import React, {Component} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Link, withRouter} from "react-router-dom";
import DataTableWrapperForListing from "../datable/DataTableWrapperForListing";
import {invoiceAttributes, paymentMethodCode2Key, subscriptionAttributes, subscriptionFrequencyCode2Key, subscriptionStatusCode2Key, transactionStatusCode2Key} from "../../utils/invoiceAttributes";
import SelectrWrapper from "../pluginwrapper/SelectrWrapper";
import {API_SALES_SUBSCRIPTION_CANCEL, API_SUBSCRIPTION_DETAIL, API_SUBSCRIPTION_EXPIRATION_DATE_CHANGE, API_SUBSCRIPTION_TRANSACTION_LIST, URL_SALES_SALESPRODUCT, URL_SALES_SUBSCRIPTION} from "../../utils/urlConstants";
import SubscriptionExpiryDateChangePopup from "../popup/SubscriptionExpiryDateChangePopup";
import SubscriptionExpiryDateChangeHistoryPopup from "../popup/SubscriptionExpiryDateChangeHistoryPopup";
import RequestRefundPopUp from "../popup/RequestRefundPopUp";
import {SimpleCheckBoxRow, SimpleRow} from "../SimpleRow";
import axios from "axios";
import {AXIOS_CLIENT_TIMEOUT} from "../../utils/globalConstants";
import {toast} from "react-toastify";
import {handleHttpError} from "../../utils/ErrorUtils";
import BasePopup from "../popup/BasePopup";
import {startLoading, stopLoading} from '../../ducks/loading';

class SubscriptionDetailsPanel extends Component {
  constructor(props) {
    super(props);

    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});
    this.state = {
      expirationDateChangeHistoryModalOpen: false,
      expirationDateChangeModalOpen: false,
      refundModalOpen: false,
      subscriptionStatusModalOpen: false,
      transactionListPage: 0,
      transactionListSizePerPage: 0,
      totalTransactionListSize: 0,
      currentSubscription: null,
      transactionList: [],
      dataLoaded: false,
      subscriptionStatus: 'Active',
      currentInvoice: null,
    }
  }

  handleSingleRefundToggle = (invoice, e) => {
    this.setState({currentInvoice: invoice, refundModalOpen: true});
    e.preventDefault();
  };

  handleRequestRefundPopupClose = (isSave, reason) => {
    this.setState({refundModalOpen: false});
    if (!isSave) {

    }
    // TODO Implement AJAX call to back-end to save order cancel reason
  };

  render() {
    if (!this.state.dataLoaded) {
      return <div/>
    }
    const {
      subscriptionId, cpProductId, cpProductName, secAccountAddress, buyer, buyerUid, orderCustomerId, countryCode, paymentAmountDisplay, paymentCurrencyCode,
      paymentFrequency, paymentPeriod, subscriptionStatusCode, subscriptionStartDate, subscriptionEndDate, lastPaymentDate, nextPaymentDate
    } = this.state.currentSubscription;

    const subscriptionNotCancelled = Object.is(subscriptionStatusCode, "00") ||
        Object.is(subscriptionStatusCode, "01");
    return (
        <div className="tab-pane active" role="tabpanel">
          <div className="clearfix mb-20">
            <h2 className="float-left mb-0"><strong>{this.props.t("PRODUCT_INFORMATION")}</strong></h2>
            <div className="text-right float-right mt-1 mb-0">
              <a onClick={() => this.handleExpirationDateChangeHistoryModalToggle(true)} className="text-400">{this.props.t("EXPIRATION_DATE_MODIFICATION_HISTORY")}<i className="icon icon-modal align-top ml-1"></i></a>
            </div>
          </div>
          <div className="form-group-wrap mb-60">
            <SimpleRow title={this.props.t("SUBSCRIPTION_ID")} value={subscriptionId}/>
            <SimpleRow title={this.props.t("PRODUCT_ID")} value={cpProductId}/>
            <SimpleRow title={this.props.t("PRODUCT_NAME")} value={cpProductName}/>
            <div className="row mb-3">
              <div className="col-6">
                <SimpleRow title={this.props.t("BUYER")} value={buyer}/>
              </div>
              <div className="col-6">
                <SimpleRow title={this.props.t("BUYER_UID")} value={buyerUid}/>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <SimpleRow title={this.props.t("ORDER_CUSTOMER_ID")} value={orderCustomerId}/>
              </div>
              <SimpleCheckBoxRow title={this.props.t("COUNTRY")} value={this.props.t(countryCode)}/>
            </div>
            <div className="row">
              <div className="col-6">
                <SimpleRow title={this.props.t("TOTAL_SALES_AMOUNT")} value={paymentAmountDisplay}/>
              </div>
              <SimpleCheckBoxRow title={this.props.t("CURRENCY")} value={paymentCurrencyCode}/>
            </div>
            <div className="row">
              <SimpleCheckBoxRow title={this.props.t("PAYMENT_FREQUENCY")} value={this.props.t(subscriptionFrequencyCode2Key[paymentFrequency])}/>
              <SimpleCheckBoxRow title={this.props.t("PAYMENT_PERIOD")} value={paymentPeriod}/>
            </div>
            <div className="row mb-3">
              <div className="col-6">
                <div className="form-group row align-items-center">
                  <label className="col col-form-label">{this.props.t("SUBSCRIPTION")}<br/>{this.props.t("START_DATE")}</label>
                  <div className="col">
                    <input type="text" className="form-control" value={subscriptionStartDate} readOnly="readonly"/>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group row align-items-center">
                  <label className="col col-form-label">{this.props.t("SUBSCRIPTION")}<br/>{this.props.t("END_DATE")}</label>
                  <div className="col">
                    <input type="text" className="form-control" value={subscriptionEndDate} readOnly="readonly"/>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-6">
                <SimpleRow title={this.props.t("LAST_PAYMENT_DATE")} value={lastPaymentDate}/>
              </div>
              <div className="col-6">
                <SimpleRow title={this.props.t("NEXT_PAYMENT_DATE")} value={nextPaymentDate}/>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="form-group row">
                  <label className="col col-form-label">{this.props.t(subscriptionAttributes.subscriptionStatus.key)}</label>
                  <div className="col">
                    {
                      subscriptionNotCancelled ?
                          <SelectrWrapper
                              className="form-control"
                              clearable={false}
                              placeholder={this.state.subscriptionStatus}
                              value={this.state.subscriptionStatus}
                              onChange={(selectedTag) => this.setState({subscriptionStatus: selectedTag.value})}>
                            {subscriptionAttributes.subscriptionStatus.value.map(v => <option key={v} value={v}>{this.props.t(v)}</option>)}
                          </SelectrWrapper> :
                          <input type="text"
                                 className="form-control"
                                 value={this.props.t(subscriptionStatusCode2Key[subscriptionStatusCode])}
                                 readOnly="readonly"/>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix mb-20">
            <h2 className="float-left mb-0"><strong>{this.props.t("TRANSACTION_LIST")}</strong></h2>
          </div>
          <DataTableWrapperForListing
              keyField="transactionId" // one of 'dataField'
              tableStyle="table table-bordered"
              hideSelectColumn={true}
              columns={[ // 'dataField' should be same as api response fields below "data" use it as 'object key'
                {dataField: "transactionId", hidden: true}, //text: this.props.t("TRANSACTION_ID"), headerStyle: {width: "11.06719%"}, classes: () => "text-truncate"},
                {dataField: "invoiceIdLink", text: this.props.t("TRANSACTION_ID"), headerStyle: {width: "11.06719%"}, classes: () => "text-truncate", titleHeader: "transactionId"},
                {dataField: "countryName", text: this.props.t(invoiceAttributes.country.key), headerStyle: {width: "7.32542%"}, classes: () => "text-truncate"},
                {dataField: "currency", text: this.props.t(invoiceAttributes.currency.key), headerStyle: {width: "7.85243%"}, classes: () => "text-left text-truncate"},
                {dataField: "paymentAmountDisplay", text: this.props.t(subscriptionAttributes.salesPrice.key), headerStyle: {width: "9.90645%"}, classes: () => "text-truncate"},
                {dataField: "paymentMethod", text: this.props.t(invoiceAttributes.paymentMethod.key), headerStyle: {width: "12.85902%"}, classes: () => "text-left text-truncate"},
                {dataField: "additionalPaymentInfo", text: this.props.t(invoiceAttributes.additionalPaymentInfo.key), headerStyle: {width: "17.23320%"}, classes: () => "text-left text-truncate"},
                {dataField: "procStatusDisplay", text: this.props.t(subscriptionAttributes.procStatusDisplay.key), headerStyle: {width: "12.01581%"}, classes: () => "text-left text-truncate"},
                {dataField: "paymentDate", text: this.props.t(subscriptionAttributes.paymentDate.key), headerStyle: {width: "10.96179%"}, classes: () => "text-left text-truncate"},
                {dataField: "refundDate", text: this.props.t(subscriptionAttributes.refundDate.key), headerStyle: {width: "11.75230%"}, classes: () => "text-left text-truncate"},
              ]}
              page={this.state.transactionListPage}
              sizePerPage={this.state.transactionListSizePerPage}
              totalSize={this.state.totalTransactionListSize}
              rows={this.state.transactionList.map(transaction => {
                return {
                  ...transaction,
                  countryName: this.props.t(transaction.countryCode),
                  currency: this.props.t(transaction.currencyCode),
                  paymentMethod: paymentMethodCode2Key[transaction.paymentMethodCode],
                  procStatusDisplay: this.props.t(transactionStatusCode2Key[transaction.transactionStatusCode]),
                  invoiceIdLink:
                      <Link to={{}}
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.history.push(URL_SALES_SALESPRODUCT + "/" + (transaction.invoiceCancelId ?? transaction.transactionId));
                            }}>
                        {transaction.transactionId}
                      </Link>,
                  refundDate:
                      Object.is(transaction.refundButton, false) &&
                      !Object.is(transaction.transactionStatusCode, "202") ? (
                          <button
                              type="button"
                              className="btn btn-auto btn-sm btn-outline-primary"
                              onClick={(e) => this.handleSingleRefundToggle(transaction, e)}>
                            {this.props.t("REFUND")}
                          </button>
                      ) : (
                          transaction.refundDate
                      )
                };
              })}
              onTableChange={this.handleSizePerPageChange}
              onSizePerPageChange={this.handleSizePerPageChange}
          />
          <div className="row mt-30">
            <div className="col">
              <button type="button" className="btn btn-sm btn-auto btn-outline-success" onClick={() => this.handleExpirationDateChangeModalToggle(true)}>{this.props.t("CHANGE_EXPIRATION_DATE")}</button>
            </div>
            <div className="col text-right">
              <button type="button"
                      className="btn btn-sm btn-auto btn-outline-primary"
                      onClick={(e) => this.handleListClick(e)}>{this.props.t("LIST")}
              </button>
              <button type="button"
                      className={`btn btn-sm btn-auto ${subscriptionNotCancelled ? "btn-primary" : "btn-success"} ml-10 ${!subscriptionNotCancelled && "disabled"}`}
                      onClick={() => this.setState({subscriptionStatusModalOpen: true})}>{this.props.t("SAVE")}
              </button>
            </div>
          </div>
          <SubscriptionExpiryDateChangePopup
              currentSubscriptionId={this.state.currentSubscription}
              open={this.state.expirationDateChangeModalOpen}
              handlePopupClose={this.handleExpirationDateChangeModalClose}/>
          <SubscriptionExpiryDateChangeHistoryPopup
              currentSubscriptionId={this.state.currentSubscription}
              open={this.state.expirationDateChangeHistoryModalOpen}
              handlePopupClose={this.handleExpirationDateChangeHistoryModalToggle}/>
          {this.state.currentInvoice &&
          <RequestRefundPopUp currentInvoice={this.state.currentInvoice}
                              buyer={this.state.currentInvoice.secAccountAddress}
                              invoiceId={this.state.currentInvoice.invoiceId}
                              paymentAmount={this.state.currentInvoice.paymentAmountDisplay}
                              paymentMethod={this.state.currentInvoice.paymentMethodDisplay}
                              additionalPaymentInfo={this.state.currentInvoice.additionalPaymentDetail}
                              open={this.state.refundModalOpen}
                              handlePopupClose={this.handleRequestRefundPopupClose}/>
          }
          <BasePopup
              open={this.state.subscriptionStatusModalOpen}
              className="modal-sm"
              title={this.props.t("SUBSCRIPTION")}
              onClose={() => this.handleChangeSubscriptionStatusClose(false)}
              body={
                <p>{this.props.t("DO_YOU_WANT_TO_CHANGE_SUBSCRIPTION_STATUS")}</p>
              }
              footer={
                <>
                  <button type="button" className="btn btn-secondary" onClick={() => this.handleChangeSubscriptionStatusClose(true)}>
                    {this.props.t("CONFIRM")}
                  </button>
                  <button type="button" className="btn btn-outline-secondary" onClick={() => this.handleChangeSubscriptionStatusClose(false)}>
                    {this.props.t("CANCEL")}
                  </button>
                </>
              }/>
        </div>
    )
        ;
  }

  componentDidMount() {
    this.fetchSubscriptionDetail(this.props.match.params.subscriptionId)
        .then(() => this.fetchSubscriptionTransactionList(1, 15, this.props.match.params.subscriptionId))
        .catch(error => {
          console.log(error);
        })
  }

  handleListClick = (e) => {
    e.preventDefault();
    this.props.history.push(URL_SALES_SUBSCRIPTION);
  };

  handleSizePerPageChange = (page, sizePerPage) => {
    this.setState({
      transactionListPage: page,
      transactionListSizePerPage: sizePerPage,
    }, () => this.fetchSubscriptionTransactionList(page, sizePerPage, this.state.currentSubscription.subscriptionId))
  };

  fetchSubscriptionDetail = (subscriptionId) => {
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .get(API_SUBSCRIPTION_DETAIL, {
            params: {
              cpAppId: this.props.carousel.selectedAppId,
              subscriptionId: subscriptionId
            }
          })
          .then(response => {
            this.setState({currentSubscription: response.data, dataLoaded: true});
            resolve();
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
          });
    });
  };

  fetchSubscriptionTransactionList = (page, sizePerPage, subscriptionId) => {
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .get(API_SUBSCRIPTION_TRANSACTION_LIST, {
            params: {
              subscriptionId: subscriptionId,
              pageNumber: page,
              limit: sizePerPage
            }
          })
          .then(response => {
            this.setState({
              transactionListPage: response.data.page,
              transactionListSizePerPage: sizePerPage,
              totalTransactionListSize: response.data.records,
              transactionList: response.data.data
            });
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
          });
    })
  };

  handleExpirationDateChangeHistoryModalToggle = (isOpen) => {
    this.setState({expirationDateChangeHistoryModalOpen: isOpen});
  };

  handleExpirationDateChangeModalToggle = (isOpen) => {
    this.setState({expirationDateChangeModalOpen: isOpen});
  };

  handleExpirationDateChangeModalClose = (isSave, reason, date) => {
    this.setState({expirationDateChangeModalOpen: false});
    if (isSave) {
      return new Promise((resolve, reject) => {
        this.props.startLoading();
        this.httpClient
            .post(API_SUBSCRIPTION_EXPIRATION_DATE_CHANGE, {
              subscriptionId: this.state.currentSubscription.subscriptionId,
              newExpiryDate: date.getValue(),
              changeReason: reason
            })
            .then((response) => {
              if (Object.is(response.data.data.result, true)) {
                this.fetchSubscriptionDetail(this.state.currentSubscription.subscriptionId);
                toast(this.props.t("SUCCESS"))
              } else {
                toast(response.data.data.errMsg);
              }
              this.props.stopLoading();
            })
            .catch(error => {
              handleHttpError(this.props.t, this.props.history, error);
              reject(error);
              this.props.stopLoading();
            })
      })
    }

    return Promise.resolve(this.props.t("CONDITION_NOT_MET"));

  };

  handleChangeSubscriptionStatusClose = (isSave) => {
    this.setState({subscriptionStatusModalOpen: false});
    if (isSave) {
      return new Promise((resolve, reject) => {
        this.props.startLoading();
        this.httpClient
            .post(API_SALES_SUBSCRIPTION_CANCEL, {
              buyerGuid: this.state.currentSubscription.buyerGuid,
              buyerUid: this.state.currentSubscription.buyerUid,
              langCode: this.props.i18n.language,
              countryCode: this.state.currentSubscription.countryCode,
              subscriptionId: this.state.currentSubscription.subscriptionId,
            })
            .then((response) => {
              if (Object.is(response.data.code, '100000')) {
                this.props.history.push(URL_SALES_SUBSCRIPTION);
              } else {
                toast(response.data.name);
              }
              this.props.stopLoading();
            })
            .catch(error => {
              handleHttpError(this.props.t, this.props.history, error);
              reject(error);
              this.props.stopLoading();
            })
      })
    }

    return Promise.resolve(this.props.t("CONDITION_NOT_MET"));
  }
}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel,
  };
};

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(SubscriptionDetailsPanel)));