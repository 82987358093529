import {
  URL_ADMIN_CPMANAGEMENT,
  URL_ADMIN_FAQMANAGEMENT,
  URL_ADMIN_FAQREGISTRATION,
  URL_ADMIN_NOTIFICATIONMANAGEMENT,
  URL_ADMIN_NOTIFICATIONREGISTRATION,
  URL_ADMIN_POPUPMANAGEMENT,
  URL_ADMIN_POPUPREGISTRATION, URL_CAMPAIGN_BUYER_REDEMPTION_STATUS,
  URL_CAMPAIGN_COUPON,
  URL_CAMPAIGN_COUPON_ALLOCATIONLIST,
  URL_CAMPAIGN_ISSUECOUPON,
  URL_MEMBER_CREATEGROUP,
  URL_MEMBER_EDITPERMISSIONS,
  URL_MEMBER_GROUPMANAGEMENT,
  URL_MEMBER_MEMBERSHIPMANAGEMENT,
  URL_MONTHLY_SETTLEMENT,
  URL_PRODUCT_ADDMULTIPLE,
  URL_PRODUCT_ADDNEW,
  URL_PRODUCT_LIST,
  URL_PRODUCT_MODIFYMULTIPLE,
  URL_PRODUCT_SUBSCRIPTIONGROUP_ADDNEW,
  URL_PRODUCT_SUBSCRIPTIONGROUP_LIST, URL_REQUEST_LIST,
  URL_SALES_SALESPRODUCT,
  URL_SALES_SALESPRODUCT_DETAIL,
  URL_SALES_SUBSCRIPTION,
  URL_SETTINGS_AGREEMENT,
  URL_SETTINGS_APPDETAILS,
  URL_SETTINGS_CHANGEINFORMATION,
  URL_SETTINGS_TESTBUYER,
  URL_STATISTICS_ANALYSISBYCOUNTRY,
  URL_STATISTICS_ANALYSISBYPRODUCT,
  URL_STATISTICS_APPSALESANALYSIS,
  URL_STATISTICS_CAMPAIGNANALYSIS,
  URL_STATISTICS_SUBSCRIBERANALYSIS,
  URL_SUPPORT_ERRORCODE,
  URL_SUPPORT_FAQ,
  URL_SUPPORT_NOTICE,
} from "./urlConstants";

// Note1 : See http://wiki.vd.sec.samsung.net/display/BIL/Menu+System+in+DPI+Portal
// Note2 : "key" should exist in 18-next for translation

export const menuKeys = {
  DASHBOARD: "DASHBOARD",
  SETTINGS: "SETTINGS",
  PRODUCT: "PRODUCT",
  SALES: "SALES",
  CAMPAIGN: "CAMPAIGN",
  STATISTICS: "STATISTICS",
  MONTHLY_SETTLEMENT_APP_APPS: "MONTHLY_SETTLEMENT_APP_APPS",
  MEMBER: "MEMBER",
  SUPPORT: "SUPPORT",
  ADMIN: "ADMIN",
  SETTINGS_APPDETAILS: "SETTINGS_APPDETAILS",
  SETTINGS_CHANGEINFORMATION: "SETTINGS_CHANGEINFORMATION",
  SETTINGS_TESTBUYER: "SETTINGS_TESTBUYER",
  SETTINGS_TESTBUYER_REGISTRATION: "SETTINGS_TESTBUYER_REGISTRATION",
  SETTINGS_AGREEMENT: "SETTINGS_AGREEMENT",
  SETTING_APP_NOTIFICATION:"SETTING_APP_NOTIFICATION",
  PRODUCT_LIST: "PRODUCT_LIST",
  PRODUCT_ADDNEW: "PRODUCT_ADDNEW",
  PRODUCT_DETAILS: "PRODUCT_DETAILS",
  PRODUCT_ADDMULTIPLE: "PRODUCT_ADDMULTIPLE",
  PRODUCT_MODIFYMULTIPLE: "PRODUCT_MODIFYMULTIPLE",
  PRODUCT_SUBSCRIPTIONGROUP_LIST: "PRODUCT_SUBSCRIPTIONGROUP_LIST",
  PRODUCT_SUBSCRIPTIONGROUP_ADDNEW: "PRODUCT_SUBSCRIPTIONGROUP_ADDNEW",
  PRODUCT_SUBSCRIPTIONGROUP_DETAIL: "PRODUCT_SUBSCRIPTIONGROUP_DETAIL",
  PRODUCT_FLEXIBLEOFFER: "PRODUCT_FLEXIBLEOFFER",
  SALES_PRODUCT: "SALES_PRODUCT",
  SALES_PRODUCT_TRANSACTIONDETAIL: "SALES_PRODUCT_TRANSACTIONDETAIL",
  SALES_SUBSCRIPTION: "SALES_SUBSCRIPTION",
  SALES_SUBSCRIPTION_PAYMENT_DETAIL: "SALES_SUBSCRIPTION_PAYMENT_DETAIL",
  CAMPAIGN_COUPONLIST: "CAMPAIGN_COUPONLIST",
  CAMPAIGN_COUPONDETAILS: "CAMPAIGN_COUPONDETAILS",
  CAMPAIGN_ISSUECOUPON: "CAMPAIGN_ISSUECOUPON",
  CAMPAIGN_ALLOCATECOUPON: "CAMPAIGN_ALLOCATECOUPON",
  CAMPAIGN_COUPON_ALLOCATIONLIST: "CAMPAIGN_COUPON_ALLOCATIONLIST",
  CAMPAIGN_BUYERREDEMPTIONSTATUS : "CAMPAIGN_BUYERREDEMPTIONSTATUS",
  STATISTICS_APPSALESANALYSIS: "STATISTICS_APPSALESANALYSIS",
  STATISTICS_ANALYSISBYCOUNTRY: "STATISTICS_ANALYSISBYCOUNTRY",
  STATISTICS_ANALYSISBYPRODUCT: "STATISTICS_ANALYSISBYPRODUCT",
  STATISTICS_SUBSCRIBERANALYSIS: "STATISTICS_SUBSCRIBERANALYSIS",
  STATISTICS_CAMPAIGNANALYSIS: "STATISTICS_CAMPAIGNANALYSIS",
  MONTHLY_SETTLEMENT: "MONTHLY_SETTLEMENT",
  MONTHLY_SETTLEMENT_DETAIL: "MONTHLY_SETTLEMENT_DETAIL_REPORT",
  MEMBER_MEMBERSHIPMANAGEMENT: "MEMBER_MEMBERSHIPMANAGEMENT",
  MEMBER_EDITPERMISSIONS: "MEMBER_EDITPERMISSIONS",
  MEMBER_GROUPMANAGEMENT: "MEMBER_GROUPMANAGEMENT",
  MEMBER_CREATEGROUP: "MEMBER_CREATEGROUP",
  PERMISSION_DETAILS: "PERMISSION_DETAILS",
  GROUP_DETAILS: "GROUP_DETAILS",
  SUPPORT_FAQ: "SUPPORT_FAQ",
  SUPPORT_ERRORCODE: "SUPPORT_ERRORCODE",
  SUPPORT_NOTICE: "SUPPORT_NOTICE",
  ADMIN_NOTIFICATIONMANAGEMENT: "ADMIN_NOTIFICATIONMANAGEMENT",
  ADMIN_NOTIFICATIONDETAILS: "ADMIN_NOTIFICATIONDETAILS",
  ADMIN_POPUPDETAILS: "ADMIN_POPUPDETAILS",
  ADMIN_FAQDETAILS: "ADMIN_FAQDETAILS",
  ADMIN_NOTIFICATIONREGISTRATION: "ADMIN_NOTIFICATIONREGISTRATION",
  ADMIN_POPUPMANAGEMENT: "ADMIN_POPUPMANAGEMENT",
  ADMIN_POPUPREGISTRATION: "ADMIN_POPUPREGISTRATION",
  ADMIN_FAQMANAGEMENT: "ADMIN_FAQMANAGEMENT",
  ADMIN_FAQREGISTRATION: "ADMIN_FAQREGISTRATION",
  ADMIN_CPMANAGEMENT: "ADMIN_CPMANAGEMENT",
  REQUEST_MANAGEMENT : "REQUEST_MANAGEMENT",
  REQUEST_LIST : "REQUEST_LIST"

};

export const alwaysShowMenuKeys = [
  menuKeys.SUPPORT_FAQ,
  menuKeys.SUPPORT_ERRORCODE,
  menuKeys.SUPPORT_NOTICE
];

export const settlementAppMenuKeys = [
  menuKeys.SETTINGS_APPDETAILS,
  menuKeys.SETTINGS_CHANGEINFORMATION,
  menuKeys.SETTINGS_TESTBUYER,
  menuKeys.SETTINGS_AGREEMENT,
  menuKeys.MONTHLY_SETTLEMENT,
  menuKeys.MONTHLY_SETTLEMENT_DETAIL,
  menuKeys.SUPPORT_FAQ,
  menuKeys.SUPPORT_ERRORCODE,
  menuKeys.SUPPORT_NOTICE
];

export const dpiPortalSupportMenu = {
  key: menuKeys.SUPPORT, url: null, subMenus: [
    {key: menuKeys.SUPPORT_FAQ, url: URL_SUPPORT_FAQ, subMenus: null},
    {key: menuKeys.SUPPORT_ERRORCODE, url: URL_SUPPORT_ERRORCODE, subMenus: null},
    {key: menuKeys.SUPPORT_NOTICE, url: URL_SUPPORT_NOTICE, subMenus: null},
  ]
};

export const dpiPortalAdminMenu = {
  key: menuKeys.ADMIN, url: null, subMenus: [
    {
      key: menuKeys.ADMIN_NOTIFICATIONMANAGEMENT, url: URL_ADMIN_NOTIFICATIONMANAGEMENT, subMenus: [
        {key: menuKeys.ADMIN_NOTIFICATIONREGISTRATION, url: URL_ADMIN_NOTIFICATIONREGISTRATION, subMenus: null},
      ]
    },
    {
      key: menuKeys.ADMIN_POPUPMANAGEMENT, url: URL_ADMIN_POPUPMANAGEMENT, subMenus: [
        {key: menuKeys.ADMIN_POPUPREGISTRATION, url: URL_ADMIN_POPUPREGISTRATION, subMenus: null},
      ]
    }, {
      key: menuKeys.ADMIN_FAQMANAGEMENT, url: URL_ADMIN_FAQMANAGEMENT, subMenus: [
        {key: menuKeys.ADMIN_FAQREGISTRATION, url: URL_ADMIN_FAQREGISTRATION, subMenus: null},
      ]
    },
    {key: menuKeys.ADMIN_CPMANAGEMENT, url: URL_ADMIN_CPMANAGEMENT, subMenus: null},
  ]
};

export const dpiPortalMemberMenu = {
  key: menuKeys.MEMBER, url: null, subMenus: [
    {
      key: menuKeys.MEMBER_MEMBERSHIPMANAGEMENT, url: URL_MEMBER_MEMBERSHIPMANAGEMENT, subMenus: [
        {key: menuKeys.MEMBER_EDITPERMISSIONS, url: URL_MEMBER_EDITPERMISSIONS, subMenus: null}
      ]
    }, {
      key: menuKeys.MEMBER_GROUPMANAGEMENT, url: URL_MEMBER_GROUPMANAGEMENT, subMenus: [
        {key: menuKeys.MEMBER_CREATEGROUP, url: URL_MEMBER_CREATEGROUP, subMenus: null}
      ]
    },
  ]
};

export const dpiPortalSettlementMenu = {
  key: menuKeys.MONTHLY_SETTLEMENT_APP_APPS, url: null, subMenus: [
    {key: menuKeys.MONTHLY_SETTLEMENT, url: URL_MONTHLY_SETTLEMENT, subMenus: null},
  ]
};

export const dpiPortalSettingsMenu = {
  key: menuKeys.SETTINGS, url: null, subMenus: [
    {key: menuKeys.SETTINGS_APPDETAILS, url: URL_SETTINGS_APPDETAILS, subMenus: null},
    {key: menuKeys.SETTINGS_CHANGEINFORMATION, url: URL_SETTINGS_CHANGEINFORMATION, subMenus: null},
    {key: menuKeys.SETTINGS_TESTBUYER, url: URL_SETTINGS_TESTBUYER, subMenus: null},
    {key: menuKeys.SETTINGS_AGREEMENT, url: URL_SETTINGS_AGREEMENT, subMenus: null},
  ]
};
export const dpiPortalRequestMenu = {
  key: menuKeys.REQUEST_MANAGEMENT, url: null, subMenus: [
    {key: menuKeys.REQUEST_LIST, url: URL_REQUEST_LIST, subMenus: null},
  ]
};
export const dpiPortalSettlementAppMenus = [
  dpiPortalSettingsMenu,
  dpiPortalSettlementMenu,
  dpiPortalSupportMenu
];

export const dpiPortalMenus = [
  dpiPortalSettingsMenu,
  {
    key: menuKeys.PRODUCT, url: null, subMenus: [
      {
        key: menuKeys.PRODUCT_LIST, url: URL_PRODUCT_LIST, subMenus: [
          {key: menuKeys.PRODUCT_ADDNEW, url: URL_PRODUCT_ADDNEW, subMenus: null},
          {key: menuKeys.PRODUCT_ADDMULTIPLE, url: URL_PRODUCT_ADDMULTIPLE, subMenus: null},
          {key: menuKeys.PRODUCT_MODIFYMULTIPLE, url: URL_PRODUCT_MODIFYMULTIPLE, subMenus: null},
        ]
      },
      {
        key: menuKeys.PRODUCT_SUBSCRIPTIONGROUP_LIST, url: URL_PRODUCT_SUBSCRIPTIONGROUP_LIST, subMenus: [
          {key: menuKeys.PRODUCT_SUBSCRIPTIONGROUP_ADDNEW, url: URL_PRODUCT_SUBSCRIPTIONGROUP_ADDNEW, subMenus: null}
        ]
      },
    ]
  },
  {
    key: menuKeys.SALES, url: null, subMenus: [
      {key: menuKeys.SALES_PRODUCT, url: URL_SALES_SALESPRODUCT, subMenus: null},
      {key: menuKeys.SALES_SUBSCRIPTION, url: URL_SALES_SUBSCRIPTION, subMenus: null},
    ]
  },
  {
    key: menuKeys.CAMPAIGN, url: null, subMenus: [
      {
        key: menuKeys.CAMPAIGN_COUPONLIST, url: URL_CAMPAIGN_COUPON, subMenus: [
          {key: menuKeys.CAMPAIGN_ISSUECOUPON, url: URL_CAMPAIGN_ISSUECOUPON, subMenus: null},
        ]
      },
      {
        key: menuKeys.CAMPAIGN_COUPON_ALLOCATIONLIST, url: URL_CAMPAIGN_COUPON_ALLOCATIONLIST, subMenus: null
      },
      {
        key: menuKeys.CAMPAIGN_BUYERREDEMPTIONSTATUS, url: URL_CAMPAIGN_BUYER_REDEMPTION_STATUS, subMenus: null
      }
    ]
  },
  {
    key: menuKeys.STATISTICS, url: null, subMenus: [
      {key: menuKeys.STATISTICS_APPSALESANALYSIS, url: URL_STATISTICS_APPSALESANALYSIS, subMenus: null},
      {key: menuKeys.STATISTICS_ANALYSISBYCOUNTRY, url: URL_STATISTICS_ANALYSISBYCOUNTRY, subMenus: null},
      {key: menuKeys.STATISTICS_ANALYSISBYPRODUCT, url: URL_STATISTICS_ANALYSISBYPRODUCT, subMenus: null},
      {key: menuKeys.STATISTICS_SUBSCRIBERANALYSIS, url: URL_STATISTICS_SUBSCRIBERANALYSIS, subMenus: null},
      {key: menuKeys.STATISTICS_CAMPAIGNANALYSIS, url: URL_STATISTICS_CAMPAIGNANALYSIS, subMenus: null},
    ]
  },
  dpiPortalSettlementMenu,
  dpiPortalMemberMenu,
  dpiPortalSupportMenu,
  dpiPortalAdminMenu,
  dpiPortalRequestMenu
];

function getFirstDepthMenuEntityByUriImpl(menus, depth, matchingUri) {
  return menus.find(menu => {
    if (Object.is(menu.url, matchingUri)) {
      return menu;
    } else if (!menu.subMenus || depth >= 3) {
      // eslint-disable-next-line
        return {}
    }

    return getFirstDepthMenuEntityByUriImpl(menu.subMenus, depth + 1, matchingUri);
  });
}

export function getFirstDepthMenuEntityByUri(matchingUri) {
  return getFirstDepthMenuEntityByUriImpl(dpiPortalMenus, 1, matchingUri);
}

function getMenuEntityByUriImpl(menus, depth, matchUri) {
  for (let i = 0; i < menus.length; i++) {
    const menu = menus[i];
    if (Object.is(menu.url, matchUri)) {
      return menu;
    }

    if (menu.subMenus && depth <= 2) {
      let ret = getMenuEntityByUriImpl(menu.subMenus, depth + 1, matchUri);
      if (ret) {
        return ret;
      }
    }
  }
  return {}
}

export function getMenuEntityByUri(matchingUri) {
  return getMenuEntityByUriImpl(dpiPortalMenus, 1, matchingUri);
}

function getMenuEntityByKeyImpl(menus, depth, matchingKey) {
  for (let i = 0; i < menus.length; i++) {
    const menu = menus[i];
    if (Object.is(menu.key, matchingKey)) {
      return menu;
    }

    if (menu.subMenus && depth <= 2) {
      let ret = getMenuEntityByKeyImpl(menu.subMenus, depth + 1, matchingKey);
      if (ret) {
        return ret;
      }
    }
  }
}

export function getMenuEntityByKey(matchingKey) {
  if (Object.is(matchingKey, menuKeys.SALES_PRODUCT_TRANSACTIONDETAIL)) {
    return {key: menuKeys.SALES_PRODUCT_TRANSACTIONDETAIL, url: URL_SALES_SALESPRODUCT_DETAIL};
  }
  return getMenuEntityByKeyImpl(dpiPortalMenus, 1, matchingKey);
}

export function dynamicUrlToConstantUrlMapper(requestUrl) {
  requestUrl = String(requestUrl)
  if (requestUrl.match(/sales\/salesproduct\/([a-zA-Z0-9]+)/g) !== null) {
    return URL_SALES_SALESPRODUCT;
  }
  if (requestUrl.match(/product\/details\/([a-zA-Z0-9]+)/g) !== null) {
    return URL_PRODUCT_ADDNEW;
  }
  if (requestUrl.match(/sales\/subscription\/([a-zA-Z0-9]+)/g) !== null) {
    return URL_SALES_SUBSCRIPTION;
  }
  if (requestUrl.match(/settlement\/monthly\/([a-zA-Z0-9]+)/g) !== null) {
    return URL_MONTHLY_SETTLEMENT;
  }
  if (requestUrl.match(/member\/membershipmanagement\/([a-zA-Z0-9]+)/g) !== null) {
    return URL_MEMBER_MEMBERSHIPMANAGEMENT;
  }
  if (requestUrl.match(/member\/groupmanagement\/([a-zA-Z0-9]+)/g) !== null) {
    return URL_MEMBER_GROUPMANAGEMENT;
  }
  if (requestUrl.match(/campaign\/coupon\/([a-zA-Z0-9]+)/g) !== null) {
    return URL_CAMPAIGN_COUPON;
  }
  if (requestUrl.match(/campaign\/allocatecoupon\/([a-zA-Z0-9]+)/g) !== null) {
    return URL_CAMPAIGN_COUPON;
  }
  if (requestUrl.match(/admin\/notificationmanagement\/([a-zA-Z0-9]+)/g) !== null) {
    return URL_ADMIN_NOTIFICATIONMANAGEMENT;
  }
  if (requestUrl.match(/admin\/popupmanagement\/([a-zA-Z0-9]+)/g) !== null) {
    return URL_ADMIN_POPUPMANAGEMENT;
  }
  if (requestUrl.match(/admin\/faqmanagement\/([a-zA-Z0-9]+)/g) !== null) {
    return URL_ADMIN_FAQMANAGEMENT;
  }
  if (requestUrl.match(/settings\/testbuyer\/([a-zA-Z0-9]+)/g) !== null) {
    return URL_SETTINGS_TESTBUYER
  }
  if (requestUrl.match(/settings\/testbuyerregistration/g) !== null) {
    return URL_SETTINGS_TESTBUYER
  }
  if (requestUrl.match(/product\/subscription\/group\/([a-zA-Z0-9]+)/g) !== null) {
    return URL_PRODUCT_SUBSCRIPTIONGROUP_LIST;
  }
  if (requestUrl.match(/product\/flexibleoffer\/([a-zA-Z0-9]+)/g) !== null) {
    return URL_PRODUCT_ADDNEW;
  }
  return requestUrl;
}

export function isDynamicUri(requestUrl) {
  requestUrl = String(requestUrl)
  if (requestUrl.match(/sales\/salesproduct\/([a-zA-Z0-9]+)/g) !== null) {
    return true;
  }
  if (requestUrl.match(/product\/details\/([a-zA-Z0-9]+)/g) !== null) {
    return true;
  }
  if (requestUrl.match(/sales\/subscription\/([a-zA-Z0-9]+)/g) !== null) {
    return true;
  }
  if (requestUrl.match(/settlement\/monthly\/([a-zA-Z0-9]+)/g) !== null) {
    return true;
  }
  if (requestUrl.match(/member\/membershipmanagement\/([a-zA-Z0-9]+)/g) !== null) {
    return true;
  }
  if (requestUrl.match(/member\/groupmanagement\/([a-zA-Z0-9]+)/g) !== null) {
    return true;
  }
  if (requestUrl.match(/campaign\/coupon\/([a-zA-Z0-9]+)/g) !== null) {
    return true;
  }
  if (requestUrl.match(/campaign\/allocatecoupon\/([a-zA-Z0-9]+)/g) !== null) {
    return true;
  }
  if (requestUrl.match(/admin\/notificationmanagement\/([a-zA-Z0-9]+)/g) !== null) {
    return true;
  }
  if (requestUrl.match(/admin\/popupmanagement\/([a-zA-Z0-9]+)/g) !== null) {
    return true;
  }
  if (requestUrl.match(/admin\/faqmanagement\/([a-zA-Z0-9]+)/g) !== null) {
    return true;
  }
  if (requestUrl.match(/settings\/testbuyer\/([a-zA-Z0-9]+)/g) !== null) {
    return true;
  }
  if (requestUrl.match(/product\/subscription\/group\/([a-zA-Z0-9]+)/g) !== null) {
    return true;
  }
  if (requestUrl.match(/product\/flexibleoffer\/([a-zA-Z0-9]+)/g) !== null) {
    return true;
  }
  return false;
}

export function getMenuEntityForDynamicUri(requestUrl) {
  if (requestUrl.match(/sales\/salesproduct\/([a-zA-Z0-9]+)/g) !== null) {
    return {key: menuKeys.SALES_PRODUCT_TRANSACTIONDETAIL, url: requestUrl};
  }
  if (requestUrl.match(/product\/details\/([a-zA-Z0-9]+)/g) !== null) {
    return {key: menuKeys.PRODUCT_DETAILS, url: requestUrl};
  }
  if (requestUrl.match(/sales\/subscription\/([a-zA-Z0-9]+)/g) !== null) {
    return {key: menuKeys.SALES_SUBSCRIPTION_PAYMENT_DETAIL, url: requestUrl};
  }
  if (requestUrl.match(/settlement\/monthly\/([a-zA-Z0-9]+)/g) !== null) {
    return {key: menuKeys.MONTHLY_SETTLEMENT_DETAIL, url: requestUrl};
  }
  if (requestUrl.match(/member\/membershipmanagement\/([a-zA-Z0-9]+)/g) !== null) {
    return {key: menuKeys.PERMISSION_DETAILS, url: requestUrl};
  }
  if (requestUrl.match(/member\/groupmanagement\/([a-zA-Z0-9]+)/g) !== null) {
    return {key: menuKeys.GROUP_DETAILS, url: requestUrl};
  }
  if (requestUrl.match(/campaign\/coupon\/([a-zA-Z0-9]+)/g) !== null) {
    return {key: menuKeys.CAMPAIGN_COUPONDETAILS, url: requestUrl};
  }
  if (requestUrl.match(/campaign\/allocatecoupon\/([a-zA-Z0-9]+)/g) !== null) {
    return {key: menuKeys.CAMPAIGN_ALLOCATECOUPON, url: requestUrl};
  }
  if (requestUrl.match(/admin\/notificationmanagement\/([a-zA-Z0-9]+)/g) !== null) {
    return {key: menuKeys.ADMIN_NOTIFICATIONDETAILS, url: requestUrl};
  }
  if (requestUrl.match(/admin\/popupmanagement\/([a-zA-Z0-9]+)/g) !== null) {
    return {key: menuKeys.ADMIN_POPUPDETAILS, url: requestUrl};
  }
  if (requestUrl.match(/admin\/faqmanagement\/([a-zA-Z0-9]+)/g) !== null) {
    return {key: menuKeys.ADMIN_FAQDETAILS, url: requestUrl};
  }
  if (requestUrl.match(/settings\/testbuyer\/([a-zA-Z0-9]+)/g) !== null) {
    return {key: menuKeys.SETTINGS_TESTBUYER, url: requestUrl};
  }
  if (requestUrl.match(/product\/subscription\/group\/([a-zA-Z0-9]+)/g) !== null) {
    return {key: menuKeys.PRODUCT_SUBSCRIPTIONGROUP_DETAIL, url: requestUrl};
  }
  if (requestUrl.match(/product\/flexibleoffer\/([a-zA-Z0-9]+)/g) !== null) {
    return {key: menuKeys.PRODUCT_FLEXIBLEOFFER, url: requestUrl};
  }
  return{};
}