import React, {Component} from 'react';
import {startLoading, stopLoading} from '../../ducks/loading';
import SearchBar from '../searchbar/SearchBar';
import {durationKey, isDateFutureThan} from '../../utils/searchBarUtils';
import axios from 'axios';
import {ANNUALLY, AXIOS_CLIENT_TIMEOUT, DESC_SORTING_ORDER, MONTH, MONTHLY, PAGE_NUMBER, QUARTER, QUARTERLY, WEEK, WEEKLY, YEAR} from '../../utils/globalConstants';
import {subscriptionGroupAttributes} from '../../utils/subscriptionGroupTypesAndAttributes';
import {API_PRODUCT_SUBSCRIPTION_GROUP_DELETE, API_PRODUCT_SUBSCRIPTION_GROUP_LIST, API_PRODUCT_SUBSCRIPTION_PRODUCT_LIST, URL_PRODUCT_SUBSCRIPTIONGROUP_ADDNEW, URL_PRODUCT_SUBSCRIPTIONGROUP_DETAIL} from '../../utils/urlConstants';
import DataTableWrapperForListing from '../datable/DataTableWrapperForListing';
import {handleHttpError} from '../../utils/ErrorUtils';
import {toast} from 'react-toastify';
import BasePopup from '../popup/BasePopup';
import {isSelectedAppChanged} from '../../ducks/carousel';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {Link, withRouter} from 'react-router-dom';

class SubscriptionGroupPanel extends Component {
  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});
    this.searchBarRef = React.createRef();
    this.state = {
      subscriptionGroupListPage: 1,
      totalSubscriptionGroupListSize: 0,
      itemsPerPage: 15,
      fetchedSubscriptionGroupList: [],
      selectedSubscriptionGroupIdList: [],
      searchParams: {},
      subscriptionProductsPopup: false,
      fetchedSubscriptionProductList: [],
      confirmDeletePopupOpen: false,
      groupId: null,
      subscriptionProductListPage: 1,
      subscriptionProductSizePerPage: 15
    }
  }

  handleDeletePopup = (isOpen) => {
    if (!isOpen && this.state.groupId !== null) {
      this.setState({groupId: null})
    }
    this.setState({confirmDeletePopupOpen: isOpen})
  }

  fetchDefaultGroupList = (page, sizePerPage) => {
    const monthBefore12 = new Date();
    monthBefore12.setFullYear(monthBefore12.getFullYear() - 1);
    const date = new Date();
    const endDateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
        .toISOString()
        .split("T")[0];
    const startDateString = new Date(monthBefore12.getTime() - (monthBefore12.getTimezoneOffset() * 60000))
        .toISOString()
        .split("T")[0];

    const params = {
      cpAppId: this.props.carousel.selectedAppId,
      dateStart: startDateString,
      dateEnd: endDateString,
      sortOrder: DESC_SORTING_ORDER,
      pageNumber: page,
      limit: sizePerPage
    };
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .get(API_PRODUCT_SUBSCRIPTION_GROUP_LIST, {
            params: params
          })
          .then(response => {
            this.setState({
              subscriptionGroupListPage: page,
              productListSizePerPage: sizePerPage,
              searchParams: params,
              fetchedSubscriptionGroupList: response.data.data,
              totalSubscriptionGroupListSize: response.data.records
            });
            this.props.stopLoading();
            resolve();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            reject(error);
            this.props.stopLoading();
          });
    })
  };

  handleSearchClick = (searchParams) => {
    if (isDateFutureThan(searchParams.startDate, searchParams.endDate)) {
      toast(this.props.t("CHECK_DATES"));
      return;
    }
    const params = {
      dateStart: searchParams.startDate,
      dateEnd: searchParams.endDate,
      keywordType: searchParams.keywordOption,
      searchText: searchParams.keywordText,
      pageNumber: 1,
    };
    this.setState({
      searchParams: params,
      subscriptionGroupListPage: PAGE_NUMBER
    }, () => this.search())
  };

  deleteColumnSorting = () => {
    let data = this.state.fetchedSubscriptionGroupList;
    let sortingOrder = this.state.sortOrder;
    data.sort(function (a, b) {
      return a.groupDeletable - b.groupDeletable;
    });
    if (sortingOrder === "DESC") {
      data.reverse();
    }
    return data;
  }

  search = () => {
    if (this.state.sortName === "groupDeletable") {
      this.setState({
        fetchedSubscriptionGroupList: this.deleteColumnSorting()
      })
      return Promise.resolve(this.props.t("CONDITION_NOT_MET"));
    }
    const {searchParams} = this.state;
    searchParams.cpAppId = this.props.carousel.selectedAppId;
    searchParams.sortName = this.state.sortName;
    searchParams.sortOrder = this.state.sortOrder;
    searchParams.pageNumber = this.state.subscriptionGroupListPage;
    searchParams.limit = this.state.itemsPerPage;
    if (searchParams.pageNumber && searchParams.limit) {
      return new Promise((resolve, reject) => {
        this.props.startLoading();
        this.httpClient
            .get(API_PRODUCT_SUBSCRIPTION_GROUP_LIST, {
              params: searchParams
            })
            .then(response => {
              this.setState({
                fetchedSubscriptionGroupList: response.data.data,
                totalSubscriptionGroupListSize: response.data.records
              });
              this.props.stopLoading();
              resolve();
            })
            .catch(error => {
              handleHttpError(this.props.t, this.props.history, error);
              reject(error);
              this.props.stopLoading();
            });
      })
    }

    return Promise.resolve(this.props.t("CONDITION_NOT_MET"));

  };

  handleRowSelect = (isSelect, rows) => {
    rows.forEach(row => {
      if (isSelect) {
        this.setState(prevState => {
          if (!prevState.selectedSubscriptionGroupIdList.find(groupId => row["groupId"] === groupId)) {
            return {selectedSubscriptionGroupIdList: [...prevState.selectedSubscriptionGroupIdList, row["groupId"]]};
          }
          return {}
        });
      } else {
        this.setState(prevState => {
          return {
            selectedSubscriptionGroupIdList: prevState.selectedSubscriptionGroupIdList.filter(groupId => row["groupId"] !== groupId)
          };
        })
      }
    });
  };

  handleTableSort = (sortOrder, sortField) => {
    const groupHeaderField2SortName = {
      "groupIdLink": "groupId",
      "groupNameLink": "groupName",
      "freeTrialOffering": "freeTrialOffering",
      "subscriptionProduct": "productCount",
      "deleteGroupButton": "groupDeletable",
      "modifiedDate": "modifiedDate",
    };
    this.setState({
      sortOrder: sortOrder === 'desc' ? "DESC" : "ASC",
      sortName: groupHeaderField2SortName[sortField] ? groupHeaderField2SortName[sortField] : "",
      SubscriptionGroupListPage: 1,
    }, () => this.search())
  };

  handleTableChange = (page, sizePerPage) => {
    this.setState({
      subscriptionGroupListPage: page,
      itemsPerPage: sizePerPage,
    }, () => this.search());
  };

  handleDelete = (groupIds) => {
    this.setState({confirmDeletePopupOpen: false})
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .post(API_PRODUCT_SUBSCRIPTION_GROUP_DELETE, groupIds)
          .then(() => {
            groupIds.forEach(groupId => {
              this.setState(prevState => {
                return {
                  fetchedSubscriptionGroupList: prevState.fetchedSubscriptionGroupList.filter(group => group.groupId !== groupId),
                  selectedSubscriptionGroupIdList: prevState.selectedSubscriptionGroupIdList.filter(item => item !== groupId)
                }
              });
            });
            this.setState({groupId: null});
            toast(this.props.t("GROUP_DELETED"));
            resolve();
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            reject(error);
            this.props.stopLoading();
          });
    })
  };

  handlePopupToggle = (isOpen) => {
    this.setState({subscriptionProductsPopup: isOpen});
  };

  popupTableChange = (page, sizePerPage) => {
    this.setState({
      subscriptionProductListPage: page,
      subscriptionProductSizePerPage: sizePerPage,
    }, () => {
      this.handlePopup(this.state.groupId);
    })
  }
  handlePopup = (groupId) => {
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .get(API_PRODUCT_SUBSCRIPTION_PRODUCT_LIST, {
            params: {
              subscriptionGroupId: groupId,
              pageNumber: this.state.subscriptionProductListPage,
              limit: this.state.subscriptionProductSizePerPage
            }
          })
          .then(response => {
            this.setState({
              fetchedSubscriptionProductList: response.data.data,
              totalSubscriptionProductListSize: response.data.records,
              subscriptionProductsPopup: true,
              groupId: groupId,
            });
            resolve();
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            reject(error);
            this.props.stopLoading();
          })
    });
  };

  checkFreeTrialOffering = (group) => {
    if (group.guidYN === 'Y' && group.duidYN === 'Y') {
      return `Per account, device (${group.duidCount})`
    } else if (group.guidYN === 'Y' && group.duidYN === 'N') {
      return `Per account`;
    } else if (group.guidYN === 'N' && group.duidYN === 'Y') {
      return `Per device (${group.duidCount})`
    } else {
      return '-'
    }
  };

  convertBillingPeriod = (billPeriod) => {
    switch (billPeriod) {
      case WEEK:
        return WEEKLY;
      case MONTH:
        return MONTHLY;
      case QUARTER:
        return QUARTERLY;
      case YEAR:
        return ANNUALLY;
      default:
        return "";
    }
    return ""
  };

  convertLevel = (level) => {
    return `Lv.${level}`
  };

  render() {

    const nonSelected = this.state.selectedSubscriptionGroupIdList.length === 0;
    const {selectedSubscriptionGroupIdList, fetchedSubscriptionGroupList} = this.state;
    let isAllDeletable = true;
    selectedSubscriptionGroupIdList.forEach(e => {
      isAllDeletable = isAllDeletable && (fetchedSubscriptionGroupList.filter(group => group.groupId === e))[0].groupDeletable;
    });

    return (
        <div className="tab-pane active" role="tabpanel">
          <SearchBar
              ref={this.searchBarRef}
              keywordPanel={{
                show: true,
                label: this.props.t("KEYWORD"),
                placeholder: this.props.t("PLEASE_ENTER_KEYWORDS"),
                options: [subscriptionGroupAttributes.groupId.key, subscriptionGroupAttributes.groupName.key],
                showDownload: false,
              }}
              datePanel={{
                show: true,
                label: this.props.t("MODIFIED_DATE"),
                defaultDuration: durationKey.DURATION_YEAR,
                maxDate: true
              }}
              filterPanel={{
                show: false
              }}
              radioButtonPanel={{
                show: false,
              }}
              onSearchClick={this.handleSearchClick}
          />
          <DataTableWrapperForListing
              keyField="groupId"
              columns={[
                {dataField: "groupId", hidden: true},
                {dataField: "groupIdLink", text: this.props.t(subscriptionGroupAttributes.groupId.key), headerStyle: {width: "12.5%"}, sort: true, titleHeader: "groupId"},
                {dataField: "groupNameLink", text: this.props.t(subscriptionGroupAttributes.groupName.key), headerStyle: {width: "42.5%"}, sort: true, classes: () => "text-truncate", titleHeader: "groupName"},
                {dataField: "freeTrialOffering", text: this.props.t(subscriptionGroupAttributes.freeTrialOffering.key), headerStyle: {width: "12.5%"}, sort: true},
                {dataField: "subscriptionProduct", text: this.props.t(subscriptionGroupAttributes.subscriptionProduct.key), headerStyle: {width: "12.5%"}, sort: true, hideTitle: true},
                {dataField: "deleteGroupButton", text: this.props.t(subscriptionGroupAttributes.deleteGroup.key), headerStyle: {width: "10%"}, sort: true, hideTitle: true},
                {dataField: "modifiedDate", text: this.props.t(subscriptionGroupAttributes.modifiedDate.key), headerStyle: {width: "10%"}, sort: true},
              ]}
              page={this.state.subscriptionGroupListPage}
              sizePerPage={this.state.itemsPerPage}
              totalSize={this.state.totalSubscriptionGroupListSize}
              disableFooter={false}
              rows={this.state.fetchedSubscriptionGroupList.map(group => {
                return {
                  ...group,
                  groupIdLink: <Link to={{}}
                                     onClick={(e) => {
                                       e.preventDefault();
                                       this.props.history.push(URL_PRODUCT_SUBSCRIPTIONGROUP_DETAIL + "/" + group.groupId);
                                     }}>
                    {group.groupId}
                  </Link>,
                  groupNameLink: <Link to={{}}
                                       onClick={(e) => {
                                         e.preventDefault();
                                         this.props.history.push(URL_PRODUCT_SUBSCRIPTIONGROUP_DETAIL + "/" + group.groupId);
                                       }}>
                    {group.groupName}
                  </Link>,
                  freeTrialOffering: this.checkFreeTrialOffering(group),
                  subscriptionProduct: group.productCount ?
                      <Link to={{}}
                            onClick={(e) => {
                              this.handlePopup(group.groupId);
                              e.preventDefault();
                            }}>
                        {group.productCount}
                      </Link> :
                      group.productCount,
                  deleteGroupButton: group.groupDeletable ? (
                      <button
                          type="button"
                          className="btn btn-sm btn-outline-400"
                          onClick={(e) => {
                            this.setState({groupId: [group.groupId]})
                            this.handleDeletePopup(true)
                          }}>
                        {this.props.t("DELETE")}
                      </button>
                  ) : (<span>-</span>),
                  modifiedDate: group.modifiedDate.split(" ")[0]
                };
              })}
              nonSelectableRows={
                this.state.fetchedSubscriptionGroupList
                    .map(group => !group.groupDeletable ? group.groupId : null)
                    .filter(e => e != null)}
              onTableSort={this.handleTableSort}
              onTableChange={this.handleTableChange}
              onRowSelect={this.handleRowSelect}/>
          <div className="pagenationGroup clearfix max">
            <div className="float-left">
              <button
                  type="button"
                  disabled={nonSelected}
                  className={`btn btn-sm btn-outline-success ml-6 ${nonSelected && "disabled"}`}
                  onClick={(e) => this.handleDeletePopup(true)}>
                {this.props.t("DELETE_GROUP")}
              </button>
            </div>
            <div className="float-right">
              <Link to={URL_PRODUCT_SUBSCRIPTIONGROUP_ADDNEW}>
                <button type="button" className="btn btn-sm btn-primary">
                  {this.props.t("ADD_NEW_GROUP")}
                </button>
              </Link>
            </div>
          </div>
          <BasePopup
              className={"modal-lg"}
              open={this.state.subscriptionProductsPopup || this.state.confirmDeletePopupOpen}
              title={this.state.subscriptionProductsPopup ? `Subscription Products (${this.state.fetchedSubscriptionProductList.length})` : this.props.t("ALERT")}
              onClose={() => {
                this.state.subscriptionProductsPopup ? this.handlePopupToggle(false) : this.handleDeletePopup(false)
              }}
              body={
                <>
                  {this.state.subscriptionProductsPopup &&
                      <>
                        <DataTableWrapperForListing
                            keyField="productId"
                            tableStyle="table table-bordered"
                            hideSelectColumn={true}
                            page={this.state.subscriptionProductListPage}
                            sizePerPage={this.state.subscriptionProductSizePerPage}
                            totalSize={this.state.totalSubscriptionProductListSize}
                            columns={[ // 'dataField' should be same as api response fields below "data" use it as 'object key'
                              {dataField: "productName", text: this.props.t("PRODUCT_NAME"), headerStyle: {width: "16%"}, classes: () => "text-truncate"},
                              {dataField: "productId", text: this.props.t("PRODUCT_ID"), headerStyle: {width: "16%"}, classes: () => "text-left text-truncate"},
                              {dataField: "billingPeriod", text: this.props.t("BILLING_PERIOD"), headerStyle: {width: "17%"}, classes: () => "text-truncate"},
                              {dataField: "level", text: this.props.t("LEVEL"), headerStyle: {width: "10%"}, classes: () => "text-truncate"},
                              {dataField: "freeTrialPeriod", text: this.props.t("FREE_TRIAL_PERIOD_DAYS"), headerStyle: {width: "17%"}, classes: () => "text-truncate"},
                              {dataField: "duplicateBenefit", text: this.props.t("DUPLICATE_BENEFIT"), headerStyle: {width: "24%"}, classes: () => "text-truncate"},
                            ]}

                            rows={this.state.fetchedSubscriptionProductList.map(subscriptionProductList => {
                              return {
                                ...subscriptionProductList,
                                billingPeriod: this.convertBillingPeriod(subscriptionProductList.billPeriod),
                                level: this.convertLevel(subscriptionProductList.productLevel),
                                freeTrialPeriod: subscriptionProductList.freeTrialDays,
                                duplicateBenefit: subscriptionProductList.duplicateBenefitYN === 'Y' ? "Yes" : "No"
                              };
                            })}
                            onTableChange={this.popupTableChange}
                        />
                        <div className="pagenationGroup clearfix max"/>
                      </>
                  }
                  {this.state.confirmDeletePopupOpen && <p>{this.state.selectedSubscriptionGroupIdList ? this.props.t("ARE_YOU_SURE_YOU_WANT_TO_DELETE") : this.props.t("PLEASE_SELECT_AT_LEAST_ONE_ITEM")}</p>}
                </>
              }
              footer={
                <>
                  {this.state.subscriptionProductsPopup &&
                      <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => {
                              this.handlePopupToggle(false)
                            }}>
                          {this.props.t("CLOSE")}
                        </button>
                      </div>
                  }
                  {this.state.confirmDeletePopupOpen &&
                      <>
                        <button type="button" className="btn btn-secondary" onClick={() => {
                          if (this.state.groupId === null) {
                            this.handleDelete(this.state.selectedSubscriptionGroupIdList)
                          } else {
                            this.handleDelete(this.state.groupId)
                          }
                        }}>
                          {this.props.t("CONFIRM")}
                        </button>
                        <button type="button" className="btn btn-outline-secondary" onClick={() => this.handleDeletePopup(false)}>
                          {this.props.t("CANCEL")}
                        </button>
                      </>
                  }
                </>
              }/>
        </div>
    )
  }

  componentDidMount() {
    this.fetchDefaultGroupList(1, 15);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (isSelectedAppChanged(this.props.carousel.selectedContsId, prevProps.carousel.selectedContsId)) {
      this.searchBarRef.current.resetState();
      this.fetchDefaultGroupList(1, 15);
    }
  }
}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel,
  };
};

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(SubscriptionGroupPanel)));

