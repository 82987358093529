import React, {Component} from 'react';
import SearchBar from "../searchbar/SearchBar";
import {withTranslation} from "react-i18next";
import {Link, withRouter} from "react-router-dom";
import DataTableWrapperForListing from "../datable/DataTableWrapperForListing";
import {API_MONTHLY_SETTLEMENT, API_SETTLEMENT_SALES_REPORT_DOWNLOAD, URL_MONTHLY_SETTLEMENT} from "../../utils/urlConstants";
import PayoutStatusPopup from "../popup/PayoutStatusPopup";
import {durationKey, getDefaultTimePeriod, isDateFutureThan} from '../../utils/searchBarUtils';
import {handleHttpError} from "../../utils/ErrorUtils";
import axios from "axios";
import {AXIOS_CLIENT_TIMEOUT, DESC_SORTING_ORDER, SETTLEMENTMONTH_SORTNAME} from "../../utils/globalConstants";
import {startLoading, stopLoading} from '../../ducks/loading';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import {handleDownloadFileFromS3} from '../../utils/multipleAddModifyProductAttributes';

class SettlementListPanel extends Component {
  constructor(props) {
    super(props);

    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});

    this.state = {
      modalOpen: false,
      settlementListPage: 1,
      totalSettlementListSize: 0,
      settlementListSizePerPage: 15,
      settlementList: [],
      searchFilter: '',
      sortOrder: "DESC",
      sortName: "settlementMonth"
    }
  }

  fetchSettlementList = (page, sizePerPage) => {
    const searchPeriod = getDefaultTimePeriod();
    const startDate = searchPeriod.startDate;
    const startDateArr = startDate.split('-');
    const endDate = searchPeriod.endDate;
    const endDateArr = endDate.split('-');
    const params = {
      dateStart: startDateArr[0].concat(startDateArr[1]),
      dateEnd: endDateArr[0].concat(endDateArr[1]),
    };
    this.setState({searchParams: params}, () => {
      let params = this.state.searchParams;
      params = {
        ...params,
        sortOrder: this.state.sortOrder,
        pageNumber: this.state.settlementListPage,
        limit: this.state.settlementListSizePerPage
      };
      return new Promise((resolve, reject) => {
        this.props.startLoading();
        this.httpClient
            .get(API_MONTHLY_SETTLEMENT, {
              params: params
            })
            .then(response => {
              this.setState({
                settlementListPage: page,
                settlementListSizePerPage: sizePerPage,
                totalSettlementListSize: response.data.records,
                settlementList: response.data.data
              });
              this.props.stopLoading();
            })
            .catch(error => {
              handleHttpError(this.props.t, this.props.history, error);
              reject(error);
              this.props.stopLoading();
            })
      })
    })
  };
  handleTableSort = (sortOrder, sortField) => {
    const productHeaderField2SortName = {
      "settlementMonth": "settlementMonth"
    };

    this.setState({
      sortOrder: sortOrder === 'desc' ? "DESC" : "ASC",
      sortName: productHeaderField2SortName[sortField] ? productHeaderField2SortName[sortField] : "",
      productListPage: 1,
    }, () => this.triggerSettlementSearch())
  };

  handleSettlementReportSearch = (searchParams) => {
    if (isDateFutureThan(searchParams.startDate, searchParams.endDate)) {
      toast(this.props.t("CHECK_DATES"))
      return;
    }
    const startDate = searchParams.startDate;
    const startDateArr = startDate.split('-');
    const endDate = searchParams.endDate;
    const endDateArr = endDate.split('-');
    const params = {
      dateStart: startDateArr[0].concat(startDateArr[1]),
      dateEnd: endDateArr[0].concat(endDateArr[1]),
      pageNumber: 1,
      rowsPerPage: this.state.settlementListSizePerPage
    };
    this.setState({
      searchParams: params,
      settlementListPage: 1,
      sortOrder: DESC_SORTING_ORDER,
      sortName: SETTLEMENTMONTH_SORTNAME
    }, () => this.triggerSettlementSearch())
  };

  triggerSettlementSearch = () => {
    const {searchParams} = this.state;
    searchParams.sortOrder = this.state.sortOrder;
    searchParams.pageNumber = this.state.settlementListPage;
    searchParams.limit = this.state.settlementListSizePerPage;
    if (searchParams.pageNumber && searchParams.limit) {
      return new Promise((resolve, reject) => {
        this.props.startLoading();
        this.httpClient
            .get(API_MONTHLY_SETTLEMENT, {
              params: searchParams
            })
            .then(response => {
              this.setState({
                totalSettlementListSize: response.data.records,
                settlementList: response.data.data
              });
              this.props.stopLoading();
            })
            .catch(error => {
              handleHttpError(this.props.t, this.props.history, error);
              reject(error);
              this.props.stopLoading();
            })
      });
    }

    return Promise.resolve(this.props.t("CONDITION_NOT_MET"));

  };

  handleSizePerPageChange = (page, sizePerPage) => {
    this.setState({
      settlementListPage: page,
      settlementListSizePerPage: sizePerPage,
    }, () => this.triggerSettlementSearch());
  };

  handlePayoutPopupClose = () => {
    this.setState({modalOpen: false})
  };

  componentDidMount() {
    this.fetchSettlementList(this.state.settlementListPage, this.state.settlementListSizePerPage);
  }

  render() {
    return (
        <div className="tab-pane active" id="nav-home" role="tabpanel">
          <SearchBar
              datePanel={{
                show: true,
                label: this.props.t("SETTLEMENT_PERIOD"), // Order Date
                defaultDuration: durationKey.DURATION_YEAR,
                durationOptions: [
                  durationKey.DURATION_ALL,
                  durationKey.DURATION_YEAR,
                  durationKey.DURATION_SIX_MONTH
                ],
                showSearch: true,
                maxDate: true
              }}
              filterPanel={{
                show: false,
              }}
              keywordPanel={{
                show: false,
              }}
              radioButtonPanel={{
                show: false,
              }}
              onSearchClick={this.handleSettlementReportSearch}/>
          <DataTableWrapperForListing
              keyField="settlementMonth" // one of 'dataField'
              hideSelectColumn={true}
              columns={[ // 'dataField' should be same as api response fields below "data" use it as 'object key'
                {dataField: "settlementMonth", text: this.props.t("SETTLEMENT_MONTH"), headerStyle: {width: "11.86294%"}, sort: true},
                {dataField: "viewSettlementLink", text: this.props.t("VIEW_SETTLEMENT_REPORT"), headerStyle: {width: "34.79796%"}, hideTitle: true},
                {dataField: "viewPayoutStatus", text: this.props.t("VIEW_PAYOUT_STATUS"), headerStyle: {width: "34.88562%"}, hideTitle: true},
                {dataField: "downloadSalesReport", text: this.props.t("DOWNLOAD_SALES_REPORT"), headerStyle: {width: "18.45346%"}, hideTitle: true},
              ]}
              page={this.state.settlementListPage}
              onTableSort={this.handleTableSort}
              sizePerPage={this.state.settlementListSizePerPage}
              totalSize={this.state.totalSettlementListSize}
              rows={this.state.settlementList.map(settlement => {
                return {
                  ...settlement,
                  viewSettlementLink:
                      <Link to={{}}
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.history.push(URL_MONTHLY_SETTLEMENT + "/" + settlement.settlementMonth);
                            }}>
                        {this.props.t("VIEW_SETTLEMENT_REPORT")}
                      </Link>,
                  viewPayoutStatus:
                      <Link to={{}}
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({modalOpen: true, searchFilter: settlement.settlementMonth})
                            }}>
                        {this.props.t("VIEW_PAYOUT_STATUS")}
                      </Link>,
                  downloadSalesReport:
                      <button
                          type="button"
                          className="btn btn-auto btn-sm btn-outline-primary"
                          onClick={(e) => this.onClickDownloadSalesReport(settlement.settlementMonth, e)}>
                        {this.props.t("DOWNLOAD_SALES_REPORT")}
                      </button>
                };
              })}
              onSizePerPageChange={this.handleSizePerPageChange}
              onTableChange={this.handleSizePerPageChange}
          />
          <div className="text-muted mt-20">
            ※ For CPs in Korea, please check the tax invoice amount after downloading Monthly Settlement Report.<br/>
            ※ Monthly Settlement Report is a consolidated report for all registered apps.
          </div>
          <PayoutStatusPopup open={this.state.modalOpen}
                             handlePopupClose={this.handlePayoutPopupClose}
                             searchFilter={this.state.searchFilter}/>
        </div>
    );
  }

  onClickDownloadSalesReport = (settlementMonth, e) => {
    let params = {
      dateStart: settlementMonth
    };
    handleDownloadFileFromS3(API_SETTLEMENT_SALES_REPORT_DOWNLOAD, params, this.props.t, this.props.history, this.httpClient, this.props.startLoading, this.props.stopLoading);
  }
}

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(0, mapDispatchToProps)(withTranslation()(withRouter(SettlementListPanel)));