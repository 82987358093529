import React, {Component} from 'react';
import {getAppFromSessionData} from '../../ducks/sessionData';
import {getCountriesInformation, isCountriesInformationChanged, loadCountriesInformation} from '../../ducks/countriesInformation';
import SelectrWrapper from '../pluginwrapper/SelectrWrapper';
import {billingPeriodCode2Key, billingPeriodKey2Code, getExpirationFromValidDates, productAttributes, productProductLevelCode2Key, productProductLevelKey2Code, productStatusCode2Key, productStatusKey2Code, productTypeCode2Key, productTypeKey2Code, visibilityCode2Key, visibilityKey2Code} from '../../utils/productTypesAndAttributes';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import ApplyAllProductPopup from '../popup/ApplyAllProductPopup';
import TaxPricingGuidePopup from '../popup/TaxPricingGuidePopup';
import CountryListPopUp from '../popup/CountryListPopUp';
import {isSelectedAppChanged} from '../../ducks/carousel';
import DataTableWrapperForProductAddModify from '../datable/DataTableWrapperForProductAddModify';
import {Link, withRouter} from 'react-router-dom';
import {API_PRODUCT_SUBSCRIPTION_GROUP_NAME_LIST, API_PRODUCT_TAX_CATEGORY_LIST, API_PRODUCT_UPDATE, URL_ADD_PRODUCT_API, URL_GET_CHECK_ID_API, URL_GET_COUNTRY_DATA_API, URL_GET_COUNTRY_INFORMATION_API, URL_GET_PRODUCT_DETAIL_API, URL_PRODUCT_LIST, URL_PRODUCT_FLEXIBLEOFFER} from '../../utils/urlConstants';
import {isDynamicProduct} from '../../utils/productValidationUtils';
import axios from 'axios';
import {AXIOS_CLIENT_TIMEOUT, FREE_TRIAL_SUBSCRIPTION, LIMITED_PERIOD, SUBSCRIPTION, SUCCESS, VALID_END_DATE_YEAR} from '../../utils/globalConstants';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {handleHttpError} from '../../utils/ErrorUtils';
import {startLoading, stopLoading} from '../../ducks/loading';
import BasePopup from '../popup/BasePopup';
import {fetchCountries, fetchTaxCategoryList} from '../../utils/CountryListUtils';

function DescriptionPanel(props) {
  return (
      <div className="textarea_count">
        <textarea
            ref={props.focus}
            maxLength="400"
            disabled={props.readOnly}
            value={props.value}
            onChange={(event) => {
              event.target.value = props.filterInput(event);
              props.handleProductDescription(event);
            }}
            className="form-control text_counter" placeholder={props.placeHolder}/>
        <div className="counts text-right">
          <span className="text_count">{props.characterCount}</span> / 400Byte
        </div>
      </div>
  )
}

class AddModifyProductPanel extends Component {
  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});
    this.state = {
      // init flag
      init: false,

      // for data table
      selectedCountries: [], // list of countries which are displayed in table
      productInformationByCountries: [], // table row details
      defaultProductInformationCountries: [],
      isApplyAllPopupOpen: false,
      isTaxGuidePopupOpen: false,
      isCountryListPopupOpen: false,
      isTooltipOpen: false,
      productId: "",
      productDescription: "",
      selectedProductType: null,
      selectedProductStatus: null,
      readOnly: false,
      selectedVisibility: "HIDE",
      selectedExpiration: "NOT_APPLICABLE",
      accountOnce: false,
      deviceAllow: false,
      deviceLimit: 1,
      isVerification: false,
      verifyUri: "",
      idAvailable: null,
      limitedDays: 0,
      limitedHours: 0,
      limitedMinutes: 0,
      modifiedDate: "",
      registeredDate: "",
      focusType: true,
      billingPeriodType: '',
      productLevelType: '',
      subscriptionGroupName: '',
      subscriptionGroupId: '',
      freeTrialDays: "",
      selectedduplicateBenefit: "NO",
      subscriptionGroupList: [],
      savePopup: false,
      countryData: [],
      taxCategoryData: []
    };

    this.focusId = React.createRef();
    this.focusDescription = React.createRef();
  }

  initData = () => {
    if (this.props.sessionData.apps.length && this.state.countryData.length && this.props.countriesInformation.length) {
      const app = getAppFromSessionData(this.props.sessionData, this.props.carousel.selectedAppId);
      if (!this.props.isModify) { // Add case
        this.setState({
          init: true,
          productId: "",
        });
      } else { // Modify case
        this.props.startLoading();
        this.httpClient
            .get(URL_GET_PRODUCT_DETAIL_API, {params: {productId: this.props.match.params.productId}})
            .then(response => {
              this.setState({
                init: true,
                productInfo: response.data
              }, () => {
                this.getCountryInfo(response.data, app.defaultCountry)
              });
              this.props.stopLoading();

            })
            .catch(error => {
              handleHttpError(this.props.t, this.props.history, error);
              this.props.stopLoading();
            });
      }
    }
  };

  getCountryInfo = (productDetail, defaultCountry) => {
    let productInformationByCountries = productDetail.informationByCountries;
    if (productInformationByCountries !== null) {
      if (this.state.readOnly) {
        productInformationByCountries = productInformationByCountries.filter(item => item.country !== defaultCountry);
      }

      this.setState({
        productInformationByCountries: productInformationByCountries.map(item => {
          let countriesInformation = getCountriesInformation(this.props.countriesInformation, item.country);
          item.isSelected = false;
          item.taxApplicable = countriesInformation?.taxApplicable;
          item.agentCountry = countriesInformation?.agentCountry;
          item.taxCategoryApplicable = countriesInformation?.taxCategoryApplicable;
          item.agentCountryTaxApplicable = countriesInformation?.agentCountryTaxApplicable;
          item.currencyCode = countriesInformation?.currency;
          if (countriesInformation?.agentCountryTaxApplicable) {
            item.taxRate = (countriesInformation?.agentCountryTaxRate * 100).toFixed(2);
          } else if (item.taxRate) {
            item.taxRate = Math.round(item.taxRate * 100 * 1000) / 1000;
          } else {
            item.taxRate = "";
          }
          return item;
        }),
        selectedCountries: productInformationByCountries.map(item => item.country)
      }, () => {
        this.setState({
          defaultProductInformationCountries: this.state.productInformationByCountries
        })
      })
    }

    this.setState({
      productId: productDetail.cpProductId,
      originalProductId: productDetail.productId,
      productDescription: productDetail.productDescription,
      selectedVisibility: visibilityCode2Key[productDetail.visibility],
      selectedProductStatus: productStatusCode2Key[productDetail.productStatus],
      readOnly: productDetail.productStatus === "05" || productDetail.productStatus === "06",
      selectedProductType: productTypeCode2Key[productDetail.productType],
      modifiedDate: productDetail.modifiedDate,
      registeredDate: productDetail.registeredDate,
      selectedExpiration: getExpirationFromValidDates(productDetail.validStartDate, productDetail.validEndDate),
      //type wise params
      limitedDays: productDetail.limitedPeriodDays,
      limitedHours: productDetail.limitedPeriodHours,
      limitedMinutes: productDetail.limitedPeriodMinutes,
      accountOnce: productDetail.freeTrialCriteriaGuid === 'Y',
      deviceAllow: productDetail.freeTrialCriteriaDuid === 'Y',
      deviceLimit: productDetail.allowedDevices,
      isVerification: productDetail.verifyUrlUseYN === 'Y',
      verifyUri: productDetail.verifyUrl,
      productLevelType: productProductLevelCode2Key[productDetail.subscriptionProductLevel],
      subscriptionGroupName: productDetail.subscriptionGroupName !== null ? productDetail.subscriptionGroupName : "",
      selectedduplicateBenefit: productDetail.duplicateBenefitYN === 'Y' ? "YES" : "NO",
      billingPeriodType: billingPeriodCode2Key[productDetail.cycle],
      freeTrialDays: productDetail.freeTrialDays,
      subscriptionGroupId: productDetail.subscriptionGroupId
    });
  };

  checkNewCountryAdded = (country, productInformationByCountries) => {
    let oldInfo = null;
    productInformationByCountries.forEach(item => {
      if (item.country === country) {
        oldInfo = item;
      }
    });
    return oldInfo;
  };

  convertToProductInformationByCountries = (selectedCountries) => {
    return selectedCountries.map(country => {
      const isAlreadyAdded = this.checkNewCountryAdded(country, this.state.productInformationByCountries);
      let countriesInformation = getCountriesInformation(this.props.countriesInformation, country);
      const isPreviouslyPresentCountry = this.checkNewCountryAdded(country, this.state.defaultProductInformationCountries);
      if (isAlreadyAdded) {
        return isAlreadyAdded;
      } else if (isPreviouslyPresentCountry) {
        return isPreviouslyPresentCountry;
      } else {
        return {
          country: country,
          isSelected: false,
          productName: "",
          currencyCode: countriesInformation.currency,
          productPrice: "",
          minimumDynamicProductPrice: "",
          priceTaxIncluded: false,
          maximumDynamicProductPrice: "",
          agentCountry: countriesInformation.agentCountry,
          taxApplicable: countriesInformation.taxApplicable,
          taxCategoryApplicable: countriesInformation.taxCategoryApplicable,
          taxCategory: countriesInformation.taxCategory,
          // for showing value in frontend
          taxRate: countriesInformation.agentCountryTaxApplicable ? (countriesInformation.agentCountryTaxRate * 100).toFixed(2) : "",
          agentCountryTaxApplicable: countriesInformation.agentCountryTaxApplicable
        }
      }
    });
  };

  handleCheck = (country) => {
    this.setState(prevState => {
      return {
        productInformationByCountries: prevState.productInformationByCountries.map((item) => {
          if (item.country === country) {
            item.isSelected = !item.isSelected;
          }
          return item;
        })
      }
    });
  };

  handleCheckAll = () => {
    const allChecked = this.state.productInformationByCountries.every(item => item.isSelected);
    this.setState(prevState => {
      return {
        productInformationByCountries: prevState.productInformationByCountries.map((item) => {
          item.isSelected = !allChecked;
          return item;
        })
      }
    });
  };

  handlePopupApplyAll = () => {
    this.setState({isApplyAllPopupOpen: true});
  };

  closePopupApplyAllValidation = (newValue) => {
    let closeModal = true;
    if (newValue.productName.length === 0) {
      toast(this.props.t("ENTER_PRODUCT_NAME_FOR_DEFAULT_COUNTRY"));
      closeModal = false;
    }
    const isDynamicProductWithNullPrice = isDynamicProduct(this.state.selectedProductType) && (newValue.minPrice.length === 0 || newValue.maxPrice.length === 0);
    const isProductWithNullPrice = !isDynamicProduct(this.state.selectedProductType) && newValue.price.length === 0;
    if (isDynamicProductWithNullPrice || isProductWithNullPrice) {
      toast(this.props.t("ENTER_PRODUCT_PRICE_FOR_DEFAULT_COUNTRY"));
      closeModal = false;
    }
    return closeModal;
  }

  handleClosePopupApplyAll = (isSave, newValue) => {
    if (!isSave) {
      this.setState({isApplyAllPopupOpen: false});
      return;
    }
    const closeModal = this.closePopupApplyAllValidation(newValue);
    if (closeModal) {
      this.setState({isApplyAllPopupOpen: false});
      this.handleApplyAllNames(newValue.productName);
      this.handleApplyAllPrices(newValue.price, newValue.minPrice, newValue.maxPrice);
    }
  };

  handlePopupOpenClick = () => {
    this.setState({isTaxGuidePopupOpen: true});
  };

  handlePopupCloseClick = () => {
    this.setState({isTaxGuidePopupOpen: false});
  };

  handleCountryListModalClose = (isSave, selectedCountries) => {
    this.setState({isCountryListPopupOpen: false});
    if (!isSave) {
      return;
    }
    this.setState({selectedCountries: selectedCountries});
    this.setState({productInformationByCountries: this.convertToProductInformationByCountries(selectedCountries)});
  };

  handleTooltipOpenClick = () => {
    this.setState({isTooltipOpen: true});
  };

  handleTooltipCloseClick = () => {
    this.setState({isTooltipOpen: false});
  };

  extractCountryList = (productInformationByCountries) => {
    return productInformationByCountries.map(item => item.country);
  };
  handleAddCountry = () => {
    this.setState({isCountryListPopupOpen: true});
  };

  handleDeleteCountry = () => {
    this.setState(prevState => {
      let newProductInformationByCountries = prevState.productInformationByCountries.filter(function (item) {
        return !item.isSelected;
      });
      return {
        productInformationByCountries: newProductInformationByCountries,
        selectedCountries: this.extractCountryList(newProductInformationByCountries)
      }
    }, () => toast(this.props.t("ADDMODIFY_PRODUCT_ALERT_ERROR_COUNTRIES_DELETED")));
  };

  checkTableData = () => {
    let priceFlag = true;
    let nameFlag = true;
    let taxFlag = true;
    let taxCategoryFlag = true;
    let priceIndiaFlag = true;

    function IsValidPriceRange(item, minimumSaleableAmount, maximumSaleableAmount) {
      return (Number(item.minimumDynamicProductPrice) >= minimumSaleableAmount
          && Number(item.minimumDynamicProductPrice) <= maximumSaleableAmount
          && Number(item.maximumDynamicProductPrice) >= minimumSaleableAmount
          && Number(item.maximumDynamicProductPrice) <= maximumSaleableAmount
          && Number(item.minimumDynamicProductPrice) <= Number(item.maximumDynamicProductPrice))
    }

    for (const item of this.state.productInformationByCountries) {
      let minimumSaleableAmount = getCountriesInformation(this.props.countriesInformation, item.country)?.minimumSaleableAmount;
      let maximumSaleableAmount = getCountriesInformation(this.props.countriesInformation, item.country)?.maximumSaleableAmount;
      let agentCountry = getCountriesInformation(this.props.countriesInformation, item.country)?.isAgentCountry;

      if (isDynamicProduct(this.state.selectedProductType)) {
        if ((item.country === "IN" && Number.isInteger(Number(minimumSaleableAmount)))
            || Number.isInteger(Number(maximumSaleableAmount))) {
          priceIndiaFlag = false;
        }
        if (!IsValidPriceRange(item, minimumSaleableAmount, maximumSaleableAmount)) {
          priceFlag = false;
        }
      } else {
        if (item.country === "IN" && !Number.isInteger(Number(item.productPrice))) {
          priceIndiaFlag = false;
        }
        if (!(item.productPrice >= minimumSaleableAmount && item.productPrice <= maximumSaleableAmount)) {
          priceFlag = false;
        }
      }
      if (item.productName.length === 0) {
        nameFlag = false;
      }
      if (item.priceTaxIncluded && agentCountry) {
        if (item.taxRate.length === 0) {
          taxFlag = false;
        }
      }
      if (getCountriesInformation(this.props.countriesInformation, item.country)?.taxCategoryApplicable && !item.taxCategory) {
        taxCategoryFlag = false;
      }
      if (!(priceFlag && nameFlag && taxFlag && taxCategoryFlag && priceIndiaFlag)) {
        break;
      }
    }
    let alertMessage = "";

    if (!priceIndiaFlag) {
      alertMessage += "Product Price for India cannot be in decimal.";
    }
    if (!priceFlag) {
      alertMessage += this.props.t("ADDMODIFY_PRODUCT_ALERT_ERROR_PROPER_PRICING");
    }
    if (!nameFlag) {
      alertMessage += this.props.t("ADDMODIFY_PRODUCT_ALERT_ERROR_PRODUCT_NAME");
    }
    if (!taxFlag) {
      alertMessage += this.props.t("ADDMODIFY_PRODUCT_ALERT_ERROR_TAX_RATE");
    }
    if (!taxCategoryFlag) {
      alertMessage += this.props.t("ADDMODIFY_PRODUCT_ALERT_ERROR_TAX_CATEGORY");
    }

    if (!(priceFlag && nameFlag && taxFlag && taxCategoryFlag && priceIndiaFlag)) {
      toast(alertMessage);
      return false;
    }
    return true;
  };

  saveProduct = () => {
    const endDate = new Date();
    if (this.state.selectedExpiration === "YEAR") {
      endDate.setFullYear(endDate.getFullYear() + 1);
    } else if (this.state.selectedExpiration === "SIX_MONTH") {
      endDate.setMonth(endDate.getMonth() + 6);
    } else if (this.state.selectedExpiration === "ONE_MONTH") {
      endDate.setMonth(endDate.getMonth() + 1);
    } else {
      endDate.setFullYear(VALID_END_DATE_YEAR);
    }
    const startDate = new Date();
    const endDateString = new Date(endDate.getTime() - (endDate.getTimezoneOffset() * 60000))
        .toISOString()
        .split("T")[0];
    const startDateString = new Date(startDate.getTime() - (startDate.getTimezoneOffset() * 60000))
        .toISOString()
        .split("T")[0];
    let params = {
      cpProductId: this.state.productId,
      productName: this.state.productInformationByCountries[0].productName,
      productDescription: this.state.productDescription,
      productType: productTypeKey2Code[this.state.selectedProductType],
      visibility: visibilityKey2Code[this.state.selectedVisibility],
      productStatus: productStatusKey2Code[this.state.selectedProductStatus],
      modifiedDate: "",
      productDeletable: null,
      registeredDate: this.state.registeredDate,
      validStartDate: startDateString,
      validEndDate: endDateString,

    };
    if (this.state.selectedProductType === SUBSCRIPTION || this.state.selectedProductType === FREE_TRIAL_SUBSCRIPTION) {
      params = {
        ...params,
        cycle: billingPeriodKey2Code[this.state.billingPeriodType],
        frequency: 1,
        freeTrialDays: parseInt(this.state.freeTrialDays),
        subscriptionGroupId: this.state.subscriptionGroupId,
        subscriptionProductLevel: productProductLevelKey2Code[this.state.productLevelType],
        duplicateBenefitYN: this.state.selectedduplicateBenefit === 'YES' ? 'Y' : 'N'
      }
    } else if (isDynamicProduct(this.state.selectedProductType)) {
      params = {
        ...params,
        verifyUrlUseYN: this.state.isVerification ? 'Y' : 'N',
        verifyUrl: this.state.verifyUri
      }
    } else if (this.state.selectedProductType === LIMITED_PERIOD) {
      params = {
        ...params,
        limitedPeriodDays: this.state.limitedDays,
        limitedPeriodHours: this.state.limitedHours,
        limitedPeriodMinutes: this.state.limitedMinutes,
      }
    }
    params = {
      ...params,
      informationByCountries: this.state.productInformationByCountries.map((e) => {
        e.productName = e.productName != null ? e.productName.trim() : "";
        e.taxRate = e.taxRate ? Math.round((e.taxRate / 100) * 100000) / 100000 : null;
        if (e.agentCountryTaxApplicable) {
          e.taxRate = null;
          e.priceTaxIncluded = true;
        }
        return e;
      }),
      cpAppId: this.props.carousel.selectedAppId,
    };

    if (!this.props.isModify) { // Add case
      this.props.startLoading();
      this.httpClient
          .post(URL_ADD_PRODUCT_API, params)
          .then(response => {
            if (response.data.status !== SUCCESS) {
              toast(this.props.t("ADDMODIFY_PRODUCT_ALERT_ERROR_ADDING"));
            } else {
              toast(this.props.t("ADDMODIFY_PRODUCT_ALERT_SUCCESSFULLY_ADDED"));
              this.props.history.push(URL_PRODUCT_LIST);
            }
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
          });

    } else { // Modify case
      this.props.startLoading();
      this.httpClient
          .post(API_PRODUCT_UPDATE, {productId: this.state.originalProductId, ...params})
          .then(response => {
            if (response.data.status !== SUCCESS) {
              toast(this.props.t("ADDMODIFY_PRODUCT_ALERT_ERROR_MODIFYING_PRODUCT"));
            } else {
              toast(this.props.t("ADDMODIFY_PRODUCT_ALERT_SUCCESSFULLY_MODIFIED"));
              this.props.history.push(URL_PRODUCT_LIST);
            }
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
          });
    }
  };

  validateLimitedPeriod = () => {
    return ((this.state.limitedDays === 0 || isNaN(this.state.limitedDays)) &&
            (this.state.limitedHours === 0 || isNaN(this.state.limitedHours)) &&
            (this.state.limitedMinutes === 0 || isNaN(this.state.limitedMinutes)))
        || ((this.state.limitedDays * 24 * 60 + this.state.limitedHours * 24 + this.state.limitedMinutes) > 129600);
  };

  handleSave = (isRegister) => {
    this.setState({savePopup: false});

    // check for error fields
    function validateNewProductDetails() {
      if (this.state.selectedProductType === LIMITED_PERIOD && this.validateLimitedPeriod()) {
        toast(this.props.t("ADDMODIFY_PRODUCT_INVALID_LIMITED_PERIOD_ALERT"));
        return false;
      }
      if (this.state.selectedProductType === SUBSCRIPTION) {
        if (this.state.subscriptionGroupName === null || this.state.subscriptionGroupName.length === 0) {
          toast(this.props.t("SELECT_SUBSCRIPTION_GROUP"));
          return false;
        }
        if (this.state.freeTrialDays === '') {
          toast(this.props.t("FILL_FREE_TRIAL_DAYS"));
          return false;
        }
        if (this.state.productLevelType === null || this.state.productLevelType.length === 0) {
          toast(this.props.t("SELECT_PRODUCT_LEVEL"));
          return false;
        }
        if (this.state.billingPeriodType === null || this.state.billingPeriodType.length === 0) {
          toast(this.props.t("SELECT_BILLING_PERIOD"));
          return false;
        }
      }
      if (isDynamicProduct(this.state.selectedProductType)) {
        if (this.state.isVerification && this.state.verifyUri.length === 0) {
          toast(this.props.t("ALL_FIELD_REQUIRED_ALERT"));
          return false;
        }
        if (this.state.isVerification && this.state.verifyUri.length > 0) {
          if (!(/^((http|https):\/\/)?(www)?/g).test(this.state.verifyUri) || (/\s/g).test(this.state.verifyUri)) {
            toast(this.props.t("VERIFY_URL_INVALID_ALERT"));
            return false;
          }
          if (this.state.verifyUri.length > 200) {
            toast(this.props.t("VERIFY_URL_INVALID_LENGTH_ALERT"));
            return false;
          }
        }
      }
      return true;
    }

    if (isRegister) { // add case
      if (!this.state.productId.length || !this.state.idAvailable) {
        this.focusId.current.focus();
        toast(this.props.t("VERIFY_PRODUCT_ID"));
        return;
      }
      if (!this.state.selectedProductType) {
        this.setState({focusType: true});
        toast(this.props.t("SELECT_PRODUCT_TYPE_ALERT"));
        return;
      }
      if (!validateNewProductDetails.call(this)) {
        return;
      }
    }
    // for both cases (add and modify)
    if (this.state.productDescription.trim().length <= 5) {
      this.focusDescription.current.focus();
      toast(this.props.t("USE_MORE_THAN_5WORDS_ALERT"));
      return;
    }
    let informationByCountries = this.state.productInformationByCountries.map((e) => {
      e.productName = e.productName != null ? e.productName.trim() : "";
      return e;
    });
    this.setState({
      productInformationByCountries: informationByCountries
    });
    if (this.checkTableData()) {
      this.saveProduct();
    }
  };

  filterInput = (event) => {
    let input = event.target.value;
    const regex = /[";:&<>,|]/gi;
    return input.replace(regex, "");
  };

  checkAvailability = () => {
    if (this.state.productId === "") {
      this.setState({idAvailable: false})
    } else {
      this.props.startLoading();
      this.httpClient
          .get(URL_GET_CHECK_ID_API, {
            params: {
              cpAppId: this.props.carousel.selectedAppId,
              cpProductId: this.state.productId
            }
          })
          .then(response => {
            this.setState({idAvailable: response.data.productIdCheck === "true"});
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
          });
    }
  };

  handleProductDescription = (event) => {
    let input = event.target.value;
    this.setState({
      productDescription: input
    });
  };

  verifyUriChanged = (event) => {
    let input = event.target.value;
    this.setState({
      verifyUri: input
    });
  };

  changeDays = (event) => {
    let input = event.target.value;
    if (parseInt(input) < 0 || parseInt(input) > 90) {
      this.setState({limitedDays: 0})
    } else if (parseInt(input) === 90) {
      this.setState({
        limitedDays: parseInt(input),
        limitedHours: 0,
        limitedMinutes: 0
      });
    } else {
      this.setState({
        limitedDays: parseInt(input)
      });
    }
  };

  changeHours = (event) => {
    let input = event.target.value;
    if (parseInt(input) < 0 || parseInt(input) > 23) {
      this.setState({limitedHours: 0})
    } else {
      this.setState(prevState => {
        return {
          limitedHours: prevState.limitedDays === 90 ? 0 : parseInt(input)
        }
      });
    }
  };

  changeMinutes = (event) => {
    let input = event.target.value;
    if (parseInt(input) < 0 || parseInt(input) > 59) {
      this.setState({limitedMinutes: 0})
    } else {
      this.setState(prevState => {
        return {
          limitedMinutes: prevState.limitedDays === 90 ? 0 : parseInt(input)
        }
      });
    }
  };

  productTypeChangeHandler = (selected) => {
    if (selected) {
      this.setState({selectedProductType: selected.value}, () => this.fetchSubscriptionGroupList(selected.value));
    } else {
      this.setState({selectedProductType: null});
    }
  };

  fetchSubscriptionGroupList = (type) => {
    if (type === SUBSCRIPTION && !this.subscriptionGroupList) {
      const params = {cpAppId: this.props.carousel.selectedAppId};
      return new Promise((resolve, reject) => {
        this.props.startLoading();
        this.httpClient
            .get(API_PRODUCT_SUBSCRIPTION_GROUP_NAME_LIST, {params: params})
            .then(response => {
              this.setState({subscriptionGroupList: response.data});
              resolve();
              this.props.stopLoading();
            })
            .catch(error => {
              handleHttpError(this.props.t, this.props.history, error);
              reject(error);
              this.props.stopLoading();
            });
      })
    }
    return Promise.resolve(this.props.t("CONDITION_NOT_MET"));

  };

  billingPeriodChangeHandler = (selected) => {
    this.setState({billingPeriodType: selected.value});
  };

  productLevelChangeHandler = (selected) => {
    this.setState({productLevelType: selected.value});
  };

  subscriptionGroupChangeHandler = (selected) => {
    this.setState({
      subscriptionGroupName: selected.value,
      subscriptionGroupId: selected.key
    });
  };

  setProductStatus = (option) => {
    this.setState({selectedProductStatus: option});
  };

  changeVerification = (selected) => {
    this.setState({isVerification: selected});
  };

  setExpiration = (option) => {
    this.setState({selectedExpiration: option});
  };

  setVisibility = (option) => {
    this.setState({selectedVisibility: option});
  };

  setDuplicateBenefit = (option) => {
    this.setState({selectedduplicateBenefit: option});
  };

  handleProductIdChange = (event) => {
    let input = event.target.value;
    this.setState({
      productId: input,
      idAvailable: null
    });
  };

  handleCountryProductNameChange = (country, event) => {
    let input = event.target.value;
    this.setState(prevState => {
      return {
        productInformationByCountries: prevState.productInformationByCountries.map((item) => {
          if (item.country === country) {
            item.productName = input
          }
          return item;
        })
      }
    });
  };

  handleCountryProductPriceChange = (country, event) => {
    let input = event.target.value;
    this.setState(prevState => {
      return {
        productInformationByCountries: prevState.productInformationByCountries.map((item) => {
          if (item.country === country) {
            item.productPrice = input
          }
          return item;
        })
      }
    });
  };

  handleCountryProductMinPriceChange = (country, event) => {
    let input = event.target.value;
    this.setState(prevState => {
      return {
        productInformationByCountries: prevState.productInformationByCountries.map((item) => {
          if (item.country === country) {
            item.minimumDynamicProductPrice = input
          }
          return item;
        })
      }
    });
  };

  handleCountryProductMaxPriceChange = (country, event) => {
    let input = event.target.value;
    this.setState(prevState => {
      return {
        productInformationByCountries: prevState.productInformationByCountries.map((item) => {
          if (item.country === country) {
            item.maximumDynamicProductPrice = input
          }
          return item;
        })
      }
    });
  };

//TODO: Check if within limits
  handleCountryProductTaxRateChange = (country, event) => {
    let input = event.target.value;
    this.setState(prevState => {
      return {
        productInformationByCountries: prevState.productInformationByCountries.map((item) => {
          if (item.country === country) {
            item.taxRate = input
          }
          return item;
        })
      }
    });
  };

  handleCountryProductTaxIncludedChange = (country, event) => {
    this.setState(prevState => {
      return {
        productInformationByCountries: prevState.productInformationByCountries.map((item) => {
          if (item.country === country) {
            item.priceTaxIncluded = !item.priceTaxIncluded;
            item.taxRate = "";
          }
          return item;
        })
      }
    });
  };

  handleFreeTrialDaysChange = (event) => {
    const re = new RegExp("^[0-9]\\d*$");
    let input = event.target.value;
    this.setState({
      freeTrialDays: re.test(input) ? input : ''
    })
  };

  handleCountryProductTaxCategoryChange = (country, selected) => {
    this.setState(prevState => {
      return {
        productInformationByCountries: prevState.productInformationByCountries.map((item) => {
          if (item.country === country) {
            item.taxCategory = selected.value
          }
          return item;
        })
      }
    });
  };

  handleApplyAllNames = (newName) => {
    if (newName.length === 0) {
      toast(this.props.t("ENTER_PRODUCT_NAME_FOR_DEFAULT_COUNTRY"));
      return;
    }

    this.setState(prevState => {
      return {
        productInformationByCountries: prevState.productInformationByCountries.map((item) => {
          item.productName = newName;
          return item;
        })
      }
    });
    toast(this.props.t("APPLY_PRODUCT_NAME_ALL_COUNTRY_MESSAGE"));
  };

  handleApplyAllDynamicProductPrices = (dynamicMinPrice, dynamicMaxPrice, defaultCountry) => {
    if ((dynamicMinPrice ? dynamicMinPrice.length === 0 : true) || (dynamicMaxPrice ? dynamicMaxPrice.length === 0 : true)) {
      toast(this.props.t("ENTER_PRODUCT_PRICE_FOR_DEFAULT_COUNTRY"));
      return;
    }
    let defaultCountryUSDMinPrice = ((Number(dynamicMinPrice) * 1000000000) / (getCountriesInformation(this.props.countriesInformation, defaultCountry)?.usdBaseExchangeCurrencyRate * 1000000000));
    let defaultCountryUSDMaxPrice = ((Number(dynamicMaxPrice) * 1000000000) / (getCountriesInformation(this.props.countriesInformation, defaultCountry)?.usdBaseExchangeCurrencyRate * 1000000000));
    this.setState(prevState => {
      return {
        productInformationByCountries: prevState.productInformationByCountries.map((item) => {
          const countryInfo = getCountriesInformation(this.props.countriesInformation, item.country);
          let setMinPrice = ((defaultCountryUSDMinPrice * 1000000000) * (countryInfo?.usdBaseExchangeCurrencyRate * 1000000000)) / (1000000000 * 1000000000);
          item.minimumDynamicProductPrice = (setMinPrice).toFixed(countryInfo?.currencyDecimalRoundingLength);
          if (item.minimumDynamicProductPrice < Number(countryInfo?.minimumSaleableAmount)) {
            item.minimumDynamicProductPrice = countryInfo?.minimumSaleableAmount;
          } else if (item.minimumDynamicProductPrice > Number(countryInfo?.maximumSaleableAmount)) {
            item.minimumDynamicProductPrice = countryInfo?.maximumSaleableAmount;
          }
          let setMaxPrice = ((defaultCountryUSDMaxPrice * 1000000000) * (countryInfo?.usdBaseExchangeCurrencyRate * 1000000000)) / (1000000000 * 1000000000);
          item.maximumDynamicProductPrice = (setMaxPrice).toFixed(countryInfo?.currencyDecimalRoundingLength);
          if (item.maximumDynamicProductPrice < Number(countryInfo?.minimumSaleableAmount)) {
            item.maximumDynamicProductPrice = countryInfo?.minimumSaleableAmount;
          } else if (item.maximumDynamicProductPrice > Number(countryInfo?.maximumSaleableAmount)) {
            item.maximumDynamicProductPrice = countryInfo?.maximumSaleableAmount;
          }
          return item;
        })
      }
    });
  };

  handleApplyAllPrices = (price, dynamicMinPrice, dynamicMaxPrice) => {
    const app = getAppFromSessionData(this.props.sessionData, this.props.carousel.selectedAppId);
    if (isDynamicProduct(this.state.selectedProductType)) {
      this.handleApplyAllDynamicProductPrices(dynamicMinPrice, dynamicMaxPrice, app.defaultCountry);
    } else {
      if (price ? price.length === 0 : true) {
        toast(this.props.t("ENTER_PRODUCT_PRICE_FOR_DEFAULT_COUNTRY"));
        return;
      }
      let defaultCountryUSDPrice = ((Number(price) * 1000000000) / (getCountriesInformation(this.props.countriesInformation, app.defaultCountry)?.usdBaseExchangeCurrencyRate * 1000000000));
      this.setState(prevState => {
        return {
          productInformationByCountries: prevState.productInformationByCountries.map((item) => {
            const countryInfo = getCountriesInformation(this.props.countriesInformation, item.country);
            let setPrice = ((defaultCountryUSDPrice * 1000000000) * (countryInfo?.usdBaseExchangeCurrencyRate * 1000000000)) / (1000000000 * 1000000000);
            item.productPrice = (setPrice).toFixed(countryInfo?.currencyDecimalRoundingLength);
            if (item.productPrice < Number(countryInfo?.minimumSaleableAmount)) {
              item.productPrice = countryInfo?.minimumSaleableAmount;
            } else if (item.productPrice > Number(countryInfo?.maximumSaleableAmount)) {
              item.productPrice = countryInfo?.maximumSaleableAmount;
            }
            return item;
          })
        }
      });
    }
    toast(this.props.t("APPLY_PRODUCT_PRICE_ALL_COUNTRY_MESSAGE"));
  };

  initCountryData = () => {
    this.props.startLoading();
    fetchTaxCategoryList(API_PRODUCT_TAX_CATEGORY_LIST, this.props.t, this.props.history).then(response => {
      this.setState({taxCategoryData: response});
      fetchCountries(URL_GET_COUNTRY_DATA_API, this.props.t, this.props.history)
          .then(countryData => {
            this.setState({countryData: countryData});
            fetchCountries(URL_GET_COUNTRY_INFORMATION_API, this.props.t, this.props.history)
                .then((countryInformation => {
                  this.props.stopLoading();
                  this.props.loadCountriesInformation(countryInformation);
                  this.initData();
                }));
          });
    })
  };

  render() {
    if (!this.state.init) {
      return <div/>;
    }
    const productTypes = productAttributes.productType.value.filter(word => word !== "FREE_TRIAL_SUBSCRIPTION");
    const billingPeriodOptions = productAttributes.billingPeriod.value;
    const productLevelOptions = productAttributes.productLevel.value;
    const app = getAppFromSessionData(this.props.sessionData, this.props.carousel.selectedAppId);
    return (
        <form className="needs-validation" noValidate>
          <div className="clearfix mb-20">
            <h2 className="float-left mb-0">
              <strong>{this.props.isModify ? this.props.t("PRODUCT_INFORMATION") : this.props.t("PRODUCT_CONFIGURATION")}</strong>
            </h2>
            <div className="text-right text-success small float-right mt-1 mb-0">
              {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */}
              <a onClick={this.handlePopupOpenClick}
                 className="text-400 mr-10">{this.props.t("TAX_RATE_AND_PRICING_GUIDE")}<i
                  className="icon icon-modal align-top ml-1"/></a>
              <span className="align-middle">*</span>{this.props.t("INDICATES_REQUIRED_FIELD")}
              <span className={`tooltips right ${this.state.isTooltipOpen && "show"}`}>
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */}
                <a onClick={this.handleTooltipOpenClick} className="tooltip-toggle">?</a>
          <div className="tooltips_body">
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */}
            <a onClick={this.handleTooltipCloseClick} className={"tooltip-toggle close"}/>
            {this.props.t("TOOLTIPLINE_1")}<br/>
            {this.props.t("TOOLTIPLINE_2")}<br/>
            {this.props.isModify &&
                <>
                  <br/>
                  <span className="text-primary">
                    {this.props.t("TOOLTIPLINE_3")}
                    </span>
                </>
            }
          </div>
          </span>
            </div>
          </div>
          <div className="form-group-wrap">
            {this.props.isModify &&
                <div className="form-group row">
                  <label className="col col-form-label">
                    {this.props.t("PRODUCT_ID")}
                    <span className="text-success align-middle">*</span>
                  </label>
                  <div className="col">
                    <div className="row">
                      <div className="col-12">
                        <input type="text" ref={this.focusId} className="form-control"
                               value={this.state.productId} readOnly="readonly"/>
                      </div>
                    </div>
                  </div>
                </div>
            }
            {!this.props.isModify &&
                <div className="row mb-3">
                  <div className="col-6">
                    <div className="form-group row">
                      <label className="col col-form-label">
                        {this.props.t("PRODUCT_ID")}
                        <span className="text-success align-middle">*</span>
                      </label>
                      <div className="col">
                        <div className="input-group">
                          <input type="text"
                                 ref={this.focusId}
                                 className="form-control"
                                 maxLength={50}
                                 value={this.state.productId}
                                 placeholder={this.props.t("PLEASE_ENTER_PRODUCT_ID")}
                                 onChange={(event) => {
                                   event.target.value = this.filterInput(event);
                                   this.handleProductIdChange(event);
                                 }}/>
                          <div className="input-group-append">
                            <button type="button" className="btn btn-primary" onClick={this.checkAvailability}>
                              {this.props.t("CHECK_AVAILABILITY")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    {this.state.idAvailable === true &&
                        <span className="text-success mt-10 d-inline-block">
                          <i className="icon icon-check align-middle mb-1"/>
                          {this.props.t("PRODUCT_ID_AVAILABLE")}
                        </span>
                    }
                    {this.state.idAvailable === false &&
                        <span className="text-danger mt-10 d-inline-block"><i className="icon icon-error align-middle mb-1"/>
                          {this.props.t("PRODUCT_ID_NOT_AVAILABLE")}
                        </span>
                    }
                  </div>
                </div>
            }

            {this.props.isModify &&
                <>
                  <div className="form-group row">
                    <label className="col col-form-label">
                      {this.props.t("DESCRIPTION")}
                      <span className="text-success align-middle">*</span>
                    </label>
                    <div className="col">
                      <DescriptionPanel
                          value={this.state.productDescription}
                          placeHolder={this.props.t('DESCRIPTION_PLACEHOLDER')}
                          characterCount={this.state.productDescription.length}
                          handleProductDescription={this.handleProductDescription}
                          filterInput={this.filterInput}
                          readOnly={this.state.readOnly}
                          focus={this.focusDescription}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-6">
                      <div className="form-group row">
                        <label className="col col-form-label">
                          {this.props.t("PROD_STATUS")}
                          <span className="text-success align-middle">*</span>
                        </label>
                        <div className="col">
                          <div className="btn-group btn-group-toggle btn-group-radio"
                               data-toggle="buttons">
                            <label
                                className={`btn btn-radio ${this.state.selectedProductStatus === "ACTIVE" && "checked"} ${this.state.readOnly && "disabled"}`}><input
                                type="radio"
                                onClick={() => this.setProductStatus('ACTIVE')}/>{this.props.t("ACTIVE")}
                            </label>
                            {!this.state.productInfo.productDeletable ?
                                (
                                    <label
                                        className={`btn btn-radio ${this.state.selectedProductStatus === "RETIRED" && "checked"} ${this.state.readOnly && "disabled"}`}><input
                                        type="radio"
                                        onClick={() => this.setProductStatus('RETIRED')}/>{this.props.t("RETIRED")}
                                    </label>
                                ) : (
                                    <label
                                        className={`btn btn-radio ${this.state.selectedProductStatus === "DELETED" && "checked"} ${this.state.readOnly && "disabled"}`}><input
                                        type="radio"
                                        onClick={() => this.setProductStatus('DELETED')}/>{this.props.t("DELETED")}
                                    </label>
                                )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-6">
                      <div className="form-group row">
                        <label className="col col-form-label">{this.props.t("REGISTERED")}</label>
                        <div className="col">
                          <div className="row">
                            <div className="col-12">
                              <input type="text" className="form-control" value={this.state.registeredDate} readOnly="readonly"/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group row">
                        <label className="col col-form-label">{this.props.t("LAST_MODIFIED")}</label>
                        <div className="col">
                          <div className="row">
                            <div className="col-12">
                              <input type="text" className="form-control" value={this.state.modifiedDate} readOnly="readonly"/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
            }
            {!this.props.isModify &&
                <div className="form-group row">
                  <label className="col col-form-label">
                    {this.props.t("DESCRIPTION")}
                    <span className="text-success align-middle">*</span>
                  </label>
                  <div className="col">
                    <DescriptionPanel
                        value={this.state.productDescription}
                        placeHolder={this.props.t('DESCRIPTION_PLACEHOLDER')}
                        characterCount={this.state.productDescription.length}
                        handleProductDescription={this.handleProductDescription}
                        filterInput={this.filterInput}
                        focus={this.focusDescription}
                    />
                  </div>
                </div>
            }

            <div className="row mb-3">
              <div className="col-6">
                <div className="form-group row">
                  <label className="col col-form-label">
                    {this.props.t("PRODUCT_TYPE")}
                    <span className="text-success align-middle">*</span>
                  </label>
                  <div className="col">
                    <SelectrWrapper
                        isDisable={this.props.isModify}
                        className="form-control"
                        clearable={true}
                        placeholder={this.props.t("SELECT")}
                        value={(this.state.selectedProductType === SUBSCRIPTION || this.state.selectedProductType === FREE_TRIAL_SUBSCRIPTION) ? SUBSCRIPTION : this.state.selectedProductType}
                        onChange={(selected) => this.productTypeChangeHandler(selected)}>
                      {productTypes.map(v => <option key={v} value={v}>{this.props.t(v)}</option>)}
                    </SelectrWrapper>
                  </div>
                </div>
              </div>
            </div>
            <div className="tabs-toggle-container">
              {(this.state.selectedProductType === "CONSUMABLE" || this.state.selectedProductType === "NON_CONSUMABLE" || this.state.selectedProductType === null) &&
                  <div className="tabs-toggle-content show"/>
              }
              {this.state.selectedProductType === LIMITED_PERIOD &&
                  <div className="tabs-toggle-content show">
                    <div className="row mb-3">
                      <div className="col-6">
                        <div className="form-group row">
                          <label className="col col-form-label"/>
                          <div className="col">
                            <div className="input-group">
                              <input type="number" min={0} max={90} className="form-control text-right rounded"
                                     disabled={this.props.isModify} value={this.state.limitedDays}
                                     onChange={this.changeDays}
                                     maxLength={2}/>
                              <div className="input-group-append">
                                <span className="input-group-text bg-white border-0">{this.props.t("DAYS_")}</span>
                              </div>
                              <input type="number" min={0} max={23}
                                     className="form-control text-right rounded"
                                     disabled={this.props.isModify} value={this.state.limitedHours}
                                     onChange={this.changeHours}
                                     maxLength={2}/>
                              <div className="input-group-append">
                                <span className="input-group-text bg-white border-0">{this.props.t("HOURS")}</span>
                              </div>
                              <input type="number" min={0} max={59}
                                     className="form-control text-right rounded"
                                     disabled={this.props.isModify} value={this.state.limitedMinutes}
                                     onChange={this.changeMinutes}
                                     maxLength={2}/>
                              <div className="input-group-append">
                                <span className="input-group-text bg-white border-0">{this.props.t("MINUTES")}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              }
              {(this.state.selectedProductType === SUBSCRIPTION || this.state.selectedProductType === FREE_TRIAL_SUBSCRIPTION) &&
                  <>
                    <div className="row mb-3">
                      <div className="col-6">
                        <div className="form-group row">
                          <label className="col col-form-label">{this.props.t("BILLING_PERIOD")}<span className="text-success align-middle">*</span></label>
                          <div className="col">
                            <SelectrWrapper
                                isDisable={this.props.isModify}
                                className="form-control"
                                clearable={true}
                                placeholder="Select"
                                value={this.state.billingPeriodType}
                                onChange={(selected) => this.billingPeriodChangeHandler(selected)}>
                              {billingPeriodOptions.map(v => <option key={v} value={v}>{this.props.t(v)}</option>)}
                            </SelectrWrapper>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-6">
                        <div className="form-group row">
                          <label className="col col-form-label">{this.props.t("PRODUCT_LEVEL")}<span className="text-success align-middle">*</span></label>
                          <div className="col">
                            <SelectrWrapper
                                className="form-control"
                                clearable={true}
                                placeholder="Select"
                                value={this.state.productLevelType}
                                onChange={(selected) => this.productLevelChangeHandler(selected)}>
                              {productLevelOptions.map(v => <option key={v} value={v}>{this.props.t(v)}</option>)}
                            </SelectrWrapper>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-6">
                        <div className="form-group row">
                          <label className="col col-form-label">{this.props.t("SUBSCRIPTION")}<br/>{this.props.t("GROUP")}<span className="text-success align-middle">*</span></label>
                          <div className="col">
                            <SelectrWrapper
                                isDisable={this.props.isModify}
                                className="form-control"
                                clearable={true}
                                placeholder={this.state.subscriptionGroupName === "" ? this.props.t("SUBSCRIPTION_GROUP_NAME") : this.state.subscriptionGroupName}
                                value={this.state.subscriptionGroupName !== null ? this.state.subscriptionGroupName : ""}
                                onChange={(selected) => this.subscriptionGroupChangeHandler(selected)}>
                              {this.state.subscriptionGroupList.map(v => <option key={v.groupId} value={v.groupName}>{this.props.t(v.groupName)}</option>)}
                            </SelectrWrapper>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-4">
                        <div className="form-group row">
                          <label className="col col-form-label">{this.props.t("FREE_TRIAL_PERIOD")}<span className="text-success align-middle">*</span></label>
                          <div className="col">
                            <div className="input-group">
                              <input type="text"
                                     className="form-control"
                                     value={this.state.freeTrialDays}
                                     onChange={(event) => {
                                       this.handleFreeTrialDaysChange(event);
                                     }}/>
                              <div className="input-group-append">
                                <span className="input-group-text bg-white border-0">{this.props.t("DAYS")}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col col-form-label">{this.props.t("DUPLICATE_BENEFIT")}<span className="text-success align-middle">*</span></label>
                      <div className="col">
                        <div className="btn-group btn-group-toggle btn-group-radio" data-toggle="buttons">
                          <label className={`btn btn-radio ${this.state.selectedduplicateBenefit === "NO" && "checked"} ${this.state.readOnly && "disabled"}`}><input disabled={this.state.readOnly} type="radio" onClick={() => this.setDuplicateBenefit('NO')}/>{this.props.t("NOPE")}</label>
                          <label className={`btn btn-radio ${this.state.selectedduplicateBenefit === "YES" && "checked"} ${this.state.readOnly && "disabled"}`}><input disabled={this.state.readOnly} type="radio" onClick={() => this.setDuplicateBenefit('YES')}/>{this.props.t("YES")}</label>
                        </div>
                      </div>
                    </div>
                  </>
              }
              {isDynamicProduct(this.state.selectedProductType) &&
                  <div className="tabs-toggle-content show">
                    <div className="row mb-3">
                      <div className="col-12">
                        <div className="form-group row">
                          <label className="col col-form-label"/>
                          <div className="col">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <div className="btn-group btn-group-toggle btn-group-radio">
                                  <label className={`btn btn-outline-500 rounded btn-auto mr-2 ${this.state.isVerification && "checked"}`}>
                                    <input type="radio" disabled={this.props.isModify} onClick={() => this.changeVerification(true)}/>
                                    {this.props.t("VERIFICATION")}
                                  </label>
                                  <label className={`btn btn-outline-500 rounded btn-auto mr-2 ${!this.state.isVerification && "checked"}`}>
                                    <input type="radio" disabled={this.props.isModify} onClick={() => this.changeVerification(false)}/>
                                    {this.props.t("NO_VERIFICATION")}
                                  </label>
                                </div>
                              </div>
                              <div className="input-group-prepend">
                                <div className="input-group-text bg-white border-0">
                                  {this.props.t("VERIFY_URI")}
                                </div>
                              </div>
                              <input type="text" className="form-control rounded text-right"
                                     value={this.state.verifyUri}
                                     maxLength={200}
                                     disabled={!this.state.isVerification || this.props.isModify}
                                     onChange={this.verifyUriChanged}/>
                              <div className="input-group-prepend">
                                <span className="input-group-text bg-white border-0">{this.props.t("SAMSUNG_CHECKOUT_VERIFY")}</span>
                              </div>
                            </div>
                            <div className="mt-15 mb-10 text-muted small">
                              <span className="text-success">*</span>
                              {this.props.t("VERIFICATION_1")}<br/>
                              <span className="text-success">*</span>
                              {this.props.t("VERIFICATION_2")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              }
            </div>
            <div className="form-group row">
              <label className="col col-form-label">
                {this.props.t("VISIBILITY")}
                <span className="text-success align-middle">*</span>
              </label>
              <div className="col">
                <div className="btn-group btn-group-toggle btn-group-radio" data-toggle="buttons">
                  {/*TODO: remove duplicates, refactor*/}
                  <label
                      className={`btn btn-radio ${this.state.selectedVisibility === "HIDE" && "checked"} ${this.state.readOnly && "disabled"}`}><input
                      disabled={this.state.readOnly} type="radio"
                      onClick={() => this.setVisibility('HIDE')}/>{this.props.t("HIDE")}</label>
                  <label
                      className={`btn btn-radio ${this.state.selectedVisibility === "SHOW" && "checked"} ${this.state.readOnly && "disabled"}`}><input
                      disabled={this.state.readOnly} type="radio"
                      onClick={() => this.setVisibility('SHOW')}/>{this.props.t("SHOW")}</label>
                  <label
                      className={`btn btn-radio ${this.state.selectedVisibility === "OPTIONAL" && "checked"} ${this.state.readOnly && "disabled"}`}><input
                      disabled={this.state.readOnly} type="radio"
                      onClick={() => this.setVisibility('OPTIONAL')}/>{this.props.t("OPTIONAL")}</label>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col col-form-label">{this.props.t("EXPIRATION")}</label>
              <div className="col">
                <div className="btn-group btn-group-toggle btn-group-radio" data-toggle="buttons">
                  {/*TODO: remove duplicates, refactor*/}
                  <label
                      className={`btn btn-radio ${this.state.selectedExpiration === "NOT_APPLICABLE" && "checked"} ${this.state.readOnly && "disabled"}`}><input
                      disabled={this.state.readOnly} type="radio"
                      onClick={() => this.setExpiration('NOT_APPLICABLE')}/>{this.props.t("NOT_APPLICABLE")}
                  </label>
                  <label
                      className={`btn btn-radio ${this.state.selectedExpiration === "ONE_MONTH" && "checked"} ${this.state.readOnly && "disabled"}`}><input
                      disabled={this.state.readOnly} type="radio"
                      onClick={() => this.setExpiration('ONE_MONTH')}/>{this.props.t("ONE_MONTH")}</label>
                  <label
                      className={`btn btn-radio ${this.state.selectedExpiration === "SIX_MONTH" && "checked"} ${this.state.readOnly && "disabled"}`}><input
                      disabled={this.state.readOnly} type="radio"
                      onClick={() => this.setExpiration('SIX_MONTH')}/>{this.props.t("SIX_MONTH")}</label>
                  <label
                      className={`btn btn-radio ${this.state.selectedExpiration === "YEAR" && "checked"} ${this.state.readOnly && "disabled"}`}><input
                      disabled={this.state.readOnly} type="radio"
                      onClick={() => this.setExpiration('YEAR')}/>{this.props.t("YEAR")}</label>
                </div>
              </div>
            </div>
            {this.props.isModify && this.state.selectedProductType === SUBSCRIPTION ? (<div className="form-group row">
              <label className="col col-form-label">{this.props.t("FLEXIBLE_OFFER")}</label>
              <div className="col">
                <Link to={{}} onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push(URL_PRODUCT_FLEXIBLEOFFER + "/" + this.state.originalProductId);
                }}>
                  <button
                      type="button"
                      className={`btn btn-sm btn-auto btn-outline-success ${this.state.readOnly && "disabled"}`}
                      disabled={this.state.readOnly}
                  >
                    {this.props.t("CONFIGURE")}
                  </button>
                </Link>
              </div>
            </div>) : <></>}
          </div>
          <div className="clearfix mb-10 mt-60">
            <h2 className="float-left mb-0">
              {this.props.isModify ?
                  <strong>{this.props.t("COUNTRIES_AND_PRICING_INFORMATION")}</strong>
                  :
                  <strong>{this.props.t("COUNTRIES_AND_PRICING_CONFIGURATION")}</strong>
              }
              <span className="text-success align-middle">*</span></h2>
            <div className="float-right">
              <div className="btn-group-toggle btn-group-checkbox ml-7" data-toggle="buttons">
                <button
                    type="button"
                    className={`btn btn-sm btn-auto btn-success ${this.state.readOnly && "disabled"}`}
                    disabled={this.state.readOnly}
                    onClick={this.handlePopupApplyAll}>
                  {this.props.t("APPLY_ALL")}
                </button>
              </div>
            </div>
          </div>
          {this.state.productInformationByCountries.length > 0 ?
              <div className="table-overlay">
                <DataTableWrapperForProductAddModify
                    productCountryData={this.state.productInformationByCountries}
                    isDynamic={isDynamicProduct(this.state.selectedProductType)}
                    readOnly={this.state.readOnly}
                    onCheck={this.handleCheck}
                    onCheckAll={this.handleCheckAll}
                    taxcategoryData={this.state.taxCategoryData}
                    onCountryProductNameChange={this.handleCountryProductNameChange}
                    onCountryProductPriceChange={this.handleCountryProductPriceChange}
                    onCountryProductMinPriceChange={this.handleCountryProductMinPriceChange}
                    onCountryProductMaxPriceChange={this.handleCountryProductMaxPriceChange}
                    onCountryProductTaxRateChange={this.handleCountryProductTaxRateChange}
                    onCountryProductTaxIncludedChange={this.handleCountryProductTaxIncludedChange}
                    onCountryProductTaxCategoryChange={this.handleCountryProductTaxCategoryChange}
                    filterInput={this.filterInput}
                />
              </div>
              :
              <div className="form-group-wrap d-flex justify-content-center">
                <div>
                  <button type="button"
                          className="btn btn-sm btn-auto btn-outline-primary"
                          onClick={this.handleAddCountry}>{this.props.t("ADD_COUNTRY")}
                  </button>
                </div>
              </div>
          }
          {isDynamicProduct(this.state.selectedProductType) &&
              <div className="row mt-20">
                <div className="col">
                  <span className="text-danger">{this.props.t("CAUTION")}</span>
                  {this.props.t("CAUTION_1")}
                </div>
              </div>}
          <div className="row mt-30">
            <div className="col-6">
              <button
                  type="button"
                  className={`btn btn-sm btn-auto btn-success ${this.state.readOnly && "disabled"}`}
                  disabled={this.state.readOnly}
                  onClick={this.handleAddCountry}
              >
                {this.props.t("ADD_COUNTRY")}
              </button>
              <button
                  type="button"
                  className={`btn btn-sm btn-auto btn-outline-success ml-8 ${this.state.readOnly && "disabled"}`}
                  disabled={this.state.readOnly || !this.state.productInformationByCountries.find(item => item.isSelected)}
                  onClick={this.handleDeleteCountry}
              >
                {this.props.t("DELETE_COUNTRY")}
              </button>
            </div>
            <div className="col-6 text-right">
              <Link to={URL_PRODUCT_LIST}>
                <button type="button" className="btn btn-sm btn-auto btn-outline-primary">
                  {this.props.t("LIST")}
                </button>
              </Link>
              {!this.props.isModify ?
                  <button
                      type="button"
                      className={`btn btn-sm btn-auto ml-10 ${this.state.productInformationByCountries.length <= 0 ? "btn-success disabled" : "btn-primary"}`}
                      onClick={() => this.handleSave(true)}
                      disabled={this.state.productInformationByCountries.length <= 0}
                  >
                    {this.props.t("REGISTER")}
                  </button>
                  :
                  <button
                      type="button"
                      className={`btn btn-sm btn-auto ml-10 ${this.state.productInformationByCountries.length <= 0 || this.state.readOnly ? "btn-success disabled" : "btn-primary"}`}
                      onClick={() => this.setState({savePopup: true})}
                      disabled={this.state.productInformationByCountries.length <= 0 || this.state.readOnly}
                  >
                    {this.props.t("SAVE")}
                  </button>}
            </div>
          </div>
          <ApplyAllProductPopup
              open={this.state.isApplyAllPopupOpen}
              handleModalClose={this.handleClosePopupApplyAll}
              readOnly={false}
              isDynamic={isDynamicProduct(this.state.selectedProductType)}
              productInformationByCountries={[getCountriesInformation(this.props.countriesInformation, app.defaultCountry)]}
              filterInput={this.filterInput}/>
          <TaxPricingGuidePopup
              handleModalClose={this.handlePopupCloseClick}
              open={this.state.isTaxGuidePopupOpen}/>
          <CountryListPopUp
              countryData={this.state.countryData}
              open={this.state.isCountryListPopupOpen}
              title={this.props.t("ADD_COUNTRY")}
              readOnly={false}
              initCountries={this.state.selectedCountries}
              handleModalClose={this.handleCountryListModalClose}
              disabledCountry={[]}
              isMultipleSelect={true}
              isContinentSelect={true}/>
          <BasePopup
              open={this.state.savePopup}
              className="modal-sm"
              title={this.props.t("ALERT")}
              onClose={() => this.setState({savePopup: false})}
              body={
                <p>{this.props.t("DO_YOU_WANT_TO_SAVE_THE_CHANGES")}</p>
              }
              footer={
                <>
                  <button type="button" className="btn btn-secondary" onClick={() => this.handleSave(false)}>
                    {this.props.t("CONFIRM")}
                  </button>
                  <button type="button" className="btn btn-outline-secondary" onClick={() => this.setState({savePopup: false})}>
                    {this.props.t("CANCEL")}
                  </button>
                </>
              }/>
        </form>
    )
  }

  componentDidMount() {
    this.initCountryData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (isSelectedAppChanged(this.props.carousel.selectedAppId, prevProps.carousel.selectedAppId)
        || isCountriesInformationChanged(this.props.countriesInformation, prevProps.countriesInformation)) {
      this.initCountryData()
    }
  }
}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel,
    sessionData: state.sessionData,
    countriesInformation: state.countriesInformation
  };
};

const mapDispatchToProps = {
  loadCountriesInformation: loadCountriesInformation,
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(AddModifyProductPanel)));