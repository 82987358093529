import React from "react";
import {withTranslation} from "react-i18next";

class RadioButtonPanel extends React.Component {

  setSelection = (option) => {
    this.props.onButtonSelect(option);
  };

  render() {
    return (<div className="form-group row">
      <div className="col">
        <div className="btn-group btn-group-toggle btn-group-radio" data-toggle="buttons">
          <div className="form-group row">
            <label className="col col-form-label">{this.props.label}</label>
            <div className="col">
              <div className="btn-group btn-group-toggle btn-group-radio" data-toggle="buttons">
                {this.props.options.map((option) => (
                    <label key={option.id} className={`btn btn-radio ${this.props.selectedOption === option.value && "checked"}`}>
                      <input type="radio" onClick={() => this.setSelection(option.value)}/>
                      {option.label}
                    </label>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>);
  }
}

export default withTranslation()(RadioButtonPanel);
