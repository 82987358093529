import React, {Component} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import BasePopup from "../popup/BasePopup";
import DatepickerxWrapper from "../pluginwrapper/DatepickerxWrapper";
import PreviewPopup from '../PreviewPopup';
import {API_ADMIN_DELETE_POPUP, API_ADMIN_POPUP_DETAIL, URL_ADD_POPUP_API, URL_ADMIN_POPUPMANAGEMENT, URL_MODIFY_POPUP_API} from '../../utils/urlConstants';
import {handleHttpError} from '../../utils/ErrorUtils';
import {startLoading, stopLoading} from '../../ducks/loading';
import axios from 'axios';
import {AXIOS_CLIENT_TIMEOUT, SUCCESS} from '../../utils/globalConstants';
import {toast} from 'react-toastify';

class PopupAddModifyPanel extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isTooltipOpen: false,
      hover: false,
      focus: false,
      alertModalOpen: false,
      title: "",
      // url: "https://sbox-dpi.samsungcheckout.com/work/contents",
      visibilityYN: "Y",
      alertMessage: "",
      alertContext: 0, // POPUP,CANCEL, SAVE
      isDataLoaded: false,
      popupSize: {width: 350, height: 350},
      linkURL: "",
      imageFile: null,
      imageFilePath: null,
      newImageFile: null,
      replacementText: "",
      previewPopupOpen: false
    };
    this.Context = {
      DELETE: 1,
      CANCEL: 2,
      SAVE: 3
    };
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});
    this.fileRef = React.createRef();
  }

  handleAlertPopupClose = (isSave, context) => {
    this.setState({alertModalOpen: false, previewPopupOpen: false});
    if (!isSave) {
      return;
    }
    switch (context) {
      case this.Context.SAVE:
        this.savePopup();
        break;
      case this.Context.DELETE:
        this.deletePopup();
        break;
      case this.Context.CANCEL:
        this.cancelPopup();
        break;
      default :
        console.log("not possible");
    }
  };

  getPopupDetail = (popupId) => {
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .get(API_ADMIN_POPUP_DETAIL, {
            params: {
              popUpId: popupId
            }
          })
          .then(response => {
            let data = response.data;
            this.setState({
              title: data.popUpName,
              startDate: data.validStartDate,
              endDate: data.validEndDate,
              visibilityYN: data.inUse,
              popupSize: {
                width: data.popUpMaxSize === "SQUARE_350_350" ? 350 : 500,
                height: data.popUpMaxSize === "SQUARE_350_350" ? 350 : 800,
              },
              linkURL: data.linkURL || "",
              imageFileName: data.fileName,
              imageFilePath: data.s3FilePath,
              replacementText: data.alternateMessage || "",
              isDataLoaded: true
            });
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            reject(error);
            this.props.stopLoading();
          })
    });
  };

  handleFileChange = event => {
    const allowedExtensions = ['img', 'jpeg', 'png'];
    if (!event.target || event.target.files.length === 0) {
      return;
    }
    const {name: fileName} = event.target.files[0];
    const fileExtension = fileName?.split(".").pop().toLowerCase();
    if (!allowedExtensions.includes(fileExtension)) {
      toast(this.props.t("FILE_NOT_ALLOWED"));
    } else {
      this.setState({
        newImageFile: event.target.files[0],
        newImagePath: URL.createObjectURL(event.target.files[0])
      });
    }
  };

  handleURLChange = event => {
    this.setState({
      linkURL: event.target.value
    });
  };

  handleVisibilitySelect = (value) => {
    this.setState({
      visibilityYN: value
    });
  };

  handleDeleteClick = () => {
    this.setState({
      alertModalOpen: true,
      alertMessage: "DO_YOU_WANT_TO_DELETE",
      alertContext: this.Context.DELETE
    })
  };

  handleCancelClick = () => {
    this.setState({
      alertModalOpen: true,
      alertMessage: "CONFIRM_EDIT_CANCEL_MESSAGE",
      alertContext: this.Context.CANCEL
    });
  };

  handleSaveClick = () => {
    //TODO validate frontend
    let titleFlag = false;
    if (this.state.title) {
      if (Object.is(this.state.title.trim(), "")) {
        titleFlag = true;
      }
    } else {
      titleFlag = true;
    }
    if (titleFlag) {
      toast(this.props.t("POPUP_NAME_NOT_VALID"));
      return;
    }

    let dateFlag = false;
    if (new Date(this.state.startDate.getValue()) > new Date(this.state.endDate.getValue())) {
      dateFlag = true;
    }
    let endDate = new Date(this.state.endDate.getValue());
    if (new Date().valueOf() > endDate.setDate(endDate.getDate() + 1)) {
      dateFlag = true;
    }
    if (dateFlag) {
      toast(this.props.t("POPUP_DATE_NOT_VALID"));
      return;
    }

    let linkURLFlag = false;
    if (this.state.linkURL) {
      const regex = /^(https?:\/\/)/gi;
      if (!regex.test(this.state.linkURL)) {
        linkURLFlag = true;
      }
    }
    if (linkURLFlag) {
      toast(this.props.t("POPUP_LINK_URL_NOT_VALID"));
      return;
    }

    this.setState({
      alertModalOpen: true,
      alertMessage: this.props.isModify ? "DO_YOU_WANT_TO_SAVE_THE_CHANGES" : "DO_YOU_WANT_TO_REGISTER_THE_INFORMATION",
      alertContext: this.Context.SAVE
    })
  };

  handleDatepickerInit = (isStart, datepicker) => {
    isStart ? this.setState({startDate: datepicker}) : this.setState({endDate: datepicker});
  };

  componentDidMount() {
    if (this.props.isModify) {
      this.getPopupDetail(this.props.match.params.popupId);
    } else {
      this.setState({isDataLoaded: true})
    }
  }

  onPreviewClick = () => {
    this.setState({previewPopupOpen: true});
  };

  handlePreviewClose = () => {
    this.setState({previewPopupOpen: false});
  };

  savePopup = () => {
    let params = new FormData();
    params.append("popUpName", this.state.title.trim());
    params.append("validStartDate", this.state.startDate.getValue());
    params.append("validEndDate", this.state.endDate.getValue());
    params.append("inUse", this.state.visibilityYN);
    params.append("popUpMaxSize", this.state.popupSize.width === 350 ? "SQUARE_350_350" : "RECTANGLE_500_800");
    params.append("linkURL", this.state.linkURL ? this.state.linkURL.trim() : "");
    params.append("alternateMessage", this.state.replacementText ? this.state.replacementText.trim() : "");
    if (this.state.newImageFile) {
      params.append("imageFile", this.state.newImageFile);
    }

    return new Promise((resolve, reject) => {
      if (this.props.isModify) {
        params.append("popUpId", Number(this.props.match.params.popupId));
        this.props.startLoading();
        return new Promise((resolve, reject) => {
          this.httpClient
              .post(URL_MODIFY_POPUP_API, params)
              .then(response => {
                if (response.data !== SUCCESS) {
                  toast(this.props.t("POPUP_MODIFY_ALERT_FAIL"));
                } else {
                  toast(this.props.t("POPUP_MODIFY_ALERT_SUCCESS"));
                  this.props.history.push(URL_ADMIN_POPUPMANAGEMENT);
                }
                this.props.stopLoading();
              })
              .catch(error => {
                handleHttpError(this.props.t, this.props.history, error);
                this.props.stopLoading();
              });
        })
      } else {
        this.props.startLoading();
        return new Promise((resolve, reject) => {
          this.httpClient
              .post(URL_ADD_POPUP_API, params)
              .then(response => {
                if (response.data !== SUCCESS) {
                  toast(this.props.t("POPUP_CREATE_ALERT_FAIL"));
                } else {
                  toast(this.props.t("POPUP_CREATE_ALERT_SUCCESS"));
                  this.props.history.push(URL_ADMIN_POPUPMANAGEMENT);
                }
                this.props.stopLoading();
              })
              .catch(error => {
                handleHttpError(this.props.t, this.props.history, error);
                this.props.stopLoading();
              });
        })
      }
    })
  };

  deletePopup = () => {
    this.props.startLoading();
    this.httpClient
        .post(API_ADMIN_DELETE_POPUP, [this.props.match.params.popupId])
        .then(response => {
          if (response.data !== SUCCESS) {
            toast(this.props.t("POPUP_DELETE_ALERT_FAIL"));
          } else {
            toast(this.props.t("POPUP_DELETE_ALERT_SUCCESS"));
            this.props.history.push(URL_ADMIN_POPUPMANAGEMENT);
          }
          this.props.stopLoading();
        })
        .catch(error => {
          handleHttpError(this.props.t, this.props.history, error);
          this.props.stopLoading();
        })
  };

  cancelPopup = () => {
    this.props.history.push(URL_ADMIN_POPUPMANAGEMENT);
  };

  render() {
    if (!this.state.isDataLoaded) {
      return <div/>
    }
    return (
        <div className="tab-pane active" role="tabpanel">
          <p className="text-right text-success small"><span className="align-middle">*</span>{this.props.t("INDICATES_REQUIRED_FIELD")}</p>
          <div className="form-group-wrap">
            <div className="form-group row">
              <label className="col col-form-label">{this.props.t("POPUP_NAME_LABEL")}<span className="text-success align-middle">*</span></label>
              <div className="col">
                <div className="input-group textarea_count">
                  <input type="text" maxLength="100" value={this.state.title}
                         onMouseOver={() => this.setState({hover: true})}
                         onMouseOut={() => this.setState({hover: false, focus: false})}
                         onBlur={() => this.setState({hover: false, focus: false})}
                         onFocus={() => this.setState({focus: true})}
                         onKeyUp={(event) => this.setState({title: event.target.value})}
                         onChange={(event) => this.setState({title: event.target.value})}
                         className="form-control text_counter rounded"
                         placeholder={this.props.t("PLEASE_ENTER_POPUP_NAME")} required/>
                  <div className="input-group-append counts text-center">
                    <span className={`text_count ${this.state.hover && "hover"} ${this.state.focus && "active"}`}>{this.state.title.length}</span> / 100{this.props.t("BYTE")}
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col col-form-label">Display Period<span className="text-success align-middle">*</span></label>
              <div className="col">
                <div className="row">
                  <div className="col-8">
                    <div className="input-group input-group-datepicker">
                      <div className="input-group-append flex-100">
                        <DatepickerxWrapper
                            className="form-control rounded"
                            isDateStart={false}
                            initDate={this.state.startDate}
                            onInit={(datepicker) => this.handleDatepickerInit(true, datepicker)}/>
                      </div>
                      <div className="input-group-append">
                        <span className="input-group-text bg-white border-0">~</span>
                      </div>
                      <div className="input-group-append flex-100">
                        <DatepickerxWrapper
                            className="form-control rounded"
                            isDateStart={false}
                            initDate={this.state.endDate}
                            onInit={(datepicker) => this.handleDatepickerInit(false, datepicker)}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col col-form-label">{this.props.t("VISIBILITY")}<span className="text-success align-middle">*</span></label>
              <div className="col">
                <div className="btn-group btn-group-toggle btn-group-radio" data-toggle="buttons">
                  <label className={`btn btn-radio ${Object.is(this.state.visibilityYN, 'Y') && "checked"}`}><input type="radio" onClick={() => this.handleVisibilitySelect('Y')}/>{this.props.t("SHOW")}</label>
                  <label className={`btn btn-radio ${!Object.is(this.state.visibilityYN, 'Y') && "checked"}`}><input type="radio" onClick={() => this.handleVisibilitySelect('N')}/>{this.props.t("HIDE")}</label>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col col-form-label">{this.props.t("POPUP_SIZE_LABEL")}<span className="text-success align-middle">*</span></label>
              <div className="col">
                <div className="btn-group btn-group-toggle btn-group-radio" data-toggle="buttons">
                  <label className={`btn btn-radio ${Object.is(this.state.popupSize.width, 350) && "checked"}`}><input type="radio" onClick={() => this.handlePopupSizeSelect(1)}/>Square (350*350)</label>
                  <label className={`btn btn-radio ${Object.is(this.state.popupSize.width, 500) && "checked"}`}><input type="radio" onClick={() => this.handlePopupSizeSelect(2)}/>Rectangle (500*800)</label>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col col-form-label">{this.props.t("LINK_URL")}</label>
              <div className="col">
                <input type="url" className="form-control" value={this.state.linkURL} onChange={(event) => this.handleURLChange(event)} placeholder="Please enter Link URL."/>
              </div>
            </div>
            <div className="form-group row">
              <label className="col col-form-label d-flex align-items-center justify-content-center">{this.props.t("POPUP_CONTENT_LABEL")}<span className="text-success align-middle">*</span></label>
              <div className="col">
                <div className="form-group row">
                  <label className="col col-form-label text-left">{this.props.t("IMAGE")}<span className="text-success align-middle">*</span></label>
                  <div className="col">
                    <div className="input-group">
                      <input
                          type="text"
                          className="form-control bg-white text-100"
                          placeholder={this.props.t("PLEASE_UPLOAD_IMAGE")}
                          readOnly="readonly"
                          value={this.state.newImageFile === null ? (this.state.imageFileName ? this.state.imageFileName : "") : this.state.newImageFile.name}
                          onClick={() => this.fileRef.current.click()}/>
                      <input type="file" accept={".jpg,.png"} style={{display: "none"}} onChange={this.handleFileChange} ref={this.fileRef}/>
                      <div className="input-group-append upload-btn-wrapper">
                        <button
                            className="btn btn-primary"
                            type="button"
                            disabled={this.state.newImageFile === null}
                            onClick={() => this.handleUploadButtonClick()}>{this.props.t("UPLOAD")}</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col col-form-label text-left">{this.props.t("REPLACEMENT_TEXT_LABEL")}</label>
                  <div className="col">
                    <input
                        type="text"
                        className="form-control"
                        maxLength="1200"
                        placeholder="Please enter replacement text."
                        value={this.state.replacementText}
                        onChange={(event) => {
                          this.setState({replacementText: event.target.value})
                        }}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-30">
            <div className="col-6">
              <button type="button" className="btn btn-sm btn-auto btn-success" onClick={() => this.onPreviewClick()}>{this.props.t("PREVIEW")}</button>
              {this.props.isModify &&
              <button type="button" className="btn btn-sm btn-auto btn-outline-primary ml-10" onClick={() => this.handleDeleteClick()}>{this.props.t("DELETE")}</button>}
            </div>
            <div className="col-6 text-right">
              <button type="button" className="btn btn-sm btn-auto btn-outline-primary" onClick={() => this.handleCancelClick()}>{this.props.t("CANCEL")}</button>
              <button disabled={!this.props.isModify && this.state.imageFilePath === null} type="button" className="btn btn-sm btn-auto btn-primary ml-10" onClick={() => this.handleSaveClick()}>{this.props.isModify ? this.props.t("SAVE") : this.props.t("REGISTER")}</button>
            </div>
          </div>
          <BasePopup
              open={this.state.alertModalOpen}
              className="modal-sm"
              title={this.props.t("ALERT")}
              onClose={() => this.handleAlertPopupClose(false)}
              body={
                <p>{this.props.t(this.state.alertMessage)}</p>
              }
              footer={
                <>
                  <button type="button" className="btn btn-secondary" onClick={() => this.handleAlertPopupClose(true, this.state.alertContext)}>
                    {this.props.t("CONFIRM")}
                  </button>
                  <button type="button" className="btn btn-outline-secondary" onClick={() => this.handleAlertPopupClose(false)}>
                    {this.props.t("CANCEL")}
                  </button>
                </>
              }/>
          {this.state.previewPopupOpen &&
          <PreviewPopup title={this.state.title}
                        imgSrc={this.state.imageFilePath}
                        altText={this.state.replacementText}
                        handleClose={this.handlePreviewClose}
                        size={this.state.popupSize}
                        onError={() => {
                          console.log("cannot open popup")
                        }}/>}
        </div>
    )
  }

  handleUploadButtonClick = () => {
    this.setState({imageFilePath: this.state.newImagePath});
    toast(this.props.t("FILE_UPLOADED_SUCCESSFULLY"))
  };

  handlePopupSizeSelect = (option) => {
    if (option === 1) {
      this.setState({popupSize: {width: 350, height: 350}})
    } else {
      this.setState({popupSize: {width: 500, height: 800}})
    }
  }
}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel
  };
};

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(PopupAddModifyPanel)));