import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";

import DataTableWrapperForListing from "../datable/DataTableWrapperForListing";
import {API_ADMIN_DELETE_FAQ, API_ADMIN_FAQ_LIST, URL_ADMIN_FAQMANAGEMENT, URL_ADMIN_FAQREGISTRATION} from "../../utils/urlConstants";
import axios from "axios";
import {AXIOS_CLIENT_TIMEOUT, REGISTERED_DATE_SORTNAME, SUCCESS} from "../../utils/globalConstants";
import SearchBar from "../searchbar/SearchBar";
import {isDateFutureThan, durationKey, getDefaultTimePeriod} from "../../utils/searchBarUtils";
import {FAQAttributes, faqKeywordOptionKey2Value, faqVisibilityCode2Key, faqVisibilityKey2Code} from "../../utils/adminAttributes";
import BasePopup from "../popup/BasePopup";
import {handleHttpError} from "../../utils/ErrorUtils";
import {withTranslation} from "react-i18next";
import {startLoading, stopLoading} from "../../ducks/loading";
import {toast} from "react-toastify";

class FAQListPanel extends Component {
  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});

    this.state = {
      pageNumber: 1,
      totalListSize: 0,
      listSizePerPage: 15,
      FAQList: [],
      selectedFAQList: [],
      searchParams: {},
      modal: false,
    }
  }

  handleSearchClick = (searchParams) => {
    if (isDateFutureThan(searchParams.startDate, searchParams.endDate)) {
      toast(this.props.t("CHECK_DATES"))
      return;
    }
    const params = {
      dateStart: searchParams.startDate,
      dateEnd: searchParams.endDate,
      useYN: searchParams.selectedFilter[0].value ? faqVisibilityKey2Code[searchParams.selectedFilter[0].value] : "",
      keywordType: searchParams ? faqKeywordOptionKey2Value[searchParams.keywordOption] : "",
      searchText: searchParams.keywordText,
      sortName: REGISTERED_DATE_SORTNAME,
      sortOrder: "DESC",
      pageNumber: this.state.pageNumber,
      limit: this.state.listSizePerPage
    };
    this.setState({searchParams: params, pageNumber: 1}, () => this.triggerFAQSearch())
  };

  triggerFAQSearch = () => {
    const {searchParams} = this.state;
    if (this.state.pageNumber && this.state.listSizePerPage) {
      searchParams.limit = this.state.listSizePerPage;
      searchParams.pageNumber = this.state.pageNumber;
      return new Promise((resolve, reject) => {
        this.props.startLoading();
        this.httpClient
            .get(API_ADMIN_FAQ_LIST, {
              params: searchParams
            })
            .then(response => {
              this.setState({
                totalListSize: response.data.records,
                FAQList: response.data.data
              });
              this.props.stopLoading();
            })
            .catch(error => {
              handleHttpError(this.props.t, this.props.history, error);
              reject(error);
              this.props.stopLoading();
            })
      });
    }
    return Promise.resolve(this.props.t("CONDITION_NOT_MET"));

  };

  handleDeleteFaq = () => {
    this.setState({modal: false});
    if (!this.state.selectedFAQList.length) {
      return;
    }
    this.props.startLoading();
    this.httpClient
        .post(API_ADMIN_DELETE_FAQ, this.state.selectedFAQList)
        .then(response => {
          if (response.data !== SUCCESS) {
            toast(this.props.t("FAQ_DELETE_ALERT_FAIL"));
          } else {
            toast(this.props.t("FAQ_DELETE_ALERT_SUCCESS"));
            this.fetchFAQList(this.state.pageNumber, this.state.listSizePerPage);
          }
          this.props.stopLoading();
        })
        .catch(error => {
          handleHttpError(this.props.t, this.props.history, error);
          this.props.stopLoading();
        })
  };

  handleRowSelect = (isSelect, rows) => {

    rows.forEach(row => {
      if (isSelect) {
        this.setState(prevState => {
          if (!prevState.selectedFAQList.find(faqId => row["faqId"] === faqId)) {
            return {
              selectedFAQList: [...prevState.selectedFAQList, row["faqId"]]
            };
          }
          return {}
        });
      } else {
        this.setState(prevState => {
          return {
            selectedFAQList: prevState.selectedFAQList.filter(faqId => row["faqId"] !== faqId)
          };
        })
      }
    });
  };

  handleTableChange = (page, sizePerPage) => {
    this.setState({
      pageNumber: page,
      listSizePerPage: sizePerPage,
    }, () => this.triggerFAQSearch());
  };

  handleTableSort = (sortOrder, sortField) => {
    const tableHeaderField2SortName = {
      "faqIdLink": "faqTitle",
      "author": "author",
      "visibility": "inUse",
      "registrationDate": "registeredDate"
    };

    this.setState(prevState => {
      return {
        searchParams: {
          ...prevState.searchParams,
          sortOrder: sortOrder === 'desc' ? "DESC" : "ASC",
          sortName: tableHeaderField2SortName[sortField] ? tableHeaderField2SortName[sortField] : sortField,
          pageNumber: 1,
          limit: prevState.listSizePerPage
        }
      }
    }, () => this.triggerFAQSearch())
  };


  render() {
    return (
        <div className="tab-pane active" role="tabpanel">
          <SearchBar
              keywordPanel={{
                show: true,
                label: "Keyword",
                placeholder: this.props.t("PLEASE_ENTER_KEYWORDS"),
                options: [FAQAttributes.title.key, FAQAttributes.author.key],
                showDownload: false,
              }}
              datePanel={{
                show: true,
                label: this.props.t("REGISTRATION_DATE"),
                defaultDuration: durationKey.DURATION_YEAR,
                maxDate: true
              }}
              filterPanel={{
                show: true,
                label: this.props.t("FILTERS"),
                filters: [
                  {key: FAQAttributes.visibility.key, value: FAQAttributes.visibility.value},
                ]
              }}
              radioButtonPanel={{show: false}}
              onSearchClick={this.handleSearchClick}/>
          <DataTableWrapperForListing
              noSelect
              keyField="faqId" // one of 'dataField'
              hideSelectColumn={false}
              columns={[ // 'dataField' should be same as api response fields below "data" use it as 'object key'
                {dataField: "faqId", hidden: true},
                {dataField: "faqIdLink", text: this.props.t(FAQAttributes.title.key), headerStyle: {width: "59.2270%"}, sort: true, classes: () => "text-left text-truncate", titleHeader: "faqTitle"},
                {dataField: "author", text: this.props.t(FAQAttributes.author.key), headerStyle: {width: "11.1597%"}, sort: false, classes: () => "text-truncate"},
                {dataField: "visibility", text: this.props.t(FAQAttributes.visibility.key), headerStyle: {width: "9.8416%"}, sort: true},
                {dataField: "registrationDate", text: this.props.t(FAQAttributes.registrationDate.key), headerStyle: {width: "15.8172%"}, sort: true, classes: () => "text-truncate"},
              ]}
              page={this.state.pageNumber}
              sizePerPage={this.state.listSizePerPage}
              totalSize={this.state.totalListSize}
              rows={this.state.FAQList.map(faq => {
                return {
                  ...faq,
                  faqIdLink:
                      <Link to={{}}
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.history.push(URL_ADMIN_FAQMANAGEMENT + "/" + faq.faqId);
                            }}>
                        {faq.faqTitle}
                      </Link>,
                  visibility: this.props.t(faqVisibilityCode2Key[faq.inUse]),
                  registrationDate: faq.registeredDate,
                };
              })}
              onTableSort={this.handleTableSort}
              onTableChange={this.handleTableChange}
              onRowSelect={this.handleRowSelect}/>
          <div className="pagenationGroup clearfix max">
            {/*<div className="float-left">*/}
            <button
                type="button" className="btn btn-sm btn-outline-primary float-left"
                onClick={() => this.setState({modal: true})}>
              {this.props.t("DELETE")}
            </button>
            <Link to={URL_ADMIN_FAQREGISTRATION}>
              <button type="button" className="btn btn-sm btn-primary float-right">
                {this.props.t("REGISTER")}
              </button>
            </Link>
          </div>
          <BasePopup
              open={this.state.modal}
              className="modal-sm"
              title={this.props.t("ALERT")}
              onClose={() => this.setState({modal: false})}
              body={
                <p>{this.state.selectedFAQList.length ? this.props.t("ARE_YOU_SURE_YOU_WANT_TO_DELETE") : this.props.t("PLEASE_SELECT_AT_LEAST_ONE_ITEM")}</p>
              }
              footer={
                <>
                  <button type="button" className="btn btn-secondary" onClick={() => this.handleDeleteFaq()}>
                    {this.props.t("CONFIRM")}
                  </button>
                  {
                    this.state.selectedFAQList.length > 0 &&
                    <button type="button" className="btn btn-outline-secondary" onClick={() => this.setState({modal: false})}>
                      {this.props.t("CANCEL")}
                    </button>
                  }

                </>
              }/>
        </div>
    );
  }

  fetchFAQList = (page, sizePerPage) => {
    const searchPeriod = getDefaultTimePeriod();
    const searchParams = {
      dateStart: searchPeriod.startDate,
      dateEnd: searchPeriod.endDate,
    };
    this.setState({searchParams: searchParams}, () => {
      let params = this.state.searchParams;
      params = {
        ...params,
        sortOrder: this.state.sortOrder,
        sortName: this.state.sortName,
        pageNumber: page,
        limit: sizePerPage
      };
      return new Promise((resolve, reject) => {
        this.props.startLoading();
        this.httpClient
            .get(API_ADMIN_FAQ_LIST, {
              params
            })
            .then(response => {
              this.setState({
                pageNumber: page,
                listSizePerPage: sizePerPage,
                totalListSize: response.data.records,
                FAQList: response.data.data
              });
              this.props.stopLoading();
            })
            .catch(error => {
              handleHttpError(this.props.t, this.props.history, error);
              this.props.stopLoading();
              reject(error);
            })
      });
    });
  };

  componentDidMount() {
    this.fetchFAQList(1, 15);
  }
}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel
  };
};

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(FAQListPanel)));