import React, {Component} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {toast} from "react-toastify";
import BasePopup from "../popup/BasePopup";
import {Editor} from "@tinymce/tinymce-react";
import axios from "axios";
import {AXIOS_CLIENT_TIMEOUT, SUCCESS} from "../../utils/globalConstants";
import {API_ADMIN_DELETE_FAQ, API_ADMIN_FAQ_DETAIL, URL_ADD_FAQ_API, URL_ADMIN_FAQMANAGEMENT, URL_MODIFY_FAQ_API} from "../../utils/urlConstants";
import {handleHttpError} from "../../utils/ErrorUtils";
import {startLoading, stopLoading} from "../../ducks/loading";

class FAQAddModifyPanel extends Component {

  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});
    this.state = {
      isTooltipOpen: false,
      hover: false,
      focus: false,
      alertModalOpen: false,
      category: "NOTICE",
      title: "",
      initialContent: "",
      content: "",
      fileList: [],
      // url: "https://sbox-dpi.samsungcheckout.com/work/contents",
      visibilityYN: "Y",
      csvFile: null,
      faqId: -1,
      alertMessage: "",
      alertContext: 0, // FILE,NOTICE,CANCEL, SAVE
      selectedFile: "",
      isDataLoaded: false
    };
    this.Context = {
      FAQ: 1,
      CANCEL: 2,
      SAVE: 3
    };
  }

  handleAlertPopupClose = (isSave, context) => {
    this.setState({alertModalOpen: false});
    if (!isSave) {
      return Promise.resolve(this.props.t("CONDITION_NOT_MET"));
    }
    if (Object.is(context, this.Context.CANCEL)) {
      this.props.history.push(URL_ADMIN_FAQMANAGEMENT);
      return Promise.resolve(this.props.t("CONDITION_NOT_MET"));
    }
    let ajaxUrl = null;
    let params;
    let toastSuccessMessage, toastFailMessage;
    if (context === this.Context.FAQ) { //delete case
      ajaxUrl = API_ADMIN_DELETE_FAQ;
      params = [this.state.faqId];
      toastSuccessMessage = "FAQ_DELETE_ALERT_SUCCESS";
      toastFailMessage = "FAQ_DELETE_ALERT_FAIL";
    } else {
      ajaxUrl = this.props.isModify ? URL_MODIFY_FAQ_API : URL_ADD_FAQ_API;
      params = {
        faqId: this.state.faqId,
        faqTitle: this.state.title,
        faqContent: this.state.content,
        inUse: this.state.visibilityYN
      };
      toastSuccessMessage = this.props.isModify ? this.props.t("FAQ_MODIFY_ALERT_SUCCESS") : this.props.t("FAQ_ADD_ALERT_SUCCESS");
      toastFailMessage = this.props.isModify ? this.props.t("FAQ_MODIFY_ALERT_FAIL") : this.props.t("FAQ_ADD_ALERT_FAIL");
    }
    if (this.state.content.length > 5000) {
      toast(this.props.t("PLEASE_ENTER_LESS_CHARACTER"));
      return Promise.resolve(this.props.t("CONDITION_NOT_MET"));
    }
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .post(ajaxUrl, params)
          .then(response => {
            if (response.data !== SUCCESS) {
              toast(this.props.t(toastFailMessage));
            } else {
              toast(this.props.t(toastSuccessMessage));
              this.props.history.push(URL_ADMIN_FAQMANAGEMENT);
            }
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
          })
    })
  };

  getFAQDetail = (faqId) => {
    return new Promise((resolve, reject) =>  {
      this.props.startLoading();
      this.httpClient
          .get(API_ADMIN_FAQ_DETAIL, {
            params: {
              faqId: faqId
            }
          })
          .then(response => {
            this.setState({
              faqId: response.data.faqId,
              title: response.data.faqTitle,
              initialContent: response.data.faqContent,
              content: response.data.faqContent,
              visibilityYN: response.data.inUse,
              isDataLoaded: true
            });
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            reject(error);
            this.props.stopLoading();
          })
    });
  };

  handleEditorChange = (content, editor) => {
    this.setState({
      content: content,
    });
  };

  handleVisibilitySelect = (value) => {
    this.setState({
      visibilityYN: value
    });
  };

  handleDeleteFAQClick = () => {
    this.setState({
      alertModalOpen: true,
      alertMessage: "DO_YOU_WANT_TO_DELETE",
      alertContext: this.Context.FAQ
    })
  };

  handleCancelClick = () => {
    this.setState({
      alertModalOpen: true,
      alertMessage: "CONFIRM_EDIT_CANCEL_MESSAGE",
      alertContext: this.Context.CANCEL
    });
    if (this.props.isModify) {
      this.getFAQDetail(this.state.faqId);
    }
  };

  handleSaveClick = () => {
    if (!this.state.title || !this.state.title.trim()) {
      toast(this.props.t("FAQ_ALERT_INVALID_TITLE"));
      return;
    }
    let convertedTextFromHTML = this.state.content
        .replace(/\n/g, "")
        .replace(new RegExp('<[^>]{0,10000}>', 'g'), '')
        .replace(/&nbsp;/g, '');
    if (!this.state.content || !convertedTextFromHTML.trim()) {
      toast(this.props.t("FAQ_ALERT_INVALID_CONTENT"));
      return;
    }
    this.setState({
      alertModalOpen: true,
      alertMessage: this.props.isModify ? "DO_YOU_WANT_TO_SAVE_THE_CHANGES" : "DO_YOU_WANT_TO_REGISTER_THE_INFORMATION",
      alertContext: this.Context.SAVE
    })
  };

  componentDidMount() {
    if (this.props.isModify) {
      this.getFAQDetail(this.props.match.params.faqId);
    } else {
      this.setState({isDataLoaded: true})
    }
  }

  render() {
    if (!this.state.isDataLoaded) {
      return <div></div>
    }

    return (
        <div className="tab-pane active" role="tabpanel">
          <p className="text-right text-success small"><span className="align-middle">*</span>{this.props.t("INDICATES_REQUIRED_FIELD")}</p>
          <div className="form-group-wrap">
            <div className="form-group row">
              <label className="col col-form-label">{this.props.t("TITLE")}<span className="text-success align-middle">*</span></label>
              <div className="col">
                <div className="input-group textarea_count">
                  <input type="text"
                         onBlur={() => this.setState({hover: false, focus: false})}
                         onFocus={() => this.setState({focus: true})}
                         onChange={(event) => this.setState({title: event.target.value})}
                         maxLength="100" value={this.state.title}
                         onMouseOver={() => this.setState({hover: true})}
                         onMouseOut={() => this.setState({hover: false, focus: false})}
                         className="form-control text_counter rounded"
                         placeholder={this.props.t("PLEASE_ENTER_TITLE")} required/>
                  <div className="input-group-append counts text-center">
                    <span className={`text_count ${this.state.hover && "hover"} ${this.state.focus && "active"}`}>{this.state.title.length}</span> / 100{this.props.t("BYTE")}
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col col-form-label">{this.props.t("CONTENT")}<span className="text-success align-middle">*</span></label>
              <div className="col">
                <Editor apiKey='75hhu7t6jplfm6zeso9vvvkdggn92rv9ov6gkgxvl5n5lmun'
                        initialValue={this.state.initialContent}
                        value={this.state.content}
                        init={{
                          height: 400,
                          menubar: true,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar:
                              'undo redo | formatselect | bold italic backcolor | \
                              alignleft aligncenter alignright alignjustify | \
                              bullist numlist outdent indent | removeformat | help'
                        }}
                        onEditorChange={this.handleEditorChange}
                />
              </div>
            </div>
          </div>
          <div className="form-group-wrap">
            <div className="form-group row">
              <label className="col col-form-label">{this.props.t("VISIBILITY")}<span className="text-success align-middle">*</span></label>
              <div className="col">
                <div className="btn-group btn-group-toggle btn-group-radio">
                  <label className={`btn btn-radio ${Object.is(this.state.visibilityYN, 'Y') && "checked"}`}><input type="radio" onClick={() => this.handleVisibilitySelect('Y')}/>{this.props.t("SHOW")}</label>
                  <label className={`btn btn-radio ${!Object.is(this.state.visibilityYN, 'Y') && "checked"}`}><input type="radio" onClick={() => this.handleVisibilitySelect('N')}/>{this.props.t("HIDE")}</label>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-30">
            <div className="col-6">
              {
                this.props.isModify &&
                <button type="button" className="btn btn-sm btn-auto btn-outline-primary" onClick={() => this.handleDeleteFAQClick()}>{this.props.t("DELETE")}</button>
              }
            </div>
            <div className="col-6 text-right">
              <button type="button" className="btn btn-sm btn-auto btn-outline-primary" onClick={() => this.handleCancelClick()}>{this.props.t("CANCEL")}</button>
              <button type="button" className="btn btn-sm btn-auto btn-primary ml-10" onClick={() => this.handleSaveClick()}>{this.props.isModify ? this.props.t("SAVE") : this.props.t("REGISTER")}</button>
            </div>
          </div>
          <BasePopup
              open={this.state.alertModalOpen}
              className="modal-sm"
              title={this.props.t("ALERT")}
              onClose={() => this.handleAlertPopupClose(false)}
              body={
                <p>{this.props.t(this.state.alertMessage)}</p>
              }
              footer={
                <>
                  <button type="button" className="btn btn-secondary" onClick={() => this.handleAlertPopupClose(true, this.state.alertContext)}>
                    {this.props.t("CONFIRM")}
                  </button>
                  <button type="button" className="btn btn-outline-secondary" onClick={() => this.handleAlertPopupClose(false)}>
                    {this.props.t("CANCEL")}
                  </button>
                </>
              }/>
        </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel
  };
};

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(FAQAddModifyPanel)));