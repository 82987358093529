import React from 'react';
import {withTranslation} from 'react-i18next';

function formatDate(date) {
  let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  let day = date.getDate();
  let monthIndex = date.getMonth();
  let year = date.getFullYear();
  return monthNames[monthIndex] + ' ' + day + ' ' + year;
}

// I have rewrite this component as DatePickerX
// Use DatePickerX instead of DatepickerxWrapper

class DatepickerxWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  render() {
    let inputClassName = this.props.isDateStart ? "datepicker date-start" : "datepicker date-end";
    if (this.props.className) {
      inputClassName += " " + this.props.className;
    }

    return (
        <input ref={this.ref}
               type="text"
               readOnly
               className={inputClassName}
               placeholder={this.props.isDateStart ? this.props.t('START_DATE') : this.props.t('END_DATE')}/>
    );
  }

  componentDidMount() {
    if (!this.ref.current) {
      return;
    }

    // init datepicker
    const element = this.ref.current;
    element.DatePickerX.init({
      mondayFirst: false,
      todayButton: false,
      clearButton: false,
      weekDayLabels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      singleMonthLabels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      format: 'yyyy-mm-dd',

      maxDate: this.props.maxToday ? new Date() : new Date(9999, 12, 31)
      //TODO set limits
      // maxDate: this.props.isDateStart ? this.props.endDate.getValue() : new Date(),
      // minDate: this.props.isDateStart ? null : this.props.startDate.getValue()
    });

    // set init value

    const getDates = () =>{
      if(this.props.initDate) {
        return formatDate(new Date(this.props.initDate));
      }else {
        if(this.props.isDateStart) {
          let beforeDate =  new Date();
          if(this.props.duration === "YEAR") {
            beforeDate =  beforeDate.setFullYear(beforeDate.getFullYear() - 1);
          }else if(this.props.duration === "ONE_MONTH"){
            beforeDate =  beforeDate.setMonth(beforeDate.getMonth() - 1);
          }else if(this.props.duration === "SIX_MONTH") {
            beforeDate =  beforeDate.setMonth(beforeDate.getMonth() - 6);;
          }else {
            beforeDate =  beforeDate.setFullYear(beforeDate.getFullYear() - 10);
          }
          return formatDate(new Date(beforeDate));
        }else {
          return  formatDate(new Date());
        }
      }

    }

    element.DatePickerX.setValue(getDates());

    if (!this.props.onInit) {
      throw new Error("Function onInit(ref) must exist");
    }

    /*
     * Pass DatePickerx to parent (I know this architecture is not good but we have no option)
     *
     *   handleStartDatepicker = (datepicker) => {
     *     console.log("start datepicker", datepicker);
     *
     *     // Save the date picker like below. Either way its good.
     *     this.startDatepicker = datepicker;
     *     this.setState({startDatePicker: datepicker});
     *   };
     *
     * See https://github.com/AvroraTeam/DatePickerX#datepickerx-methods for apis for DatePickerX
     */
    this.props.onInit({
      setValue: (date) => this.ref.current.DatePickerX.setValue(formatDate(date)),
      set10yearBeforeValue: (date) => {
        const beforeDate = new Date(date);
        beforeDate.setFullYear(beforeDate.getFullYear() - 10);
        this.ref.current.DatePickerX.setValue(formatDate(beforeDate))
      },
      set1YearBeforeValue: (date) => {
        const beforeDate = new Date(date);
        beforeDate.setFullYear(beforeDate.getFullYear() - 1);
        this.ref.current.DatePickerX.setValue(formatDate(beforeDate))
      },
      set6monthBeforeValue: (date) => {
        const beforeDate = new Date(date);
        beforeDate.setMonth(beforeDate.getMonth() - 6);
        this.ref.current.DatePickerX.setValue(formatDate(beforeDate))
      },
      set1monthBeforeValue: (date) => {
        const beforeDate = new Date(date);
        beforeDate.setMonth(beforeDate.getMonth() - 1);
        this.ref.current.DatePickerX.setValue(formatDate(beforeDate))
      },
      getValue: () => this.ref.current.DatePickerX.getValue()
    });
  }

  componentWillUnmount() {
    // TODO Maybe Memory Leak, need a way do free datepickerx
    this.ref = null;
  }
}

export default withTranslation()(DatepickerxWrapper);