import React, {Component} from 'react';
import SearchBar from '../searchbar/SearchBar';
import {isProductDeletable, productAttributes, productStatusCode2Key, productStatusKey2Code, productTypeCode2Key, productTypeKey2Code, visibilityCode2Key, visibilityKey2Code} from '../../utils/productTypesAndAttributes';
import {withTranslation} from 'react-i18next';
import DataTableWrapperForListing from '../datable/DataTableWrapperForListing';
import BasePopup from '../popup/BasePopup';
import {Link, withRouter} from 'react-router-dom';
import {API_PRODUCT_LIST, URL_CHANGE_STATUS_API, URL_CHANGE_VISIBILITY_API, URL_PRODUCT_ADDMULTIPLE, URL_PRODUCT_ADDNEW, URL_PRODUCT_DETAIL, URL_PRODUCT_LIST_DOWNLOAD_API, URL_PRODUCT_MODIFYMULTIPLE, URL_PRODUCT_SUBSCRIPTIONGROUP_DETAIL} from '../../utils/urlConstants';
import axios from 'axios';
import {AXIOS_CLIENT_TIMEOUT, FREE_TRIAL_SUBSCRIPTION, SUBSCRIPTION} from '../../utils/globalConstants';
import {connect} from 'react-redux';
import {durationKey, isDateFutureThan} from '../../utils/searchBarUtils';
import {isSelectedAppChanged} from '../../ducks/carousel';
import {toast} from 'react-toastify';
import {handleHttpError} from '../../utils/ErrorUtils';
import {handleDownloadFile} from '../../utils/multipleAddModifyProductAttributes';
import {startLoading, stopLoading} from '../../ducks/loading';

class ProductListPanel extends Component {
  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});
    this.searchBarRef = React.createRef();
    this.radioButtonOptions = [
      {
        id: 1,
        value: "",
        label: this.props.t("ALL")
      },
      {
        id: 2,
        value: "Y",
        label: this.props.t("APPLIED")
      },
      {
        id: 3,
        value: "N",
        label: this.props.t("NOT_APPLIED")
      }
    ];
    this.state = {
      // for product list data table, will be init in fetchProductList
      productListPage: 0,
      totalProductListSize: 0,
      productListSizePerPage: 0,
      fetchedProductList: [],
      selectedProductIdList: [],
      searchParams: {},

      // for modal
      changeVisibilityPopupOpen: false,
      modalVisibilityValue: "",
      confirmDeletePopupOpen: false,
      confirmRetirePopupOpen: false,
      productId: null
    }
  }

  fetchDefaultProductList = (page, sizePerPage) => {
    const monthBefore10Year = new Date();
      monthBefore10Year.setFullYear(monthBefore10Year.getFullYear() - 10);
    var date = new Date();
    var endDateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
        .toISOString()
        .split("T")[0];
    var startDateString = new Date(monthBefore10Year.getTime() - (monthBefore10Year.getTimezoneOffset() * 60000))
        .toISOString()
        .split("T")[0];

    const params = {
      cpAppId: this.props.carousel.selectedAppId,
      dateStart: startDateString,
      dateEnd: endDateString,
      sortOrder: "DESC",
      pageNumber: page,
      limit: sizePerPage,
      flexbileOffer: ""
    };
    this.props.startLoading();
    this.httpClient
        .get(API_PRODUCT_LIST, {
          params: params
        })
        .then(response => {
          this.setState({
            productListPage: page,
            productListSizePerPage: sizePerPage,
            searchParams: params,
            fetchedProductList: response.data.data,
            totalProductListSize: response.data.records
          });
          this.props.stopLoading();
        })
        .catch(error => {
          handleHttpError(this.props.t, this.props.history, error);
          this.props.stopLoading();
        });
  };

  handleRowSelect = (isSelect, rows) => {
    rows.forEach(row => {
      if (isSelect) {
        this.setState(prevState => {
          if (!prevState.selectedProductIdList.find(productId => row["productId"] === productId)) {
            return {selectedProductIdList: [...prevState.selectedProductIdList, row["productId"]]};
          }
          return {}
        });
      } else {
        this.setState(prevState => {
          return {
            selectedProductIdList: prevState.selectedProductIdList.filter(productId => row["productId"] !== productId)
          };
        })
      }
    });
  };

  handleTableSort = (sortOrder, sortField) => {
    const productHeaderField2SortName = {
      "productIdLink": "cpProductId",
      "productNameLink": "productName",
      "productType": "productType",
      "visibility": "visibility",
      "productStatus": "productStatus",
      "modifiedDate": "modifiedDate",
      "subscriptionGroup" : "subscriptionGroupId",
      "deleteProductButton" : "productDeletable"
    };

    this.setState({
      sortOrder: sortOrder === 'desc' ? "DESC" : "ASC",
      sortName: productHeaderField2SortName[sortField] ? productHeaderField2SortName[sortField] : "",
      productListPage: 1,
    }, () => this.search())
  };

  handleTableChange = (page, sizePerPage) => {
    this.setState({
      productListPage: page,
      productListSizePerPage: sizePerPage,
    }, () => this.search());
  };

  handleDelete = (productIds) => {
    this.setState({confirmDeletePopupOpen: false});
    this.props.startLoading();
    this.httpClient
        .post(URL_CHANGE_STATUS_API, {
          cpAppId: this.props.carousel.selectedAppId,
          productIds: productIds,
          productStatusCode: "06"
        })
        .then(() => {
          productIds.forEach(productId => {
            this.setState(prevState => {
              return {
                fetchedProductList: prevState.fetchedProductList.filter(product => product.productId !== productId),
                selectedProductIdList: prevState.selectedProductIdList.filter(item => item !== productId)
              }
            });
          });
          this.setState({productId: null})
          toast(this.props.t("PRODUCT_DELETED"));
          this.props.stopLoading();
        })
        .catch(error => {
          handleHttpError(this.props.t, this.props.history, error);
          this.props.stopLoading();
        });
  };

  handleRetire = (productIds) => {
    this.setState({confirmRetirePopupOpen: false});
    this.props.startLoading();
    this.httpClient
        .post(URL_CHANGE_STATUS_API, {
          cpAppId: this.props.carousel.selectedAppId,
          productIds: productIds,
          productStatusCode: "05"
        })
        .then(() => {
          productIds.forEach(productId => {
            this.setState(prevState => {
              return {
                fetchedProductList: prevState.fetchedProductList.map((product) => {
                  if (product.productId === productId) {
                    product.productStatus = "05";
                  }
                  return product;
                }),
                selectedProductIdList: prevState.selectedProductIdList.filter(item => item !== productId)
              }
            });
          });
          toast(this.props.t("PRODUCT_RETIRED"));
          this.props.stopLoading();
        })
        .catch(error => {
          handleHttpError(this.props.t, this.props.history, error);
          this.props.stopLoading();
        });
  };

  handleModalToggle = (isOpen) => {
    this.setState({changeVisibilityPopupOpen: isOpen});
  };

  handleRetirePopup = (isOpen) => {
    this.setState({confirmRetirePopupOpen: isOpen})
  }
  handleChangeVisibility = () => {
    const {selectedProductIdList} = this.state;
    const {modalVisibilityValue} = this.state;
    this.props.startLoading();
    this.httpClient
        .post(URL_CHANGE_VISIBILITY_API, {
          cpAppId: this.props.carousel.selectedAppId,
          productIds: selectedProductIdList,
          visibilityCode: modalVisibilityValue
        })
        .then(() => {
          selectedProductIdList.forEach(productId => {
            this.setState(prevState => {
              return {
                fetchedProductList: prevState.fetchedProductList.map((product) => {
                  if (product.productId === productId) {
                    product.visibility = modalVisibilityValue;
                  }
                  return product;
                }),
               selectedProductIdList: prevState.selectedProductIdList.filter(item => productId !== item)
              }
            }
          );

          });

          this.props.stopLoading();
        })
        .catch(error => {
          handleHttpError(this.props.t, this.props.history, error);
          this.props.stopLoading();
        });

  };

  handleSearchClick = (searchParams) => {
    if (isDateFutureThan(searchParams.startDate, searchParams.endDate)) {
      toast(this.props.t("CHECK_DATES"))
      return;
    }
    const params = {
      dateStart: searchParams.startDate,
      dateEnd: searchParams.endDate,
      productVisibility: searchParams.selectedFilter[1].value ? visibilityKey2Code[searchParams.selectedFilter[1].value] : "",
      productStatus: searchParams.selectedFilter[2].value ? productStatusKey2Code[searchParams.selectedFilter[2].value] : "",
      productType: searchParams.selectedFilter[0].value ? productTypeKey2Code[searchParams.selectedFilter[0].value] : "",
      keywordType: searchParams.keywordOption,
      searchText: searchParams.keywordText,
      flexibleOffer: searchParams.selectedRadioButton,
      pageNumber: 1,
    };
    this.setState({
      searchParams: params,
      productListPage: 1
    }, () => this.search())
  };

  search = () => {
    const {searchParams} = this.state;
    searchParams.cpAppId = this.props.carousel.selectedAppId;
    searchParams.sortName = this.state.sortName;
    searchParams.sortOrder = this.state.sortOrder;
    searchParams.pageNumber = this.state.productListPage;
    searchParams.limit = this.state.productListSizePerPage;
    if (searchParams.pageNumber && searchParams.limit) {
      this.props.startLoading();
      this.httpClient
          .get(API_PRODUCT_LIST, {
            params: searchParams
          })
          .then(response => {
            this.setState({
              fetchedProductList: response.data.data,
              totalProductListSize: response.data.records
            });
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
          });
    }
  };

  handleDeletePopup = (isOpen) => {
    if (!isOpen && this.state.productId !== null) {
      this.setState({productId: null})
    }
    this.setState({confirmDeletePopupOpen: isOpen})
  }

  handleDownloadClick = (searchParams) => {
    const params = {
      cpAppId: this.props.carousel.selectedAppId,
      dateStart: searchParams.startDate,
      dateEnd: searchParams.endDate,
      productVisibility: searchParams.selectedFilter[1].value ? visibilityKey2Code[searchParams.selectedFilter[1].value] : "",
      productStatus: searchParams.selectedFilter[2].value ? productStatusKey2Code[searchParams.selectedFilter[2].value] : "",
      productType: searchParams.selectedFilter[0].value ? productTypeKey2Code[searchParams.selectedFilter[0].value] : "",
      keywordType: searchParams.keywordOption,
      searchText: searchParams.keywordText,
      sortName: this.state.sortName,
      sortOrder: "DESC",
      pageNumber: this.state.productListPage,
      rowsPerPage: this.state.productListSizePerPage,
      flexibleOffer: searchParams.selectedRadioButton
    };
    handleDownloadFile(URL_PRODUCT_LIST_DOWNLOAD_API, params, this.props.t, this.props.history, this.httpClient, this.props.startLoading, this.props.stopLoading);
  };

  render() {
    const nonSelected = this.state.selectedProductIdList.length === 0;
    const {selectedProductIdList} = this.state;
    const {fetchedProductList} = this.state;
    let isAllRetirable = true;
    selectedProductIdList.forEach(e => {
      isAllRetirable = isAllRetirable && !(fetchedProductList.filter(product => product.productId === e))[0].productDeletable;
    });
    let isAllDeletable = true;
    selectedProductIdList.forEach(e => {
      isAllDeletable = isAllDeletable && (fetchedProductList.filter(product => product.productId === e))[0].productDeletable;
    });
    return (
        <div className="tab-pane active" role="tabpanel">
          <SearchBar
              ref={this.searchBarRef}
              keywordPanel={{
                show: true,
                label: this.props.t("KEYWORD"),
                placeholder: this.props.t("PLEASE_ENTER_KEYWORDS"),
                options: [productAttributes.cpProductId.key, productAttributes.productName.key],
                showDownload: true,
              }}
              datePanel={{
                show: true,
                label: this.props.t("MODIFIED_DATE"),
                defaultDuration: durationKey.DURATION_ALL,
                maxDate: true
              }}
              filterPanel={{
                show: true,
                label: this.props.t("FILTERS"),
                filters: [
                  {key: productAttributes.productType.key, value: productAttributes.productType.value.filter(word => word !== "FREE_TRIAL_SUBSCRIPTION")},
                  {key: productAttributes.visibility.key, value: productAttributes.visibility.value},
                  {key: productAttributes.productStatus.key, value: productAttributes.productStatus.value}
                ]
              }}
              radioButtonPanel={{
                show: true,
                label: this.props.t("FLEXIBLE_OFFER"),
                selectedOption: "",
                options: this.radioButtonOptions,
              }}
              onSearchClick={this.handleSearchClick}
              onDownloadClick={this.handleDownloadClick}/>
          <DataTableWrapperForListing
              keyField="productId" // one of 'dataField'
              columns={[ // 'dataField' should be same as api response fields below "data" use it as 'object key'
                {dataField: "productId", hidden: true},
                {dataField: "productIdLink", text: this.props.t(productAttributes.productId.key), headerStyle: {width: "13.1810%"}, sort: true, titleHeader: "cpProductId"},
                {dataField: "productNameLink", text: this.props.t(productAttributes.productName.key), headerStyle: {width: "25.1160%"}, sort: true, classes: () => "text-left text-truncate", titleHeader: "productName"},
                {dataField: "productType", text: this.props.t(productAttributes.productType.key), headerStyle: {width: "11.1597%"}, sort: true},
                {dataField: "subscriptionGroup", text: this.props.t(productAttributes.subscriptionGroup.key), headerStyle: {width: "11%"}, sort: true, titleHeader: "subscriptionGroupId"},
                {dataField: "visibility", text: this.props.t(productAttributes.visibility.key), headerStyle: {width: "7.9086%"}, sort: true},
                {dataField: "productStatus", text: this.props.t(productAttributes.productStatus.key), headerStyle: {width: "9.2267%"}, sort: true},
                {dataField: "deleteProductButton", text: this.props.t("DELETE_PRODUCT"), headerStyle: {width: "9.2267%"}, sort: true, hideTitle: true},
                {dataField: "modifiedDate", text: this.props.t(productAttributes.modifiedDate.key), headerStyle: {width: "9.2267%"}, sort: true},
              ]}
              selectedProductIdList={this.state.selectedProductIdList}
              page={this.state.productListPage}
              sizePerPage={this.state.productListSizePerPage}
              totalSize={this.state.totalProductListSize}
              rows={this.state.fetchedProductList.map(product => {
                return {
                  ...product,
                  productIdLink: <Link to={{}}
                                       onClick={(e) => {
                                         e.preventDefault();
                                         this.props.history.push(URL_PRODUCT_DETAIL + "/" + product.productId);
                                       }}>
                    {product.cpProductId}
                  </Link>,
                  productNameLink: <Link to={{}}
                                         onClick={(e) => {
                                           e.preventDefault();
                                           this.props.history.push(URL_PRODUCT_DETAIL + "/" + product.productId);
                                         }}>
                    {product.productName}
                  </Link>,
                  subscriptionGroup: product.subscriptionGroupId !== null ? <Link to={{}}
                                                                                  onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    this.props.history.push(URL_PRODUCT_SUBSCRIPTIONGROUP_DETAIL + "/" + product.subscriptionGroupId);
                                                                                  }}>
                    {product.subscriptionGroupId}</Link> : <span> - </span>,
                  visibility: this.props.t(visibilityCode2Key[product.visibility]),
                  productType: this.props.t(productTypeCode2Key[product.productType] === FREE_TRIAL_SUBSCRIPTION ? SUBSCRIPTION : productTypeCode2Key[product.productType]),
                  productStatus: this.props.t(productStatusCode2Key[product.productStatus]),
                  deleteProductButton:
                      isProductDeletable(product.productStatus, product.productDeletable) ? (
                          <button
                              type="button"
                              className="btn btn-sm btn-outline-400"
                              onClick={(e) => {
                                this.setState({
                                  productId: [product.productId]
                                })
                                this.handleDeletePopup(true);
                              }}>
                            {this.props.t("DELETE")}
                          </button>
                      ) : (
                          <span>-</span>
                      ),
                  modifiedDate: product.modifiedDate.split(" ")[0]
                };
              })}
              nonSelectableRows={ // Should use 'keyField' value
                this.state.fetchedProductList
                    .map(product => !isProductDeletable(product.productStatus, true) ? product.productId : null)
                    .filter(e => e != null)}
              onTableSort={this.handleTableSort}
              onTableChange={this.handleTableChange}
              onRowSelect={this.handleRowSelect}/>
          <div className="pagenationGroup clearfix max">
            <div className="float-left">
              <button
                  type="button"
                  className={`btn btn-sm btn-success ${nonSelected && "disabled"}`}
                  disabled={nonSelected}
                  onClick={() => this.handleModalToggle(true)}>
                {this.props.t("CHANGE_VISIBILITY")}
              </button>
              <button
                  type="button"
                  disabled={nonSelected || !isAllRetirable}
                  className={`btn btn-sm btn-outline-success ml-6 ${nonSelected && "disabled"}`}
                  onClick={() => this.handleRetirePopup(true)}>
                {this.props.t("RETIRE_PRODUCT")}
              </button>
              <button
                  type="button"
                  disabled={nonSelected || !isAllDeletable}
                  className={`btn btn-sm btn-outline-success ml-6 ${nonSelected && "disabled"}`}
                  onClick={(e) => {
                    this.handleDeletePopup(true)
                  }}>
                {this.props.t("DELETE_PRODUCT")}
              </button>
            </div>
            <div className="float-right">
              <Link to={URL_PRODUCT_ADDNEW}>
                <button type="button" className="btn btn-sm btn-primary">
                  {this.props.t("ADD_NEW_PRODUCT")}
                </button>
              </Link>
              <Link to={URL_PRODUCT_ADDMULTIPLE}>
                <button type="button" className="btn btn-sm btn-outline-primary ml-6">
                  {this.props.t("ADD_MULTI_PRODUCT")}
                </button>
              </Link>
              <Link to={URL_PRODUCT_MODIFYMULTIPLE}>
                <button type="button" className="btn btn-sm btn-outline-primary ml-6">
                  {this.props.t("MODIFY_MULTI_PRODUCT")}
                </button>
              </Link>
            </div>
          </div>
          {/* Below is for popup for 'Change Visibility' button */}
          <BasePopup
              open={this.state.changeVisibilityPopupOpen || this.state.confirmDeletePopupOpen || this.state.confirmRetirePopupOpen}
              title={this.state.changeVisibilityPopupOpen ? this.props.t("CHANGE_VISIBILITY") : this.props.t("ALERT")}
              onClose={() => {
                if (this.state.changeVisibilityPopupOpen) {
                  this.handleModalToggle(false);
                } else if (this.state.confirmDeletePopupOpen) {
                  this.handleDeletePopup(false)
                } else if (this.state.confirmRetirePopupOpen) {
                  this.handleRetirePopup(false);
                }
              }}
              body={
                <>
                  {this.state.confirmDeletePopupOpen && <p>{this.props.t("ARE_YOU_SURE_YOU_WANT_TO_DELETE")}</p>}
                  {this.state.confirmRetirePopupOpen && <p>{this.props.t("ARE_YOU_SURE_YOU_WANT_TO_RETIRE")}</p>}
                  {this.state.changeVisibilityPopupOpen && <>
                    <div className="mb-20">
                      {this.props.t("YOU_WILL_CHANGE")}<strong className="text-success">{this.state.selectedProductIdList.length}</strong> {this.state.selectedProductIdList.length > 1 ? this.props.t("PRODUCTS") : this.props.t("PRODUCT")} {this.props.t("SELECTING_VISIBILITY")}
                    </div>
                    <table className="table table-borderd">
                      <thead>
                      <tr>
                        <th className="border-bottom">{this.props.t("CHANGE_VISIBILITY")}</th>
                        <th className="border-bottom bg-white">
                          <div className="btn-group btn-group-toggle btn-group-radio" data-toggle="buttons">
                            <label className={`btn btn-radio ${Object.is(this.state.modalVisibilityValue, visibilityKey2Code.SHOW) && "checked"}`}>
                              <input type="radio" name="a" onClick={() => this.setState({modalVisibilityValue: visibilityKey2Code.SHOW})}/>{this.props.t("SHOW")}
                            </label>
                            <label className={`btn btn-radio ${Object.is(this.state.modalVisibilityValue, visibilityKey2Code.HIDE) && " checked"}`}>
                              <input type="radio" name="a" onClick={() => this.setState({modalVisibilityValue: visibilityKey2Code.HIDE})}/>{this.props.t("HIDE")}
                            </label>
                            <label className={`btn btn-radio ${Object.is(this.state.modalVisibilityValue, visibilityKey2Code.OPTIONAL) && " checked"}`}>
                              <input type="radio" name="a" onClick={() => this.setState({modalVisibilityValue: visibilityKey2Code.OPTIONAL})}/>{this.props.t("OPTIONAL")}
                            </label>
                          </div>
                        </th>
                      </tr>
                      </thead>
                    </table>
                  </>}
                </>
              }
              footer={
                <>
                  {this.state.changeVisibilityPopupOpen && <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" disabled={this.state.modalVisibilityValue.length === 0} onClick={() => {
                      this.handleChangeVisibility();
                      this.handleModalToggle(false)
                    }}>
                      {this.props.t("SAVE")}
                    </button>
                    <button type="button" className="btn btn-outline-secondary" onClick={() => this.handleModalToggle(false)}>
                      {this.props.t("CANCEL")}
                    </button>
                  </div>}
                  {this.state.confirmDeletePopupOpen && <>
                    <button type="button" className="btn btn-secondary" onClick={() => {
                      if (this.state.productId !== null) {
                        this.handleDelete(this.state.productId)
                      } else {
                        this.handleDelete(this.state.selectedProductIdList)
                      }
                    }}>
                      {this.props.t("CONFIRM")}
                    </button>
                    <button type="button" className="btn btn-outline-secondary" onClick={() => this.handleDeletePopup(false)}>
                      {this.props.t("CANCEL")}
                    </button>
                  </>}
                  {this.state.confirmRetirePopupOpen && <>
                    <button type="button" className="btn btn-secondary" onClick={(e) => {
                      this.handleRetire(this.state.selectedProductIdList);
                    }}>
                      {this.props.t("CONFIRM")}
                    </button>
                    <button type="button" className="btn btn-outline-secondary" onClick={() => this.handleRetirePopup(false)}>
                      {this.props.t("CANCEL")}
                    </button>
                  </>}
                </>
              }/>
        </div>
    )
        ;
  }

  componentDidMount() {
    this.fetchDefaultProductList(1, 15);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (isSelectedAppChanged(this.props.carousel.selectedContsId, prevProps.carousel.selectedContsId)) {
      this.searchBarRef.current.resetState();
      this.fetchDefaultProductList(1, 15);
    }
  }

}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel,
  };
};

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(ProductListPanel)));
