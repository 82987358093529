import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import axios from 'axios';
import {AXIOS_CLIENT_TIMEOUT, DESC_SORTING_ORDER, REGISTERED_DATE_SORTNAME, SUCCESS} from '../../utils/globalConstants';
import 'react-toastify/dist/ReactToastify.css';
import {startLoading, stopLoading} from '../../ducks/loading';
import DataTableWrapperForListing from '../datable/DataTableWrapperForListing';
import SearchBar from '../searchbar/SearchBar';
import {testBuyerAttributes} from '../../utils/settingsAttributes';
import {Link, withRouter} from 'react-router-dom';
import {URL_DELETE_TESTBUYER_API, URL_GET_TESTBUYER_LIST_DATA_API, URL_SETTINGS_TESTBUYER, URL_SETTINGS_TESTBUYER_REGISTRATION} from '../../utils/urlConstants';
import BasePopup from '../popup/BasePopup';
import {handleHttpError} from '../../utils/ErrorUtils';
import {toast} from 'react-toastify';
import {isSelectedAppChanged} from '../../ducks/carousel';

class TestBuyerListPanel extends Component {

  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});
    this.searchBarRef = React.createRef();

    this.state = {
      testBuyerListPage: 1,
      totalListSize: 0,
      listSizePerPage: 15,
      testBuyerList: [],
      selectedTestBuyerIdList: [],
      searchParams: {},
      modal: false,
    }
  }

  handleSearchClick = (searchParams) => {
    const params = {
      cpAppId: this.props.carousel.selectedAppId,
      keywordType: searchParams.keywordOption,
      searchText: searchParams.keywordText,
      sortName: this.state.sortName,
      sortOrder: this.state.sortOrder,
      pageNumber: this.state.testBuyerListPage,
      limit: this.state.listSizePerPage
    };
    this.setState({searchParams: params, testBuyerListPage: 1}, () => this.triggerTestBuyerSearch())
  };

  triggerTestBuyerSearch = () => {
    const {searchParams} = this.state;
    searchParams.sortOrder = this.state.sortOrder;
    searchParams.sortName = this.state.sortName;
    if (this.state.testBuyerListPage && this.state.listSizePerPage) {
      searchParams.limit = this.state.listSizePerPage;
      searchParams.pageNumber = this.state.testBuyerListPage;
      return new Promise((resolve, reject) => {
        this.props.startLoading();
        this.httpClient
            .get(URL_GET_TESTBUYER_LIST_DATA_API, {
              params: searchParams
            })
            .then(response => {
              this.setState({
                totalListSize: response.data.records,
                testBuyerList: response.data.data
              });
              this.props.stopLoading();
            })
            .catch(error => {
              handleHttpError(this.props.t, this.props.history, error);
              reject(error);
              this.props.stopLoading();
            })
      });
    }
    return Promise.resolve(this.props.t("CONDITION_NOT_MET"));
  };

  handleDeleteTestBuyer = () => {
    this.setState({modal: false});
    if (!this.state.selectedTestBuyerIdList.length) {
      return
    }
    this.props.startLoading();
    this.httpClient
        .post(URL_DELETE_TESTBUYER_API, this.state.selectedTestBuyerIdList)
        .then(response => {
          if (response.data.status !== SUCCESS) {
            toast(this.props.t("TESTBUYER_DELETE_ALERT_FAIL"));
          } else {
            toast(this.props.t("TESTBUYER_DELETE_ALERT_SUCCESS"));
            this.fetchTestBuyerList(this.state.pageNumber, this.state.listSizePerPage);
          }
          this.props.stopLoading();
        })
        .catch(error => {
          handleHttpError(this.props.t, this.props.history, error);
          this.props.stopLoading();
        })
  };

  handleRowSelect = (isSelect, rows) => {

    rows.forEach(row => {
      if (isSelect) {
        this.setState(prevState => {
          if (!prevState.selectedTestBuyerIdList.find(testBuyerId => row["testBuyerId"] === testBuyerId)) {
            return {
              selectedTestBuyerIdList: [...prevState.selectedTestBuyerIdList, row["testBuyerId"]]
            };
          }
          return {}
        });
      } else {
        this.setState(prevState => {
          return {
            selectedTestBuyerIdList: prevState.selectedTestBuyerIdList.filter(testBuyerId => row["testBuyerId"] !== testBuyerId)
          };
        })
      }
    });
  };

  handleTableChange = (page, sizePerPage) => {
    this.setState({
      testBuyerListPage: page,
      listSizePerPage: sizePerPage,
    }, () => this.triggerTestBuyerSearch());
  };

  handleTableSort = (sortOrder, sortField) => {
    const tableHeaderField2SortName = {
      "testBuyerIdLink": "testBuyerEmail",
      "testBuyerName": "testBuyerName",
      "contact": "testBuyerTelephone",
      "registrationDate": "registeredDate"
    };
    this.setState({
      sortOrder: sortOrder === 'desc' ? "DESC" : "ASC",
      sortName: tableHeaderField2SortName[sortField] ? tableHeaderField2SortName[sortField] : sortField,
      testBuyerListPage: 1,
    }, () => this.triggerTestBuyerSearch())
  };

  render() {
    return (
        <div className="tab-pane active" role="tabpanel">
          <SearchBar
              ref={this.searchBarRef}
              keywordPanel={{
                show: true,
                label: this.props.t("KEYWORD"),
                placeholder: this.props.t("PLEASE_ENTER_KEYWORDS"),
                options: [testBuyerAttributes.testBuyerId.key, testBuyerAttributes.testBuyerName.key],
                showDownload: false,
              }}
              datePanel={{
                show: false,
              }}
              filterPanel={{
                show: false,
              }}
              radioButtonPanel={{
                show: false,
              }}
              onSearchClick={this.handleSearchClick}/>
          <DataTableWrapperForListing
              noSelect
              keyField="testBuyerId" // one of 'dataField'
              hideSelectColumn={false}
              columns={[ // 'dataField' should be same as api response fields below "data" use it as 'object key'
                {dataField: "testBuyerId", hidden: true},
                {dataField: "testBuyerIdLink", text: this.props.t(testBuyerAttributes.testBuyerId.key), headerStyle: {width: "30%"}, sort: false, classes: () => "text-truncate", titleHeader: "testBuyerEmail"},
                {dataField: "testBuyerName", text: this.props.t(testBuyerAttributes.testBuyerName.key), headerStyle: {width: "30%"}, sort: false, classes: () => "text-truncate"},
                {dataField: "contact", text: this.props.t(testBuyerAttributes.contact.key), headerStyle: {width: "20%"}, sort: false, classes: () => "text-truncate"},
                {dataField: "registrationDate", text: this.props.t(testBuyerAttributes.registrationDate.key), headerStyle: {width: "20%"}, sort: true, classes: () => "text-truncate"},
              ]}
              page={this.state.pageNumber}
              sizePerPage={this.state.listSizePerPage}
              totalSize={this.state.totalListSize}
              rows={this.state.testBuyerList.map(testBuyer => {
                return {
                  ...testBuyer,
                  testBuyerIdLink:
                      <Link to={{}}
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.history.push(URL_SETTINGS_TESTBUYER + "/" + testBuyer.testBuyerId);
                            }}>
                        {testBuyer.testBuyerEmail}
                      </Link>,
                  contact: testBuyer.testBuyerTelephone,
                  registrationDate: testBuyer.registeredDate,
                };
              })}
              onTableSort={this.handleTableSort}
              onTableChange={this.handleTableChange}
              onRowSelect={this.handleRowSelect}/>
          <div className="pagenationGroup clearfix max">
            <button
                type="button" className="btn btn-sm btn-outline-primary float-left"
                onClick={() => this.setState({modal: true})}>
              {this.props.t("DELETE")}
            </button>
            <Link to={URL_SETTINGS_TESTBUYER_REGISTRATION}>
              <button type="button" className="btn btn-sm btn-primary float-right">
                {this.props.t("TEST_BUYER_REGISTER")}
              </button>
            </Link>
          </div>
          <BasePopup
              open={this.state.modal}
              className="modal-sm"
              title={this.props.t("ALERT")}
              onClose={() => this.setState({modal: false})}
              body={
                <p>{this.state.selectedTestBuyerIdList.length ? this.props.t("DO_YOU_WANT_TO_DELETE") : this.props.t("PLEASE_SELECT_AT_LEAST_ONE_ITEM")}</p>
              }
              footer={
                <>
                  <button type="button" className="btn btn-secondary" onClick={() => this.handleDeleteTestBuyer()}>
                    {this.props.t("CONFIRM")}
                  </button>
                  {
                    this.state.selectedTestBuyerIdList.length > 0 &&
                    <button type="button" className="btn btn-outline-secondary" onClick={() => this.setState({modal: false})}>
                      {this.props.t("CANCEL")}
                    </button>
                  }

                </>
              }/>
        </div>
    );
  }

  fetchTestBuyerList = (page, sizePerPage) => {
    this.setState({
      sortName: REGISTERED_DATE_SORTNAME,
      sortOrder: DESC_SORTING_ORDER
    }, () => {
      let params = this.state.searchParams;
      params = {
        ...params,
        sortOrder: this.state.sortOrder,
        sortName: this.state.sortName,
        pageNumber: page,
        limit: sizePerPage,
        cpAppId: this.props.carousel.selectedAppId,
      };
      return new Promise((resolve, reject) => {
        this.props.startLoading();
        this.httpClient
            .get(URL_GET_TESTBUYER_LIST_DATA_API, {
              params
            })
            .then(response => {
              this.setState({
                pageNumber: page,
                listSizePerPage: sizePerPage,
                totalListSize: response.data.records,
                testBuyerList: response.data.data,
                searchParams: params
              });
              this.props.stopLoading();
            })
            .catch(error => {
              handleHttpError(this.props.t, this.props.history, error);
              this.props.stopLoading();
              reject(error);
            })
      });
    });
  };

  componentDidMount() {
    this.fetchTestBuyerList(1, 15);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (isSelectedAppChanged(this.props.carousel.selectedContsId, prevProps.carousel.selectedContsId)) {
      this.searchBarRef.current.resetState();
      this.fetchTestBuyerList(1, 15);
    }
  }
}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel
  };
};

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(TestBuyerListPanel)));
