import React, {Component} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Link, withRouter} from "react-router-dom";
import DataTableWrapperForListing from "../datable/DataTableWrapperForListing";
import {API_ADMIN_CPMANAGEMENT_DETAIL, API_ADMIN_CPMANAGEMENT_LIST} from "../../utils/urlConstants";
import axios from "axios";
import {AXIOS_CLIENT_TIMEOUT, DESC_SORTING_ORDER} from "../../utils/globalConstants";
import SearchBar from "../searchbar/SearchBar";
import {CPAttributes, cpUserMemberTypeCode2Key} from "../../utils/adminAttributes";
import {startLoading, stopLoading} from '../../ducks/loading';
import {handleHttpError} from '../../utils/ErrorUtils';
import Form from '../Form';
import {getProperty} from '../../utils/propertiesByDomain';
import {isDateFutureThan} from '../../utils/searchBarUtils';
import {toast} from 'react-toastify';

class CPManagementPanel extends Component {
  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});

    this.state = {
      pageNumber: 1,
      totalListSize: 0,
      limit: 15,
      CPUserList: [],
      searchParams: {}
    }

    this.formRef = React.createRef();
    this.submitForm = this.submitForm.bind(this);
  }

  submitForm = () => {
    this.formRef.current.submit();
  };

  handleSearchClick = (searchParams) => {
    if (isDateFutureThan(searchParams.startDate, searchParams.endDate)) {
      toast(this.props.t("CHECK_DATES"))
      return;
    }
    const params = {
      contentId: this.props.carousel.selectedContsId,
      dateStart: searchParams.startDate,
      dateEnd: searchParams.endDate,
      searchText: searchParams.keywordText,
      sortName: "userJoinDate",
      sortOrder: DESC_SORTING_ORDER,
      pageNumber: this.state.pageNumber,
      limit: this.state.limit
    };
    this.setState({searchParams: params}, () => this.triggerCPSearch())
  };

  triggerCPSearch = () => {
    const {searchParams} = this.state;
    if (searchParams.pageNumber && searchParams.limit) {
      return new Promise((resolve, reject) => {
        this.props.startLoading();
        this.httpClient
            .get(API_ADMIN_CPMANAGEMENT_LIST, {
              params: searchParams
            })
            .then(response => {
              this.setState({
                totalListSize: response.data.records,
                CPUserList: response.data.data
              });
              this.props.stopLoading();
            })
            .catch(error => {
              handleHttpError(this.props.t, this.props.history, error);
              reject(error);
              this.props.stopLoading();
            })
      });
    }
    return Promise.resolve(this.props.t("CONDITION_NOT_MET"));
  };

  handleCPClick = (cpuserId) => {
    this.setState({currentUserId: cpuserId}, this.submitForm);
  };

  handleTableChange = (page, sizePerPage) => {
    this.setState({
      pageNumber: page,
      limit: sizePerPage,
      searchParams: {
        ...this.state.searchParams,
        pageNumber: page,
        limit: sizePerPage
      }
    }, () => this.triggerCPSearch());
  };

  handleTableSort = (sortOrder, sortField) => {
    const tableHeaderField2SortName = {
      "userIdLink": "userSecAcc",
      "memberType": "userType"
    };

    this.setState(prevState => {
      return {
        searchParams: {
          ...prevState.searchParams,
          sortOrder: sortOrder === 'desc' ? "DESC" : "ASC",
          sortName: tableHeaderField2SortName[sortField] ? tableHeaderField2SortName[sortField] : sortField,
          pageNumber: 1,
          limit: prevState.limit
        },
        pageNumber: 1,
        limit: prevState.limit
      }
    }, () => this.triggerCPSearch())
  };

  render() {
    const actionUrl = getProperty("urlHome") + API_ADMIN_CPMANAGEMENT_DETAIL;
    return (
        <>
          <div className="tab-pane active" role="tabpanel">
            <SearchBar
                keywordPanel={{
                  show: true,
                  label: "Keyword",
                  placeholder: this.props.t("PLEASE_ENTER_KEYWORDS"),
                  showDownload: false,
                }}
                datePanel={{
                  show: false,
                }}
                filterPanel={{
                  show: false,
                }}
                radioButtonPanel={{
                  show: false,
                }}
                onSearchClick={this.handleSearchClick}/>
            <DataTableWrapperForListing
                noSelect
                keyField="userId" // one of 'dataField'
                hideSelectColumn={true}
                columns={[ // 'dataField' should be same as api response fields below "data" use it as 'object key'
                  {dataField: "userId", hidden: true},
                  {dataField: "userIdLink", text: this.props.t(CPAttributes.userID.key), headerStyle: {width: "74.9475%"}, sort: false, titleHeader: "userEmail"},
                  {dataField: "userName", text: this.props.t(CPAttributes.userName.key), headerStyle: {width: "20.03519%"}, sort: false},
                  {dataField: "memberType", text: this.props.t(CPAttributes.memberType.key), headerStyle: {width: "20.03519%"}, sort: true},
                  {dataField: "userStatus", text: this.props.t(CPAttributes.status.key), headerStyle: {width: "20.03519%"}, sort: true},
                ]}
                page={this.state.pageNumber}
                sizePerPage={this.state.limit}
                totalSize={this.state.totalListSize}
                rows={this.state.CPUserList.map(cpUser => {
                  return {
                    ...cpUser,
                    userIdLink:
                        <Link to={{}}
                              onClick={(e) => {
                                e.preventDefault();
                                this.handleCPClick(cpUser.userId);
                              }}>
                          {cpUser.userSecAcc || cpUser.userId}
                        </Link>,
                    memberType: this.props.t(cpUserMemberTypeCode2Key[cpUser.userType]),
                  };
                })}
                onTableSort={this.handleTableSort}
                onTableChange={this.handleTableChange}/>
          </div>
          <Form method="get" action={actionUrl} ref={this.formRef}>
            <input type="hidden" name="userId" value={this.state.currentUserId}/>
          </Form>
        </>
    );
  }

  fetchCPUserList = (page, sizePerPage) => {
    let params = this.state.searchParams;
    params = {
      ...params,
      sortOrder: this.state.sortOrder,
      sortName: this.state.sortName,
      pageNumber: page,
      limit: sizePerPage
    };
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .get(API_ADMIN_CPMANAGEMENT_LIST, {
            params
          })
          .then(response => {
            this.setState({
              pageNumber: page,
              limit: sizePerPage,
              totalListSize: response.data.records,
              CPUserList: response.data.data
            });
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            this.props.stopLoading();
            reject(error);
          })
    });
  };

  componentDidMount() {
    this.fetchCPUserList(1, 15);
  }
}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel
  };
};

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(CPManagementPanel)));