import React, {Component} from 'react';
import {startLoading, stopLoading} from '../../ducks/loading';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {Link, withRouter} from 'react-router-dom';
import {API_PRODUCT_SUBSCRIPTION_GROUP_ADD, API_PRODUCT_SUBSCRIPTION_GROUP_DETAIL, API_PRODUCT_SUBSCRIPTION_GROUP_UPDATE, URL_PRODUCT_SUBSCRIPTIONGROUP_LIST} from '../../utils/urlConstants';
import {handleHttpError} from '../../utils/ErrorUtils';
import axios from 'axios';
import {AXIOS_CLIENT_TIMEOUT, SUCCESS} from '../../utils/globalConstants';
import DataTableWrapperForListing from '../datable/DataTableWrapperForListing';
import {toast} from 'react-toastify';
import SelectrWrapper from '../pluginwrapper/SelectrWrapper';
import {isSelectedAppChanged} from '../../ducks/carousel';

class SubscriptionProductGroupAddModifyPanel extends Component {
  constructor(props) {
    super(props);
    this.httpClient = axios.create({timeout: AXIOS_CLIENT_TIMEOUT});
    this.state = {
      groupName: "",
      groupId: "",
      subscriptionProductList: [],
      guid: "",
      duid: "",
      deviceAllow: false,
      accountOnce: false,
      duidCount: 0,
      isDataLoaded: false
    }

    this.duidCountMin = 0;
    this.duidCountMax = 99;
  }

  handleAccountOnceToggle = () => {
    this.setState(prevState => {
      return {
        accountOnce: !prevState.accountOnce
      }
    });
  };

  handleDeviceAllowToggle = () => {
    this.setState(prevState => {
      return {
        deviceAllow: !prevState.deviceAllow,
        duidCount: prevState.deviceAllow ? 0 : this.state.duidCount
      }
    });

  };

  changeDeviceAllowLimit = (event) => {
    const input = Number(event.target.value);
    if (!isNaN(input) && input >= this.duidCountMin && input <= this.duidCountMax) {
      this.setState({
        duidCount: input,
      })
    }
  };

  fetchGroupDetails = (groupId) => {
    return new Promise((resolve, reject) => {
      this.props.startLoading();
      this.httpClient
          .get(API_PRODUCT_SUBSCRIPTION_GROUP_DETAIL, {
            params: {
              subscriptionGroupId: groupId
            }
          })
          .then(response => {
            this.setState({
              subscriptionProductList: response.data.subscriptionProducts,
              groupName: response.data.groupName,
              groupId: response.data.groupId,
              deviceAllow: response.data.duidYN === 'Y',
              accountOnce: response.data.guidYN === 'Y',
              duidCount: response.data.duidCount,
              isDataLoaded: true
            });
            resolve();
            this.props.stopLoading();
          })
          .catch(error => {
            handleHttpError(this.props.t, this.props.history, error);
            reject(error);
            this.props.stopLoading();
          })
    });
  };

  changeGroupName = (event) => {
    event.preventDefault();
    let input = event.target.value;
    this.setState({
      groupName: input
    });
  };

  applySettingToAll = () => {
    let {freeTrialDays} = this.state.subscriptionProductList[0];
    this.setState(prevState => {
      return {
        subscriptionProductList: prevState.subscriptionProductList.map((product) => {
              product.freeTrialDays = freeTrialDays;
              return product
            }
        )
      }
    });
    toast(this.props.t("APPLY_FREE_TRIAL_DAYS_ALL_PRODUCT_MESSAGE"));
  };

  freeTrialChangeHandler = (event, product) => {
    event.preventDefault();
    let input = event.target.value;
    this.setState(prevState => {
      return {
        subscriptionProductList: prevState.subscriptionProductList.map((item) => {
              if (item.productId === product.productId) {
                item.freeTrialDays = input ? parseInt(input) : 0;
              }
              return item;
            }
        )
      }
    })
  };

  duplicateBenefitChangeHandler = (selected, product) => {
    this.setState(prevState => {
      return {
        subscriptionProductList: prevState.subscriptionProductList.map((item) => {
              if (item.productId === product.productId) {
                item.duplicateBenefitYN = selected.key === "Yes" ? "Y" : "N";
              }
              return item;
            }
        )
      }
    })
  };

  saveDetails = () => {
    let params = {
      cpAppId: this.props.carousel.selectedAppId,
      groupName: this.state.groupName.trim(),
      guidYN: this.state.accountOnce ? "Y" : "N",
      duidYN: this.state.deviceAllow ? "Y" : "N",
      duidCount: this.state.duidCount,
    };
    if (this.props.isModify) {
      params = {
        ...params,
        groupId: this.state.groupId,
        subscriptionProducts: this.state.subscriptionProductList
      };
      if (!this.state.accountOnce && !this.state.deviceAllow) {
        toast(this.props.t("SELECT_ATLEAST_FROM_ACCOUNT_AND_DEVICE"));
        return Promise.resolve(this.props.t("CONDITION_NOT_MET"));
      }
      if ((this.state.deviceAllow && (parseInt(this.state.duidCount) === 0)) || this.state.duidCount === 0) {
        toast(this.props.t("DEVICE_COUNT_SHOULD_BE_GREATER_THAN_ZERO"));
        return Promise.resolve(this.props.t("CONDITION_NOT_MET"));
      }
      return new Promise((resolve, reject) => {
        this.props.startLoading();
        this.httpClient
            .post(API_PRODUCT_SUBSCRIPTION_GROUP_UPDATE, params)
            .then(response => {
              if (response.data.status !== SUCCESS) {
                toast(this.props.t("ERROR_IN_GROUP_UPDATE"));
              } else {
                toast(this.props.t("GROUP_UPDATE_SUCCESSFULLY"));
                this.props.history.push(URL_PRODUCT_SUBSCRIPTIONGROUP_LIST);
              }
              resolve();
              this.props.stopLoading();
            })
            .catch(error => {
              handleHttpError(this.props.t, this.props.history, error);
              reject(error);
              this.props.stopLoading();
            });
      });
    } else {
      return new Promise((resolve, reject) => {
        this.props.startLoading();
        this.httpClient
            .post(API_PRODUCT_SUBSCRIPTION_GROUP_ADD, params)
            .then(response => {
              if (response.data.status !== SUCCESS) {
                toast(this.props.t("ERROR_IN_GROUP_ADDING"));
              } else {
                toast(this.props.t("GROUP_ADD_SUCCESSFULLY"));
                this.props.history.push(URL_PRODUCT_SUBSCRIPTIONGROUP_LIST);
              }
              resolve();
              this.props.stopLoading();
            })
            .catch(error => {
              handleHttpError(this.props.t, this.props.history, error);
              reject(error);
              this.props.stopLoading();
            });
      })
    }
  };

  initData = () => {
    this.setState({
      isDataLoaded: true
    });
  };

  render() {
    const duplicateBenefitOptions = ["Yes", "No"];
    if (!this.state.isDataLoaded) {
      return <div/>
    }
    return (
        <div>
          <div className="clearfix mb-20">
            <h2 className="float-left mb-0"><strong>{this.props.t("GROUP_CONFIGURATION")}</strong></h2>
          </div>
          <div className="form-group-wrap">
            {this.props.isModify &&
                <div className="row mb-3">
                  <div className="col-6">
                    <div className="form-group row">
                      <label className="col col-form-label line-height-1">{this.props.t("SUBSCRIPTION_GROUP")}<br/>{this.props.t("IDENTITY")}<span className="text-success align-middle">*</span></label>
                      <div className="col">
                        <div className="input-group">
                          <input type="text" className="form-control" value={`${this.state.groupId}`} disabled={true} placeholder="Subscription Group Id."/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            }
            <div className="row mb-3">
              <div className="col-6">
                <div className="form-group row">
                  <label className="col col-form-label line-height-1">{this.props.t("SUBSCRIPTION_GROUP")}<br/>{this.props.t("NAME")}<span className="text-success align-middle">*</span></label>
                  <div className="col">
                    <div className="input-group">
                      <input type="text" className="form-control" value={this.state.groupName} onChange={this.changeGroupName} maxLength={200}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix mb-20 mt-60">
            <h2 className="float-left mb-0"><strong>{this.props.t("FREE_TRIAL_SETTINGS")}</strong></h2>
          </div>
          <div className="form-group-wrap">
            <div className="row mb-3">
              <div className="col-9">
                <div className="form-group row align-items-center">
                  <label className="col col-form-label line-height-1">{this.props.t("FREE_TRIAL_OFFERING")} <span className="text-success align-middle">*</span></label>
                  <div className="col">
                    <ul className="list-inline m-0">
                      <li className="list-inline-item">
                        <div className="btn-group-toggle btn-group-checkbox">
                          <label className={`btn-checkbox active ${this.state.accountOnce && "checked"}`}><input type="checkbox" onClick={() => this.handleAccountOnceToggle()}/></label>
                        </div>
                        <span className="ml-6 mr-30 text-muted">{this.props.t("ACCOUNT_ONCE")}</span>
                      </li>
                      <li className="list-inline-item">
                        <div className="btn-group-toggle btn-group-checkbox">
                          <label className={`btn-checkbox active ${this.state.deviceAllow && "checked"}`}><input type="checkbox" onClick={() => this.handleDeviceAllowToggle()}/></label>
                        </div>
                        <span className="ml-6 mr-30 text-muted">{this.props.t("DEVICE")}</span>
                      </li>
                      <li className="list-inline-item">
                        <div className="d-flex center">
                          <input type="number" min={this.duidCountMin} max={this.duidCountMax} className="form-control form-control-input" value={Number(this.state.duidCount).toString()} onChange={this.changeDeviceAllowLimit} disabled={!this.state.deviceAllow} maxLength={2}/>
                          <span className="input-group-text bg-white border-0">{this.props.t("TIMES")}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.props.isModify &&
              <>
                <div className="clearfix mb-10 mt-60">
                  <h2 className="float-left mb-0"><strong>{this.props.t("SUBSCRIPTION_PRODUCTS")}</strong> <span className="text-success align-middle">*</span></h2>
                  <div className="float-right">
                    <div className="btn-group-toggle btn-group-checkbox ml-7">
                      <label className="btn-checkbox btn-checkbox-button"><input type="checkbox" onClick={this.applySettingToAll}/>{this.props.t("APPLY_ALL_FREE_TRIAL")}</label>
                    </div>
                  </div>
                </div>
                <DataTableWrapperForListing
                    keyField="productId" // one of 'dataField'
                    columns={[ // 'dataField' should be same as api response fields below "data" use it as 'object key'
                      {dataField: "productId", hidden: true},
                      {dataField: "productName", text: this.props.t("PRODUCT_NAME"), headerStyle: {width: "17%"}, sort: false, classes: () => "text-left text-truncate", titleHeader: "productName"},
                      {dataField: "productId", text: this.props.t("PRODUCT_ID"), headerStyle: {width: "17%"}, sort: false, classes: () => "text-left text-truncate"},
                      {dataField: "billingPeriod", text: this.props.t("BILLING_PERIOD"), headerStyle: {width: "17%"}, sort: false},
                      {dataField: "level", text: this.props.t("LEVEL"), headerStyle: {width: "17%"}, sort: false},
                      {dataField: "freeTrialPeriod", text: this.props.t("FREE_TRIAL_PERIOD_DAYS"), headerStyle: {width: "16%"}, sort: false, hideTitle: true},
                      {dataField: "duplicateBenefit", text: this.props.t("DUPLICATE_BENEFIT"), headerStyle: {width: "16%"}, sort: false},
                    ]}
                    rows={this.state.subscriptionProductList.map((product, index) => {
                      return {
                        ...product,
                        productName: product.productName,
                        productId: product.cpProductId,
                        billingPeriod: product.billPeriod,
                        level: product.productLevel,
                        freeTrialPeriod: <input
                            className="form-control form-control-input"
                            type="number"
                            min={0}
                            value={product.freeTrialDays}
                            onChange={(event) => this.freeTrialChangeHandler(event, product)}
                        />,
                        duplicateBenefit: <SelectrWrapper
                            isDisable={false}
                            className="form-control"
                            clearable={false}
                            placeholder={product.duplicateBenefitYN === 'Y' ? "Yes" : "No"}
                            value={product.duplicateBenefitYN === 'Y' ? "Yes" : "No"}
                            onChange={(selected) => this.duplicateBenefitChangeHandler(selected, product)}>
                          {duplicateBenefitOptions.map(v => <option key={v} value={v}>{this.props.t(v)}</option>)}
                        </SelectrWrapper>
                      };
                    })}
                    disableFooter={true}/>

                <div className="row mt-30">
                  <div className="col text-right">
                    <Link to={URL_PRODUCT_SUBSCRIPTIONGROUP_LIST}>
                      <button type="button" className="btn btn-sm btn-auto btn-outline-primary">{this.props.t("LIST")}</button>
                    </Link>
                    <button type="button" className="btn btn-sm btn-auto btn-primary ml-10" onClick={() => this.saveDetails()}>{this.props.t("SAVE")}</button>
                  </div>
                </div>
              </>
          }
          {!this.props.isModify &&
              <div className="row mt-30">
                <div className="col text-right">
                  <Link to={URL_PRODUCT_SUBSCRIPTIONGROUP_LIST}>
                    <button type="button" className="btn btn-sm btn-auto btn-outline-primary">{this.props.t("LIST")}</button>
                  </Link>
                  <button type="button" className="btn btn-sm btn-auto btn-primary ml-10" onClick={() => this.saveDetails()}>{this.props.t("REGISTER")}</button>
                </div>
              </div>
          }
        </div>
    )
  }

  componentDidMount() {
    if (this.props.isModify) {
      this.fetchGroupDetails(this.props.match.params.groupId);
    } else {
      this.initData();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (isSelectedAppChanged(this.props.carousel.selectedAppId, prevProps.carousel.selectedAppId)) {
      this.initData();
    }
  }

}

const mapStateToProps = (state) => {
  return {
    carousel: state.carousel,
  };
};

const mapDispatchToProps = {
  startLoading: startLoading,
  stopLoading: stopLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(SubscriptionProductGroupAddModifyPanel)));