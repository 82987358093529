export const productTypeKey2Code = {
  CONSUMABLE: "1",
  NON_CONSUMABLE: "2",
  LIMITED_PERIOD: "3",
  SUBSCRIPTION: "4",
  FREE_TRIAL_SUBSCRIPTION: "6",
  DYNAMIC_PRODUCT: "7",
};

export const productTypeCode2Key = {
  "1": "CONSUMABLE",
  "2": "NON_CONSUMABLE",
  "3": "LIMITED_PERIOD",
  "4": "SUBSCRIPTION",
  "6": "FREE_TRIAL_SUBSCRIPTION",
  "7": "DYNAMIC_PRODUCT",
};

export const visibilityKey2Code = {
  SHOW: "01",
  HIDE: "02",
  OPTIONAL: "03",
};

export const visibilityCode2Key = {
  "01": "SHOW",
  "02": "HIDE",
  "03": "OPTIONAL",
};

export const productStatusKey2Code = {
  INACTIVE: "01",
  ACTIVE: "03",
  RETIRED: "05",
  DELETED: "06",
};

export const productStatusCode2Key = {
  "01": "INACTIVE",
  "03": "ACTIVE",
  "05": "RETIRED",
  "06": "DELETED",
};

export const billingPeriodKey2Code = {
  "Weekly": "W",
  "Monthly": "M",
  "Quarterly": "Q",
  "Annually": "Y"
};

export const billingPeriodCode2Key = {
  "W": "Weekly",
  "M": "Monthly",
  "Q": "Quarterly",
  "Y": "Annually"
};

export const productProductLevelKey2Code = {
  "Lv.1": "1",
  "Lv.2": "2",
  "Lv.3": "3",
  "Lv.4": "4",
  "Lv.5": "5",
  "Lv.6": "6",
  "Lv.7": "7",
  "Lv.8": "8",
  "Lv.9": "9",
  "Lv.10": "10"
};

export const productProductLevelCode2Key = {
  "1": "Lv.1",
  "2": "Lv.2",
  "3": "Lv.3",
  "4": "Lv.4",
  "5": "Lv.5",
  "6": "Lv.6",
  "7": "Lv.7",
  "8": "Lv.8",
  "9": "Lv.9",
  "10": "Lv.10"
};

export const isProductDeletable = (status, isDeletable) => {
  return (!(Object.is(status, productStatusKey2Code.DELETED) || Object.is(status, productStatusKey2Code.RETIRED))) && isDeletable;
};

export const convertCodeToFrequency = (input) => {
  if (input === 'M') {
    return "Every Month"
  } else if (input === 'W') {
    return "Every Week"
  } else if (input === 'Y') {
    return "Every Year"
  } else {
    return null
  }
};

export const convertFrequencyToCode = (input) => {
  if (input === 'Every Month') {
    return 'M'
  } else if (input === 'Every Week') {
    return 'W'
  } else if (input === 'Every Year') {
    return 'Y'
  } else {
    return null
  }
};

export const getExpirationFromValidDates = (startDate, endDate) => {
  const startYear = startDate.substr(0, startDate.indexOf("-"));
  const endYear = endDate.substr(0, endDate.indexOf("-"));
  const diffYear = parseInt(endYear) - parseInt(startYear);
  if (diffYear > 1) {
    return "NOT_APPLICABLE";
  } else if (diffYear === 1) {
    return "YEAR";
  }
  const startMonth = startDate.substr(startDate.indexOf("-") + 1, startDate.lastIndexOf("-"));
  const endMonth = endDate.substr(endDate.indexOf("-") + 1, endDate.lastIndexOf("-"));
  if (parseInt(endMonth) - parseInt(startMonth) <= 1) {
    return "ONE_MONTH"
  } else {
    return "SIX_MONTH"
  }
};

// 1. Value of the 'key' should be into i18next
// 2. Values of the 'value' are null if the range is impossible to enumerate.
//    See http://wiki.vd.sec.samsung.net/display/BIL/Types+and+Attributes+of+Product+in+DPI
export const productAttributes = {
  productId: {
    key: "PRODUCT_ID",
    value: null // Less than or equal to 20 characters
  },
  cpProductId: {
    key: "CP_PROD_ID",
    value: null
  },
  productName: {
    key: "REP_CNTR_PROD_NM",
    value: null // Less than or equal to 50 characters
  },
  productPrice: {
    key: "PRODUCT_PRICE",
    value: null
  },
  productType: {
    key: "PRODUCT_TYPE",
    value: [ // See productTypeCodeMapping above
      "CONSUMABLE",
      "NON_CONSUMABLE",
      "LIMITED_PERIOD",
      "SUBSCRIPTION",
      "FREE_TRIAL_SUBSCRIPTION",
      "DYNAMIC_PRODUCT"
    ]
  },
  productStatus: {
    key: "PRODUCT_STATUS",
    value: [
      "ACTIVE",
      "RETIRED",
    ]
  },
  productDetailsStatus: {
    key: "PRODUCT_STATUS",
    value: [
      "ACTIVE",
      "RETIRED",
      "DELETED"
    ]
  },
  productValidityPeriod: {
    key: "PRODUCT_VALIDITY_PERIOD",
    value: null // Natural Number, Unit of the value is 'minute'.
  },
  subscriptionProductPaymentCycle: {
    key: "SUBSCRIPTION_PRODUCT_PAYMENT_CYCLE",
    value: ['W', 'M', 'Q', 'Y']
  },
  subscriptionPaymentTotalDegree: {
    key: "SUBSCRIPTION_PAYMENT_TOTAL_DEGREE",
    value: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 9000]
  },
  freeTrialDays: {
    key: "FREE_TRIAL_DAYS",
    value: null // Natual Number, less than or equal to 999
  },
  guidCheckYn: {
    key: "GUID_CHECK_YN",
    value: ['Y', 'N']
  },
  duidCheckYn: {
    key: "DUID_CHECK_YN",
    value: ['Y', 'N']
  },
  duidLimitCount: {
    key: "DUID_LIMIT_COUNT",
    value: null // Natual Number, less than or equal to 99
  },
  cpVerifyUriYn: {
    key: "CP_VERIFY_URI_YN",
    value: ['Y', 'N']
  },
  cpVerifyUri: {
    key: "CP_VERIFY_URI",
    value: null
  },
  visibility: { // See visibilityCodeMapping above
    key: "VISIBILITY",
    value: ["SHOW", "HIDE", "OPTIONAL"]
  },
  description: {
    key: "DESCRIPTION",
    value: null
  },
  expiration: {
    key: "EXPIRATION",
    value: null
  },
  modifiedDate: {
    key: "MODIFIED_DATE",
    value: null
  },
  registeredDate: {
    key: "REGISTERED_DATE",
    value: null
  },
  country: {
    key: "COUNTRY",
    value: null
  },
  currency: {
    key: "CURRENCY",
    value: null
  },
  minimumDynamicProductPrice: {
    // TODO add key and value after storyboard is updated
  },
  maximumDynamicProductPrice: {
    // TODO add key and value after storyboard is updated
  },
  taxCategory: {
    key: "TAX_CATEGORY",
    value: null
  },
  taxRate: {
    key: "TAX_RATE",
    value: null
  },
  subscriptionGroup: {
    key: "SUBSCRIPTION_GROUP",
    value: null
  },
  productLevel: {
    Key: "PRODUCT_LEVEL",
    value: ["Lv.1", "Lv.2", "Lv.3", "Lv.4", "Lv.5", "Lv.6", "Lv.7", "Lv.8", "Lv.9", "Lv.10"]
  },
  billingPeriod: {
    Key: "BILLING_PERIOD",
    value: ['Weekly', 'Monthly', 'Quarterly', 'Annually']
  }
  // TODO fill others if you need it ...
};