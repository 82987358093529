import React from "react";
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {alwaysShowMenuKeys, dpiPortalAdminMenu, dpiPortalMenus, dpiPortalSettlementAppMenus, dynamicUrlToConstantUrlMapper, getFirstDepthMenuEntityByUri, menuKeys} from "../../utils/dpiPortalMenusAndUtils";
import {connect} from "react-redux";
import structuredClone from '@ungap/structured-clone';

function SubItem(props) {
  return (
      <li key={props.menuKey}>
        <Link to={props.url}>{props.text}</Link>
      </li>
  );
}

function ChildItem(props) {
  return (
      <li key={props.menuKey} className={`${props.isActive && "active"}`}>
        <Link to={props.url}>{props.text}</Link>
        {props.children && <ul>{props.children}</ul>}
      </li>
  );
}

function LinkItem(props) {
  return (
      <Link to={{}} className={"toggle " + props.className}>
        {props.children}
      </Link>
  );
}

function renderMenuItemByKey(menuKey) {
  // eslint-disable-next-line default-case
  switch (menuKey) {
    case menuKeys.SETTINGS:
      return (
          <LinkItem className="settting" menuKey={menuKey}>
            <span>Settings</span>
          </LinkItem>
      );
    case menuKeys.PRODUCT:
      return (
          <LinkItem className="product" menuKey={menuKey}>
            <span>Product</span>
          </LinkItem>
      );
    case menuKeys.SALES:
      return (
          <LinkItem className="sales" menuKey={menuKey}>
            <span>Sales</span>
          </LinkItem>
      );
    case menuKeys.CAMPAIGN:
      return (
          <LinkItem className="campaign" menuKey={menuKey}>
            <span>Campaign</span>
          </LinkItem>
      );
    case menuKeys.STATISTICS:
      return (
          <LinkItem className="statistics" menuKey={menuKey}>
            <span>Statistics</span>
          </LinkItem>
      );
    case menuKeys.MONTHLY_SETTLEMENT_APP_APPS:
      return (
          <LinkItem className="settlement" menuKey={menuKey}>
            <span className="d-block">
              Monthly
              <br/>
              Settlement
              <br/>
              <small>(All Apps)</small>
            </span>
          </LinkItem>
      );
    case menuKeys.MEMBER:
      return (
          <LinkItem className="member" menuKey={menuKey}>
            <span>Member</span>
          </LinkItem>
      );
    case menuKeys.SUPPORT:
      return (
          <LinkItem className="support" menuKey={menuKey}>
            <span>Support</span>
          </LinkItem>
      );
    case menuKeys.ADMIN:
      return (
          <LinkItem className="admin" menuKey={menuKey}>
            <span>Admin</span>
          </LinkItem>
      );
    case menuKeys.REQUEST_MANAGEMENT:
      return (
          <LinkItem className="request" menuKey={menuKey}>
            <span>CSV Repo</span>
          </LinkItem>
      );
    default:
      return(<></>)
  }
}

function MenuItem(props) {
  const liClassName = props.isActive ? "dropdown active" : "dropdown";

  return (
      <li key={props.menuKey} className={liClassName} onClick={() => props.onClick(props.menuKey)}>
        {renderMenuItemByKey(props.menuKey)}
        <ul>{props.children}</ul>
      </li>
  );
}

/**
 * LeftNaviBarList
 *
 * If depth is 1, render <MenuItem>
 * If depth is 2, render <ChildItem>
 * If depth is 3, render <SubItem>
 *
 * activeMenuItemKey will open the <MenuItem>.
 *
 * <ChildItem> will be active automatically via seeing uri
 */
class LeftNaviBarList extends React.Component {
  constructor(props) {
    super(props);

    const activeMenu = getFirstDepthMenuEntityByUri(dynamicUrlToConstantUrlMapper(this.props.location.pathname));

    this.state = {
      activeMenuItemKey: activeMenu ? activeMenu.key : null
    }
  }

  handleMenuItemClick = (menuKey) => {
    if (Object.is(this.state.activeMenuItemKey, menuKey)) {
      this.setState({activeMenuItemKey: null});
    } else {
      this.setState({activeMenuItemKey: menuKey});
    }
  };

  /**
   * Render All menu recursively
   *
   * Recursive should stop when submenu is undefined or depth is below 3
   */
  renderMenu = (selectedApp, menus, depth) => {
    return menus.map(menu => {
      let child;
      if (menu.subMenus && depth < 3) {
        child = this.renderMenu(selectedApp, menu.subMenus, depth + 1);
      }
      let returnContent;
      if (menu.hidden) {
        returnContent = <></>
      } else if (depth === 1) {
        const isActive = Object.is(this.state.activeMenuItemKey, menu.key);
        returnContent =
            <MenuItem
                key={menu.key}
                menuKey={menu.key}
                onClick={this.handleMenuItemClick}
                isActive={isActive}>
              {child}
            </MenuItem>
      } else if (depth === 2) {
        returnContent =
            <ChildItem
                key={menu.key}
                menuKey={menu.key}
                url={menu.url}
                isActive={Object.is(dynamicUrlToConstantUrlMapper(this.props.location.pathname), menu.url)}
                text={this.props.t(menu.key)}>
              {child}
            </ChildItem>
      } else {
        returnContent =
            <SubItem
                key={menu.key}
                menuKey={menu.key}
                url={menu.url}
                text={this.props.t(menu.key)}/>
      }
      return returnContent;
    });
  };

  // filter only the permitted menus from the allMenu List.
  createRenderMenu = (allMenus, permittedMenus, depth) => {
    return allMenus.map(menu => {
      // ignore the admin menu
      if (menu.key === menuKeys.ADMIN) {
        return undefined;
      }
      let child;
      if (menu.subMenus && depth < 3) {
        child = this.createRenderMenu(menu.subMenus, permittedMenus, depth + 1);
      }
      // if every item of child array is undefined, filter it
      if (child && child.every(item => item === undefined)) {
        child = undefined;
      }
      let returnContent;
      if (depth === 1) {
        menu.subMenus = child && child.filter(item => item !== undefined);
        returnContent = menu.subMenus ? menu : undefined;
      } else if (depth === 2) {
        // Only Add Permitted Menus to the render menu. Support Menu is always permitted, so add it.
        if (alwaysShowMenuKeys.find(item => item === menu.key) || permittedMenus.find(item => item === menu.key)) {
          menu.subMenus = child && child.filter(item => item !== undefined);
          returnContent = menu;
        }
      } else {
        if (permittedMenus.find(item => item === menu.key)) {
          returnContent = menu;
        }
      }
      return returnContent;
    });
  };

  // Render menu recursively
  render() {
    const {selectedAppId} = this.props.carousel;

    // When cpUser is switched (CP Management)
    // during loadSessionData call in App.js, selectedAppId in carousel is from different cp (as updateSelectedAppIdInCarousel is not called yet),
    // apps.find will return undefined here, hence updated logic to select first app (this.props.sessionData.apps[0])
    const selectedApp = this.props.sessionData.apps.find(app => Object.is(app.appId, selectedAppId)) || this.props.sessionData.apps[0];

    let toRenderMenus;

    if (selectedApp.settlementApp) {
      toRenderMenus = dpiPortalSettlementAppMenus;
    } else {
      toRenderMenus = this.props.sessionData.isManager ? dpiPortalMenus.filter(menuItem => menuItem.key !== menuKeys.ADMIN) :
          this.createRenderMenu(structuredClone(dpiPortalMenus), selectedApp.permittedMenus, 1);
    }

    toRenderMenus = toRenderMenus.filter(item => item !== undefined);

    // Add the Admin Menu if it is superUser
    if (this.props.sessionData.isSuperUser) {
      toRenderMenus.push(dpiPortalAdminMenu);
    }

    return (
        <nav className="header_menu">
          <ul>
            {this.renderMenu(selectedApp, toRenderMenus, 1)}
          </ul>
        </nav>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    sessionData: state.sessionData,
    carousel: state.carousel,
  };
};

export default connect(mapStateToProps)(withTranslation()(withRouter(LeftNaviBarList)));
