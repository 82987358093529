// All Page urls should be in PageController in server

export const URL_SELLER_OFFICE = "https://seller.samsungapps.com/tv";
export const URL_SA_CREATE_ACCOUNT = "https://account.samsung.com/membership/auth/sign-up";

export const URL_INDEX_PAGE = "/";
export const URL_ERROR_PAGE = "/error-page";

export const URL_SIGNIN_PAGE = "/auth/signin";
export const URL_SIGNOUT_PAGE = "/auth/signout";
export const URL_DSA = "/auth/contract";

export const URL_SETTINGS_APPDETAILS = "/settings/appdetails";
export const URL_SETTINGS_CHANGEINFORMATION = "/settings/changeinformation";
export const URL_SETTINGS_SELLER_INFORMATION = "/settings/sellerinformation";
export const URL_DSA_SELLER_INFORMATION = "/dsa/sellerinformation";
export const URL_SETTINGS_TESTBUYER = "/settings/testbuyer";
export const URL_SETTINGS_TESTBUYER_REGISTRATION = "/settings/testbuyerregistration";
export const URL_SETTINGS_AGREEMENT = "/settings/agreement";

export const URL_PRODUCT_LIST = "/product/productlist";
export const URL_PRODUCT_ADDNEW = "/product/addnew";
export const URL_PRODUCT_DETAIL = "/product/details";
export const URL_PRODUCT_ADDMULTIPLE = "/product/addmultiple";
export const URL_PRODUCT_MODIFYMULTIPLE = "/product/modifymultiple";
export const URL_PRODUCT_SUBSCRIPTIONGROUP_LIST = "/product/subscriptionlist";
export const URL_PRODUCT_SUBSCRIPTIONGROUP_ADDNEW = "/product/addnewsubscriptiongroup";
export const URL_PRODUCT_SUBSCRIPTIONGROUP_DETAIL = "/product/subscription/group";
export const URL_PRODUCT_FLEXIBLEOFFER = "/product/flexibleoffer"

export const URL_SALES_SALESPRODUCT = "/sales/salesproduct";
export const URL_SALES_SALESPRODUCT_DETAIL = "sales/transactiondetail";
export const URL_SALES_SUBSCRIPTION = "/sales/subscription";

export const URL_CAMPAIGN_COUPON = "/campaign/coupon";
export const URL_CAMPAIGN_COUPONDETAIL = "/campaign/coupondetail";
export const URL_CAMPAIGN_ISSUECOUPON = "/campaign/issuecoupon";
export const URL_CAMPAIGN_ALLOCATECOUPON = "/campaign/allocatecoupon";
export const URL_CAMPAIGN_COUPON_ALLOCATIONLIST = "/campaign/allocationlist";
export const URL_CAMPAIGN_BUYER_REDEMPTION_STATUS = "/campaign/buyerredemptionstatus";

export const URL_STATISTICS_APPSALESANALYSIS = "/statistics/appsalesanalysis";
export const URL_STATISTICS_ANALYSISBYCOUNTRY = "/statistics/analysisbycountry";
export const URL_STATISTICS_ANALYSISBYPRODUCT = "/statistics/analysisbyproduct";
export const URL_STATISTICS_SUBSCRIBERANALYSIS = "/statistics/subscriberanalysis";
export const URL_STATISTICS_CAMPAIGNANALYSIS = "/statistics/campaignanalysis";

export const URL_MONTHLY_SETTLEMENT = "/settlement/monthly";

export const URL_MEMBER_MEMBERSHIPMANAGEMENT = "/member/membershipmanagement";
export const URL_MEMBER_EDITPERMISSIONS = "/member/editpermissions";
export const URL_MEMBER_GROUPMANAGEMENT = "/member/groupmanagement";
export const URL_MEMBER_CREATEGROUP = "/member/creategroup";

export const URL_SUPPORT_FAQ = "/support/faq";
export const URL_SUPPORT_ERRORCODE = "/support/errorcode";
export const URL_SUPPORT_NOTICE = "/support/notice";

export const URL_ADMIN_NOTIFICATIONMANAGEMENT = "/admin/notificationmanagement";
export const URL_ADMIN_NOTIFICATIONREGISTRATION = "/admin/notificationregistration";
export const URL_ADMIN_POPUPMANAGEMENT = "/admin/popupmanagement";
export const URL_ADMIN_POPUPREGISTRATION = "/admin/popupregistration";
export const URL_ADMIN_FAQMANAGEMENT = "/admin/faqmanagement";
export const URL_ADMIN_FAQREGISTRATION = "/admin/faqregistration";
export const URL_ADMIN_CPMANAGEMENT = "/admin/cpmanagement";
export const URL_REQUEST_LIST = "/request/request-list";

// API
export const URL_GET_SESSION_DATA_API = "/auth/session.api";
export const URL_AFTER_SA_ACCOUNT_LOGIN = "/auth/signin/after.api";
export const URL_AFTER_SA_ACCOUNT_LOGOUT = "/auth/signout/after.api";

export const API_APP_SELECTION_UPDATE = "/auth/app/select.api";
export const URL_GET_COUNTRY_DATA_API = "/settings/supported/country/list.api";
export const URL_SET_DEFAULT_COUNTRY_API = "/settings/default/country/update.api";
export const URL_GET_APP_DETAIL_API = "/settings/app/detail.api";
export const URL_UPDATE_CP_APP = "/settings/app/update.api";


export const URL_SET_CONTRACT_API = "/auth/contract/update.api";

export const URL_GET_COUNTRY_INFORMATION_API = "/common/service/countries.api";
export const URL_GET_COUNTRY_INFORMATION_BY_CODE_API = "/common/service/countries-by-code.api";

export const URL_GET_DASHBOARD_DATA_API = "/dashboard/data.api";
export const URL_GET_POPUP_DATA_API = "/dashboard/popup.api";

export const URL_GET_COMPANY_DETAIL_API = "/settings/seller/company/detail.api";
export const URL_GET_COMPANY_SMART_BILL_CHECK_API = "/settings/seller/company/smartbill/check.api";
export const URL_UPDATE_SETTINGS_SELLER_COMPANY_API = "/settings/seller/company/update.api";
export const URL_GET_TESTBUYER_LIST_DATA_API = "/settings/test/buyer/list.api";
export const URL_DELETE_TESTBUYER_API = "/settings/test/buyer/delete.api";
export const URL_GET_TESTBUYER_DETAIL_API = "/settings/test/buyer/detail.api";
export const URL_GET_TESTBUYER_CHECK_ID_API = "/settings/test/buyer/id/check.api";
export const URL_ADD_TESTBUYER_API = "/settings/test/buyer/add.api";
export const URL_MODIFY_TESTBUYER_API = "/settings/test/buyer/update.api";
export const URL_GET_AGREEMENT_LIST_DATA_API = "/settings/contract/list.api";
export const URL_GET_AGREEMENT_LIST_DOWNLOAD_API = "/settings/contract/download.api";

export const API_PRODUCT_LIST = "/product/list.api";
export const URL_CHANGE_VISIBILITY_API = "/product/visibility/update.api";
export const URL_CHANGE_STATUS_API = "/product/status/update.api";
export const URL_GET_CHECK_ID_API = "/product/id/check.api";
export const URL_GET_PRODUCT_DETAIL_API = "/product/detail.api";
export const URL_ADD_PRODUCT_API = "/product/add.api";
export const URL_PRODUCT_LIST_DOWNLOAD_API = "/product/list/download.api";
export const API_PRODUCT_UPDATE = "/product/update.api";
export const URL_MULTIPLE_ADD_PRODUCT_API = "/product/multiple/add.api";
export const URL_MULTIPLE_MODIFY_PRODUCT_API = "/product/multiple/update.api";
export const URL_GET_MULTIPLE_PRODUCTS_BY_ID_API = "/product/multiple/detail.api";
export const API_PRODUCT_MULTIPLE_LIST_DOWNLOAD = "/product/multiple/list/download.api";
export const URL_MULTIPLE_DOWNLOAD_TEMPLATE_API = "/product/template/download.api";
export const URL_ADD_FLEXIBLE_OFFER_API = "/product/flexibleoffer/add.api";
export const URL_DETAIL_FLEXIBLE_OFFER_API = "/product/flexibleoffer/detail.api";

export const API_SALES_TRANSACTION_LIST = "/sales/invoice/list.api";
export const API_SALES_INVOICE_DETAIL = "/sales/invoice/detail.api";
export const API_SALES_INVOICE_PRODUCT_STATUS_HISTORY = "/sales/status/history/list.api";
export const API_SALES_SINGLE_INVOICE_REFUND = "/sales/refund.api"; // [POST]
export const API_SALES_MULTIPLE_INVOICE_REFUND = "/sales/multiple/refund.api";
export const API_SALES_INVOICE_LIST_DOWNLOAD = "/sales/invoice/list/download.api";
export const API_SALES_SUBSCRIPTION_LIST = "/sales/subs/list.api";
export const API_SALES_SUBSCRIPTION_CANCEL = "/sales/subs/cancel.api"; // [POST]
export const API_SALES_MULTI_SUBSCRIPTION_CANCEL = "/sales/subs/multiple/cancel.api";
export const API_SUBSCRIPTION_LIST_DOWNLOAD = "/sales/subs/list/download.api";
export const API_SUBSCRIPTION_DETAIL = "/sales/subs/detail.api";
export const API_SUBSCRIPTION_TRANSACTION_LIST = "/sales/subs/transaction/list.api";
export const API_SUBSCRIPTION_EXPIRATION_DATE_MODIFICATION_HISTORY = "/sales/subs/expiry/history/list.api";
export const API_SUBSCRIPTION_EXPIRATION_DATE_CHANGE = "/sales/subs/expiry/update.api";
export const API_SALES_SUBSCRIPTION_PAYMENT_PERIOD = "/sales/subs/payment/period.api";
export const API_SALES_INVOICE_COUNT = "/sales/invoice/count.api";
export const API_SALES_SUBSCRIPTION_COUNT = "/sales/subs/count.api";
export const API_SALES_SUBSCRIPTION_LIST_INHOUSE_DOWNLOAD = "/sales/subs/list/dpi/download.api";
export const API_SALES_INVOICE_LIST_INHOUSE_DOWNLOAD ="/sales/invoice/list/dpi/download.api";

export const URL_GET_COUPON_LIST_DATA_API = "/coupon/list.api";
export const URL_MODIFY_COUPON_VALIDPERIOD_API = "/coupon/validity/update.api";
export const URL_GET_COUPON_DETAIL_API = "/coupon/detail.api";
export const URL_GET_COUPON_ISSUE_API = "/coupon/issue/list.api";
export const URL_GET_COUPON_DOWNLOAD_API = "/coupon/list/download.api";
export const URL_GET_COUPON_ISSUE_DOWNLOAD_API = "/coupon/issue/list/download.api";
export const URL_GET_COUPON_PRODUCTLIST_DATA_API = "/coupon/product/list.api";
export const URL_GET_COUPON_PRODUCT_COUNTRYLIST_DATA_API = "/coupon/product/country/list.api";
export const URL_GET_COUPON_CREATE_API = "/coupon/add.api";
export const URL_GET_COUPONALLOCATIONLIST_DATA_API = "/coupon/allocation/list.api";
export const URL_GET_COUPONALLOCATIONLIST_DOWNLOAD_API = "/coupon/allocation/list/download.api";
export const URL_GET_COUPON_ISSUE_DETAIL_API = "/coupon/issue/detail.api";
export const URL_GET_COUPON_MODIFY_API = "/coupon/update.api";
export const URL_CAMPAIGN_ALLOCATE_DOWNLOAD_TEMPLATE_API = "/coupon/allocate/template/download.api";
export const URL_CAMPAIGN_ALLOCATE_API = "/coupon/allocate.api";
export const URL_CAMPAIGN_ALLOCATE_UPLOAD_API = "/coupon/allocate-upload.api";
export const API_COUPON_REDEEM_LIST = "/coupon/redeem/list.api";
export const API_COUPON_REDEEM_LIST_DOWNLOAD = "/coupon/redeem/list/download.api";

export const URL_GET_STATISTICS_APPSALES_API = "/stats/app/sales.api";
export const URL_GET_STATISTICS_APPSALES_COUNTRY_API = "/stats/app/country/sales.api";
export const URL_GET_STATISTICS_APPSALES_DOWNLOAD_API = "/stats/app/sales/download.api";
export const URL_GET_STATISTICS_APPSALES_COUNTRY_DOWNLOAD_API = "/stats/app/country/sales/download.api";
export const URL_GET_STATISTICS_COUNTRY_SALES_API = "/stats/country/sales.api";
export const URL_GET_STATISTICS_COUNTRY_SALES_DOWNLOAD_API = "/stats/country/sales/download.api";
export const URL_GET_STATISTICS_PRODUCT_SALES_API = "/stats/product/sales.api";
export const URL_GET_STATISTICS_PRODUCTLIST_API = "/stats/product/list.api";
export const URL_GET_STATISTICS_PRODUCT_SALES_DOWNLOAD_API = "/stats/product/sales/download.api";
export const URL_GET_STATISTICS_SUBSCRIBER_SALES_API = "/stats/subscriber/sales.api";
export const URL_GET_STATISTICS_SUBSCRIBER_SALES_DOWNLOAD_API = "/stats/subscriber/sales/download.api";
export const URL_GET_STATISTICS_COUPON_NAMELIST_API = "/stats/coupon/name/list.api";
export const URL_GET_STATISTICS_CAMPAIGN_SALES_API = "/stats/campaign/sales.api";
export const URL_GET_STATISTICS_CAMPAIGN_SALES_DOWNLOAD_API = "/stats/campaign/sales/download.api";

export const API_MONTHLY_SETTLEMENT = "/settlement/list.api";
export const API_MONTHLY_SETTLEMENT_REPORT = "/settlement/detail.api";
export const API_MONTHLY_SETTLEMENT_REPORT_DOWNLOAD = "/settlement/list/download.api";
export const API_SETTLEMENT_PAYOUT_REPORT = "/settlement/payout/list.api";
export const API_SETTLEMENT_PAYOUT_REPORT_DOWNLOAD = "/settlement/payout/list/download.api";
export const API_SETTLEMENT_SALES_REPORT_DOWNLOAD = "/settlement/detail/download.api";

export const URL_GET_GROUP_LIST_DATA_API = "/member/group/list.api";
export const URL_DELETE_GROUP_API = "/member/group/delete.api";
export const URL_GET_GROUP_DETAIL_API = "/member/group/detail.api";
export const URL_CREATE_GROUP_API = "/member/group/add.api";
export const URL_MODIFY_GROUP_API = "/member/group/update.api";
export const URL_GET_MEMBER_LIST_DATA_API = "/member/list.api";
export const URL_GET_MEMBER_DETAIL_API = "/member/detail.api";
export const URL_GET_INACTIVE_MEMBER_LIST_API = "/member/inactive/list.api";
export const URL_CREATE_MEMBER_API = "/member/add.api";
export const URL_MODIFY_MEMBER_API = "/member/update.api";

export const API_SUPPORT_FAQ_LIST = "/support/faq/list.api";
export const API_SUPPORT_NOTICE_LIST = "/support/notice/list.api";
export const API_SUPPORT_ERROR_LIST = "/support/guide/list.api";

export const URL_ADD_FAQ_API = "/admin/faq/add.api";
export const URL_MODIFY_FAQ_API = "/admin/faq/update.api";
export const API_ADMIN_DELETE_FAQ = "/admin/faq/delete.api";
export const API_ADMIN_FAQ_LIST = "/admin/faq/list.api";
export const URL_ADD_POPUP_API = "/admin/popup/add.api";
export const URL_MODIFY_POPUP_API = "/admin/popup/update.api";
export const API_ADMIN_DELETE_POPUP = "/admin/popup/delete.api";
export const API_ADMIN_POPUP_LIST = "/admin/popup/list.api";
export const API_ADMIN_POPUP_DETAIL = "/admin/popup/detail.api";
export const API_ADMIN_NOTIFICATION_LIST = "/admin/notice/list.api";
export const API_ADMIN_NOTIFICATION_DETAIL = "/admin/notice/detail.api";
export const API_ADMIN_NOTIFICATION_UPDATE = "/admin/notice/update.api";
export const API_ADMIN_FAQ_DETAIL = "/admin/faq/detail.api";
export const API_ADMIN_DELETE_NOTICE = "/admin/notice/delete.api";
export const API_ADMIN_NOTIFICATION_REGISTER = "/admin/notice/add.api";
export const API_ADMIN_CPMANAGEMENT_LIST = "/admin/cp/list.api";
export const API_ADMIN_CPMANAGEMENT_DETAIL = "/admin/cp/change.api";
export const API_ADMIN_CHECK_PERMISSION = "/admin/check/permission.api";

export const API_PRODUCT_SUBSCRIPTION_GROUP_NAME_LIST = "/product/subscription/group/name/list.api";
export const API_PRODUCT_SUBSCRIPTION_GROUP_LIST = "/product/subscription/group/list.api";
export const API_PRODUCT_SUBSCRIPTION_PRODUCT_LIST = "/product/subscription/product/list.api";
export const API_PRODUCT_SUBSCRIPTION_GROUP_DETAIL = "/product/subscription/group/detail.api";
export const API_PRODUCT_SUBSCRIPTION_GROUP_ADD = "/product/subscription/group/add.api";
export const API_PRODUCT_SUBSCRIPTION_GROUP_DELETE = "/product/subscription/group/delete.api";
export const API_PRODUCT_SUBSCRIPTION_GROUP_UPDATE = "/product/subscription/group/update.api";

export const API_PRODUCT_TAX_CATEGORY_LIST = "/product/tax/category/list.api";
export const API_CSV_REQUEST_LIST = "/csv/download/list.api";
export const S3_PRE_SIGNED_URL ="/csv/s3/preSignUrl.api";
